import AddCircleIcon from "@mui/icons-material/AddCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Paper, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Popper from "@mui/material/Popper";
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridColumnVisibilityModel,
  GridFilterPanel,
  GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import routes from "common/routes";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchRoute } from "utils/routes";
import { usePrismWebContext } from "../context";
import { AlertBox, ConfirmationDialog } from "../Dialog";
import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PrismGridColDef } from "models/grid";
import Refresh from "../Refresh/refresh";

interface Props {
  clearSearch: () => void;
  onChange: (event) => void;
  gridColumns?: GridColDef[];
  handleColumnChange?: (event, columnList) => void;
  children?: ReactElement;
  pageTitle?: string;
  checkboxLabel?: string;
  tooltipTitle?: string;
  disableAddButton?: boolean;
  activeStateFilter?: (x: boolean) => void;
  showQuickFilter?: boolean;
  showAdd?: boolean;
  handleExport?: (x: any) => void;
  enableCustomExport?: boolean;
  disableExport?: boolean;
  showEdit?: boolean;
  handleAddRow?: (x: boolean) => void;
  onDelete?: (x: boolean) => void;
  setShowActionButtons?: (x: boolean) => void;
  disableDeleteButton?: boolean;
  deleteTooltipTitle?: string;
  disableEditButton?: boolean;
  disablePrintOption?: boolean;
  customPopperWidth?: number;
  boolean;
  isAuthorizationRuleExist?: boolean;
  setColumnVisibilityModel?: (x) => void;
  refreshButton?: { onRefresh: () => void; isRefreshingData: boolean };
  showMultiFilter?: boolean;
  customTableAction?: ReactElement;
}

export function CustomToolbar({
  gridColumns,
  handleColumnChange,
  onChange,
  clearSearch,
  children,
  pageTitle,
  checkboxLabel,
  tooltipTitle,
  disableAddButton,
  activeStateFilter,
  showQuickFilter = true,
  showAdd = true,
  handleExport,
  enableCustomExport = false,
  disableExport = false,
  showEdit = false,
  onDelete,
  setShowActionButtons,
  disableDeleteButton,
  deleteTooltipTitle,
  disableEditButton = false,
  disablePrintOption = true,
  customPopperWidth,
  isAuthorizationRuleExist,
  setColumnVisibilityModel,
  refreshButton,
  showMultiFilter = false,
  customTableAction
}: Props) {
  const exportOptions = {
    exportButton: {
      csv: true
    }
  };
  const csvExportOptions = { fileName: pageTitle || "export" };

  const getStyle = disableButton => {
    return {
      color: disableButton ? "rgb(210, 210, 210) !important" : "rgb(0,0,0)"
    };
  };

  const { t } = useTranslation();
  const AddPopperWidth = 413;

  const [columnAnchorEl, setColumnAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openColumnSettings = Boolean(columnAnchorEl);

  const [addNewAnchorEl, setAddNewAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openAddNew = Boolean(addNewAnchorEl);
  const id = openColumnSettings
    ? "column-settings"
    : openAddNew
    ? "add-new"
    : undefined;

  const [toolBarCols, setToolBarCols] = useState(gridColumns);
  const [checked, setChecked] = React.useState(false);
  const { gridDisplayInactiveState, currentPlatform, gridActiveColumns } =
    usePrismWebContext();
  const [displayInactiveState, setDisplayInactiveState] =
    gridDisplayInactiveState;
  const [gridActiveColumnValue, setGridActiveColumnValue] = gridActiveColumns;
  const [platformName, setPlatformName] = currentPlatform;

  const columnVisibilityModel = React.useCallback(
    (columns: PrismGridColDef[]): GridColumnVisibilityModel => {
      return columns.reduce((acc, column) => {
        // Set the property to `false` if hide is true, otherwise `true`
        acc[column.field] = !column.hide;
        return acc;
      }, {} as Record<GridColDef["field"], boolean>);
    },
    []
  );

  useEffect(() => {
    const key = `${platformName}.${pageTitle}`;
    if (
      gridActiveColumnValue[key]?.length === gridColumns.length &&
      handleColumnChange
    ) {
      setToolBarCols(gridActiveColumnValue[key]);
      handleColumnChange(null, gridActiveColumnValue[key]);
      setColumnVisibilityModel(
        columnVisibilityModel(gridActiveColumnValue[key])
      );
    } else {
      const activeColumnData = {
        ...gridActiveColumnValue,
        [key]: [...gridColumns]
      };
      setGridActiveColumnValue(activeColumnData);
      setToolBarCols(gridColumns);
      setColumnVisibilityModel(columnVisibilityModel(gridColumns));
    }
  }, []);

  const handleAddNew = (event: React.MouseEvent<HTMLElement>) => {
    setAddNewAnchorEl(addNewAnchorEl ? null : event.currentTarget);
    setConfirmation(false);
  };

  const handleColumnSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setColumnAnchorEl(columnAnchorEl ? null : event.currentTarget);
  };

  const handleColumnSelectionOk = (
    event: React.MouseEvent<HTMLButtonElement>,
    columnList
  ) => {
    if (handleColumnChange) {
      handleColumnChange(event, columnList);
      setColumnVisibilityModel(columnVisibilityModel(columnList));
    }
    setColumnAnchorEl(null);
  };

  const toggleColumnVisibility = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnObj
  ) => {
    const column = toolBarCols.find(x => x.field === columnObj.field);
    if (column) {
      column.hide = !column.hide;
      const key = `${platformName}.${pageTitle}`;
      const activeColumnData = {
        ...gridActiveColumnValue,
        [key]: [...toolBarCols]
      };
      setGridActiveColumnValue(activeColumnData);
    }
    setToolBarCols([...toolBarCols]);
  };

  const [confirmation, setConfirmation] = React.useState(false);

  const handleBackdropClick = () => {
    if (openAddNew) {
      setConfirmation(true);
    }
  };

  const handlePopperClick = event => {
    event.stopPropagation();
  };

  const handleClose = flag => {
    setConfirmation(false);
    if (flag) {
      setAddNewAnchorEl(null);
    }
  };

  const [alert, setAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSuccess = message => {
    setAddNewAnchorEl(null);
    setErrorMessage(null);
    setSuccessMessage(message);
    setAlert(true);
  };

  const handleError = error => {
    setSuccessMessage(null);
    setErrorMessage(error || t("unknown_error"));
    setAlert(true);
  };

  const getTitle = (): string => {
    let title = "";

    for (const route in routes) {
      if (matchRoute(location.pathname, [routes[route]])) {
        title = routes[route].moduleName;
      }
    }
    return title;
  };

  useEffect(() => {
    if (displayInactiveState && checkboxLabel) {
      const activeCheckBoxKey = `${platformName}.${pageTitle}`;
      activeStateFilter(displayInactiveState[activeCheckBoxKey]);
      setChecked(displayInactiveState[activeCheckBoxKey]);
    }
  }, []);

  const handleCheckBoxClick = (
    event: React.SyntheticEvent<Element, any>,
    checked: boolean
  ) => {
    const activeCheckBoxKey = `${platformName}.${pageTitle}`;
    setChecked(checked);
    const activeCheckBoxData = {
      ...displayInactiveState,
      [activeCheckBoxKey]: checked
    };
    setDisplayInactiveState(activeCheckBoxData);
    activeStateFilter(checked);
  };

  const handleEdit = () => {
    setShowActionButtons(true);
  };

  const handleDeleteClick = () => {
    onDelete(true);
  };
  const handleRefresh = () => {
    if (refreshButton) {
      refreshButton.onRefresh();
    }
  };
  return (
    <>
      <GridToolbarContainer
        sx={{
          padding: "10px",
          justifyContent: {
            lg: "space-between",
            ".MuiBox-root": {
              ".MuiButton-root": {
                padding: 0,
                minWidth: "inherit",
                width: "20px",
                height: "20px",
                marginLeft: "20px",
                textAlign: "center",
                color: "rgb(0,0,0)",
                "&:hover": {
                  color: "rgba(0,0,0,.08)"
                },
                "&:focus": {
                  color: "rgba(0,0,0,.12)"
                },
                "&:active": {
                  backgroundColor: "rgba(0,0,0,.12)"
                },
                ".MuiButton-startIcon": {
                  marginRight: 0,
                  marginLeft: 0,
                  ".MuiSvgIcon-root": {
                    fontSize: "20px"
                  }
                }
              },
              ".MuiButton-text": {
                fontSize: 0
              }
            }
          }
        }}
      >
        <Typography variant="h6" sx={{ marginRight: "auto" }}>
          {" "}
          {pageTitle || getTitle()}{" "}
        </Typography>
        {showQuickFilter && (
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput: string) =>
              searchInput
                .split(",")
                .map(value => value.trim())
                .filter(value => value !== "")
            }
            sx={{
              width: {
                md: "50%",
                lg: "auto",
                marginLeft: "10px",
                marginRight: "15px"
              }
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "20px"
          }}
        >
          {checkboxLabel && (
            <FormControlLabel
              control={<Checkbox name="gilad" />}
              label={checkboxLabel}
              onChange={handleCheckBoxClick}
              checked={checked}
            />
          )}
          {showMultiFilter && <GridToolbarFilterButton />}
          {refreshButton && (
            <Refresh
              onClick={handleRefresh}
              isLoading={refreshButton.isRefreshingData}
            />
          )}
          <Button
            onClick={handleColumnSettingsClick}
            title={t("column_settings")}
            data-testid={"testid-table-settings-button"}
          >
            <SettingsIcon />
          </Button>

          {showAdd && (
            <Button
              onClick={handleAddNew}
              title={tooltipTitle}
              disabled={Boolean(disableAddButton)}
              sx={getStyle(disableAddButton)}
              data-testid={"testid-table-add-button"}
            >
              {isAuthorizationRuleExist ? <EditIcon /> : <AddCircleIcon />}
            </Button>
          )}

          {showEdit && (
            <>
              <Button
                onClick={handleEdit}
                title={tooltipTitle}
                sx={getStyle(disableEditButton)}
                disabled={Boolean(disableEditButton)}
                data-testid={"testid-table-edit-button"}
              >
                <EditIcon />
              </Button>
              <Button
                onClick={handleDeleteClick}
                title={deleteTooltipTitle}
                sx={getStyle(disableDeleteButton)}
                disabled={Boolean(disableDeleteButton)}
                data-testid={"testid-table-delete-button"}
              >
                <DeleteIcon />
              </Button>
            </>
          )}

          {openAddNew && (
            <Backdrop
              sx={{ color: "#fff", zIndex: "1298" }}
              style={{ opacity: 0.2 }}
              open={openAddNew}
              onMouseDown={handleBackdropClick}
            >
              <Popper
                id={id}
                open={openAddNew}
                anchorEl={addNewAnchorEl}
                sx={{
                  width: customPopperWidth || AddPopperWidth,
                  zIndex: "1299"
                }}
                placement="right-start"
                onMouseDown={event => handlePopperClick(event)}
              >
                <Paper>
                  {React.cloneElement(children, {
                    handleSuccess,
                    handleError
                  })}
                </Paper>
              </Popper>
            </Backdrop>
          )}

          {
            <ConfirmationDialog
              onOpen={confirmation}
              handleClose={handleClose}
              text={"exit_confirmation"}
            />
          }

          <Popper
            id={id}
            open={openColumnSettings}
            anchorEl={columnAnchorEl}
            sx={{ width: AddPopperWidth, zIndex: "1301" }}
            placement="right-start"
          >
            <Paper>
              <FormGroup sx={{ padding: "16px 25px" }}>
                <Typography variant="h6">{t("column_settings")}</Typography>
                {toolBarCols
                  ?.filter(item => item.headerName)
                  .map((x, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={!x.hide}
                          name={x.field}
                          onChange={event => toggleColumnVisibility(event, x)}
                        />
                      }
                      label={x?.headerName || x?.field}
                    />
                  ))}
                <Button
                  onClick={event => handleColumnSelectionOk(event, toolBarCols)}
                >
                  {t("ok")}
                </Button>
              </FormGroup>
            </Paper>
          </Popper>

          {alert && (
            <AlertBox
              successMessage={successMessage}
              errorMessage={errorMessage}
              open={alert}
              handleClose={() => setAlert(false)}
            />
          )}

          {enableCustomExport ? (
            <Button
              onClick={handleExport}
              title={t("export")}
              disabled={disableExport}
              sx={getStyle(disableExport)}
              data-testid={"testid-table-export-button"}
            >
              <SaveAltSharpIcon fontSize="small" />
            </Button>
          ) : (
            <GridToolbarExport
              title={t("export")}
              disabled={disableExport}
              printOptions={{ disableToolbarButton: disablePrintOption }}
              options={exportOptions}
              csvOptions={csvExportOptions}
              sx={getStyle(disableExport)}
              data-testid={"testid-table-export-button"}
            />
          )}
          {customTableAction && customTableAction}
        </Box>
      </GridToolbarContainer>
    </>
  );
}
