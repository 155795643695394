import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import uniqueId from "lodash/uniqueId";
import { GridComponent } from "components/common/Grid";
import {
  APP_MARGIN_TOP,
  APP_PADDING_VERTICAL
} from "components/style/app-style";
import MarketWillPaysFilter from "components/transaction/MarketWillPays/MarketWillPaysFilter";
import { FilterValues } from "components/transaction/MarketPrice/MarketPriceFilter/market-price-filter";
import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import { useDataApi } from "hooks/api/data/DataAPI";
import { MARKET_WILL_PAYS_KEY, MARKETS_KEY } from "common/QueryKeys";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import { convertMoneyToDouble } from "common/helper";
import Columns from "./column";

export default function MarketWillPaysPage() {
  const { t } = useTranslation();
  const { useListMarkets } = useDataApi();
  const { useListMarketWillPays } = useTransactionApi();
  const [filterValues, setFilterValues] = useState<FilterValues>({});

  const {
    data: marketWillPaysDataResponse,
    isLoading: isLoadingMarketWillPays,
    refetch: refetchMarketWillPays,
    isRefetching: isMarketWillPaysRefetching
  } = useListMarketWillPays(MARKET_WILL_PAYS_KEY, {
    filter: [
      {
        market: filterValues?.markets
      }
    ],
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });
  const {
    data: marketsData,
    isLoading: isMarketsLoading,
    refetch: refetchMarkets,
    isRefetching: isMarketsRefetching
  } = useListMarkets(MARKETS_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });
  const marketWillPaysData = useMemo(() => {
    return marketWillPaysDataResponse?.marketWillPays.reduce((acc, item) => {
      const rows = [...acc];
      const row = {
        rowId: uniqueId("rowId-"),
        name: "",
        marketSourceDisplayName: "",
        marketDisplayName: "",
        marketPriceConfigurationName: "",
        results: [],
        unitPrice: [],
        unbrokenPrice: []
      };
      row.name = item.name;
      row.marketSourceDisplayName =
        marketsData?.markets.find(market => market.name === item.market)
          ?.sourceName || item.market;
      row.marketDisplayName =
        marketsData?.markets.find(market => market.name === item.market)
          ?.displayName.value || item.market;
      row.marketPriceConfigurationName = item.marketPriceConfigurationName;

      item.willPays.forEach(willPay => {
        row.results.push(
          willPay.marketResult.segmentResults
            .map(segmentResult => segmentResult.selections)
            .join(" / ")
        );
        row.unitPrice.push(convertMoneyToDouble(willPay.unitPrice));
        row.unbrokenPrice.push(convertMoneyToDouble(willPay.unbrokenPrice));
      });
      rows.push(row);
      return rows;
    }, []);
  }, [marketWillPaysDataResponse, marketsData]);

  const refetch = () => {
    refetchMarkets();
    refetchMarketWillPays();
  };

  const isRefetching = [isMarketsRefetching, isMarketWillPaysRefetching].some(
    Boolean
  );

  const isLoading = [
    isMarketsLoading,
    isLoadingMarketWillPays,
    isRefetching
  ].some(Boolean);

  const handleFilterChange = values => setFilterValues(values);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: {
          xs: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.xs * 2
          }px)`,
          md: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.md * 2
          }px)`,
          lg: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.lg * 2
          }px)`
        }
      }}
    >
      <MarketWillPaysFilter onFilterChange={handleFilterChange} />
      <GridComponent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column"
        }}
        idProp={"rowId"}
        rowData={marketWillPaysData}
        columnDefs={Columns(t)}
        pageTitle={t("market_will_pays")}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayName", sort: "asc" }]
          }
        }}
        loading={isLoading}
        editType="none"
        disableAddButton={true}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
      >
        <></>
      </GridComponent>
    </Box>
  );
}
