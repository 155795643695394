import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridComponent } from "components/common/Grid";
import competitorColumns from "../Competitor/competitorColumn";
import CompetitionCompetitorUpsert from "components/data/Competitor/upsert/upsert";
import { ICompetition } from "@tsg/1st-grpc-web/src/generated/stronachgroup/first/prism/v1/data";

export default function Competitors(competitorData: ICompetition) {
  const { t } = useTranslation();
  const actionMethod = useRef(null);
  const handleActionClick = (actionName, params) => {
    actionMethod.current(actionName, params.id);
  };
  let competitors = [];
  let competitionName = "";
  console.log(competitorData);
  competitors = competitorData?.selectedData?.competitors;
  competitors = competitors.map((item, index) => ({ ...item, id: index + 1 }));
  competitionName = competitorData?.selectedData?.name;
  // console.log(competitonName);

  return (
    <>
      <GridComponent
        idProp={"id"}
        rowData={competitors}
        columnDefs={competitorColumns(handleActionClick, t)}
        actionMethod={actionMethod}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "asc" }]
          }
        }}
        pageTitle="Competitors"
        rowHeight={"auto"}
        showAdd={false}
      >
        <CompetitionCompetitorUpsert originalData={competitorData} />
      </GridComponent>
    </>
  );
}
