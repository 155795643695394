import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { prism } from "@tsg/1st-grpc-web";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import {
  MARKET_PRICES_KEY,
  MARKET_SOURCES_KEY,
  MARKETS_KEY
} from "common/QueryKeys";
import { GridComponent } from "components/common/Grid";
import MarketPriceFilter from "components/transaction/MarketPrice/MarketPriceFilter";
import { FilterValues } from "components/transaction/MarketPrice/MarketPriceFilter/market-price-filter";
import GridContainer from "components/common/Grid/grid-container";
import { useDataApi } from "hooks/api/data/DataAPI";
import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import { convertToKeyValue } from "utils/data";
import Columns from "./column";
import MarketPricesDataPanelTable from "./data-panel-table";

interface IEnchantedMarketPrice
  extends prism.v1.transaction.IMarketPriceConfiguration {
  marketNested?: prism.v1.data.IMarket;
  marketPriceConfiguration?: prism.v1.transaction.MarketPriceConfiguration;
}

export default function MarketPricesPage() {
  const { t } = useTranslation();
  const { useListMarketPrices } = useTransactionApi();
  const { useListMarkets, useListMarketSources } = useDataApi();
  const [filterValues, setFilterValues] = useState<FilterValues>({});
  const {
    data: marketPricesData,
    isLoading: isMarketPricesLoading,
    refetch: refetchMarketPrices,
    isRefetching: isMarketPricesRefetching
  } = useListMarketPrices(MARKET_PRICES_KEY, {
    filter: [
      {
        market: filterValues?.markets
      }
    ],
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const {
    data: marketSourcesData,
    isLoading: isMarketSourcesLoading,
    refetch: refetchMarketSources,
    isRefetching: isMarketSourcesRefetching
  } = useListMarketSources(MARKET_SOURCES_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const {
    data: marketsData,
    isLoading: isMarketsLoading,
    refetch: refetchMarkets,
    isRefetching: isMarketsRefetching
  } = useListMarkets(MARKETS_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const marketSourcesByName = convertToKeyValue(
    marketSourcesData?.marketSources
  );
  const marketsByName = convertToKeyValue(marketsData?.markets);

  let marketPrices: IEnchantedMarketPrice[] =
    marketPricesData?.marketPrices || [];

  const isRefetching = [
    isMarketPricesRefetching,
    isMarketSourcesRefetching,
    isMarketsRefetching
  ].some(Boolean);

  const isLoading = [
    isMarketPricesLoading,
    isMarketSourcesLoading,
    isMarketsLoading,
    isRefetching
  ].some(Boolean);

  if (
    marketsData?.markets?.length &&
    marketSourcesData?.marketSources?.length &&
    marketPrices.length
  ) {
    marketPrices = marketPrices.reduce((acc, cur) => {
      const marketNested = marketsByName[cur.market];
      if (
        !filterValues.marketSources?.length ||
        (filterValues.marketSources?.length &&
          filterValues.marketSources?.includes(marketNested?.sourceName))
      ) {
        return [
          ...acc,
          {
            ...cur,
            marketNested: {
              ...marketNested,
              source: marketSourcesByName[marketsByName[cur.market]?.sourceName]
            }
          }
        ];
      }
      return acc;
    }, []);
  }

  const handleFilterChange = values => setFilterValues(values);

  const refetch = () => {
    refetchMarketPrices();
    refetchMarketSources();
    refetchMarkets();
  };
  const getDetailPanelHeight = () => "auto";
  return (
    <GridContainer>
      <MarketPriceFilter onFilterChange={handleFilterChange} />
      <GridComponent
        idProp={"name"}
        rowData={marketPrices}
        columnDefs={Columns(t)}
        getDetailPanelContent={({ row }) => (
          <MarketPricesDataPanelTable rows={row} />
        )}
        getDetailPanelHeight={getDetailPanelHeight}
        pageTitle={t("market_prices")}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayName", sort: "asc" }]
          }
        }}
        loading={isLoading}
        editType="none"
        rowHeight={"auto"}
        disableAddButton={true}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
      >
        <></>
      </GridComponent>
    </GridContainer>
  );
}
