const mapEnumToOptions = <T>(i18EnumKey: string, enumeration: T, t) => {
  return Object.values(enumeration)
    ?.filter(method => method !== 0)
    ?.map(value => ({
      name: value,
      displayName: {
        value: t(`${i18EnumKey}.${enumeration[value]}`)
      }
    }));
};

export { mapEnumToOptions };

export enum DateFilterOptions {
  TODAY = "Today",
  LAST_7_DAYS = "Last 7 Days",
  LAST_30_DAYS = "Last 30 Days",
  TOMORROW = "Tomorrow",
  LAST_1_YEAR = "Last 1 Year",
  THIS_MONTH = "This Month",
  LAST_MONTH = "Last Month"
}

export enum StandardDateFilterOptions {
  TODAY = DateFilterOptions.TODAY,
  TOMORROW = DateFilterOptions.TOMORROW,
  LAST_7_DAYS = DateFilterOptions.LAST_7_DAYS,
  LAST_30_DAYS = DateFilterOptions.LAST_30_DAYS,
  LAST_1_YEAR = DateFilterOptions.LAST_1_YEAR
}

export enum HighUsageDateFilterOptions {
  TODAY = DateFilterOptions.TODAY,
  LAST_7_DAYS = DateFilterOptions.LAST_7_DAYS,
  LAST_30_DAYS = DateFilterOptions.LAST_30_DAYS,
  THIS_MONTH = DateFilterOptions.THIS_MONTH
}

export enum UsageDateFilterOptions {
  TODAY = DateFilterOptions.TODAY,
  LAST_7_DAYS = DateFilterOptions.LAST_7_DAYS,
  THIS_MONTH = DateFilterOptions.THIS_MONTH,
  LAST_MONTH = DateFilterOptions.LAST_MONTH
}

export enum ConnectionCommands {
  ADD_GUEST_CONNECTION_WAGERING_SOURCES = "AddGuestConnectionWageringSources",
  REMOVE_GUEST_CONNECTION_WAGERING_SOURCES = "RemoveGuestConnectionWageringSources",
  DISABLE_ITSP_CONNECTION = "DisableItspConnection",
  ENABLE_ITSP_CONNECTION = "EnableItspConnection",
  ARCHIVE_ITSP_CONNECTION = "ArchiveItspConnection",
  ADD_PARIMUTUEL_PROGRAMS = "AddParimutuelPrograms",
  REMOVE_PARIMUTUEL_PROGRAMS = "RemoveParimutuelPrograms",
  UPDATE_WAGERING_SOURCES = "UpdateWageringSources",
  SEND_SETTLEMENT_REPORT = "SendSettlementReport",
  START_ITSP_CONNECTION = "StartItspConnection",
  STOP_ITSP_CONNECTION = "StopItspConnection",
  UPDATE_ITSP_CONNECTION = "UpdateItspConnection",
  DELETE_ITSP_CONNECTION = "DeleteItspConnection"
}

export enum MarketCommands {
  APPROVE_MARKET = "ApproveMarket",
  CANCEL_MARKET = "CancelMarket",
  CLOSE_MARKET = "CloseMarket",
  INCOMPLETE_MARKET = "IncompleteMarket",
  MANUALLY_SUSPEND_MARKET = "ManuallySuspendMarket",
  OFFICIAL_MARKET = "OfficialMarket",
  REFUND_MARKET = "RefundMarket",
  REFUND_REQUESTED_MARKET = "RefundRequestedMarket",
  RESULT_MARKET = "ResultMarket",
  UPDATE_MARKET = "UpdateMarket",
  UNOFFICIAL_MARKET = "UnofficialMarket",
  UNAUTO_SUSPEND_MARKET = "UnautoSuspendMarket",
  OPEN_MARKET = "OpenMarket"
}
export enum GridActions {
  EDIT = "edit",
  REMOVE = "remove",
  ADD_ROW = "add row",
  CSV = "csv"
}
