import React from "react";
import ListItem from "@mui/material/ListItem";
import MuiListItemText from "@mui/material/ListItemText";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";

interface ICellListItemProps {
  primary: string;
  selectedWageringSource: Record<string, string>;
  rowId: string;
  rowName: number | string;
  onClick?: (callback: (wageringSourceName: string) => void) => void;
  onChange: (
    wageringSourceName: string,
    name: number | string,
    state?: Record<string, string>
  ) => void;
  field: string;
  selected?: string;
}

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  color: theme.palette.custom.contrastTableText
}));

const WageringSourceListItem = ({
  primary,
  onClick,
  selectedWageringSource,
  onChange,
  rowId,
  rowName,
  field,
  selected
}: ICellListItemProps) => {
  const gridApi = useGridApiContext().current;

  const handleClick = e => {
    onClick((wageringSourceName: string) => {
      const expanded = gridApi.getExpandedDetailPanels();

      if (!expanded.includes(rowId)) {
        onChange(wageringSourceName, rowName, selectedWageringSource);
        gridApi.toggleDetailPanel(rowId);
        gridApi.setCellFocus(rowId, field);
      }

      if (expanded.includes(rowId)) {
        if (wageringSourceName === selected) {
          gridApi.toggleDetailPanel(rowId);
          onChange("", rowName, selectedWageringSource);
        } else {
          onChange(wageringSourceName, rowName, selectedWageringSource);
          gridApi.setCellFocus(rowId, field);
        }
      }
    });

    e.stopPropagation();
  };

  return (
    <ListItem
      onClick={onClick ? handleClick : null}
      disableGutters
      disablePadding
    >
      <ListItemText
        primaryTypographyProps={{
          fontWeight: selected?.split("/")?.pop() === primary ? "600" : "400"
        }}
        sx={{
          cursor: onClick ? "pointer" : "",
          textDecoration: onClick ? "underline" : ""
        }}
        primary={primary}
      />
    </ListItem>
  );
};

export default WageringSourceListItem;
