import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { prism } from "@tsg/1st-grpc-web";
import { useDataApi } from "hooks/api/data/DataAPI";
import { DATA_MARKET_KEY } from "common/QueryKeys";
import { buttonStyle } from "components/style/app-style";
import { MarketCommands } from "common/utils/enum-utils";

interface Props {
  command: MarketCommands;
  row?: prism.v1.data.IMarket;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
}
const MarketActionViewWrapper = styled(Box)({
  padding: "16px 25px"
});
const MarketActionViewBody = styled(Box)({
  padding: "16px 0px"
});
const MarketActionViewFooter = styled(Box)({
  display: "flex",
  justifyContent: "center"
});

function MarketActionView({ row, handleSuccess, handleError, command }: Props) {
  const { t } = useTranslation();
  const {
    useApproveMarket,
    useCancelMarket,
    useCloseMarket,
    useIncompleteMarket,
    useManuallySuspendMarket,
    useOfficialMarket,
    useRefundMarket,
    useRefundRequestMarket,
    useResultMarket,
    useUnofficialMarket,
    useUnAutoSuspendMarket,
    useOpenMarket
  } = useDataApi();
  const queryClient = useQueryClient();

  const requestCallback = () => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [DATA_MARKET_KEY] });
        handleSuccess(
          t(`market_command_success.${command}`, {
            name: row?.displayName.value
          })
        );
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };
  const { mutate: mutateApproveMarket, isPending: isLoadingApproveMarket } =
    useApproveMarket(requestCallback());
  const { mutate: mutateCancelMarket, isPending: isLoadingCancelMarket } =
    useCancelMarket(requestCallback());
  const { mutate: mutateCloseMarket, isPending: isLoadingCloseMarket } =
    useCloseMarket(requestCallback());
  const {
    mutate: mutateIncompleteMarket,
    isPending: isLoadingIncompleteMarket
  } = useIncompleteMarket(requestCallback());
  const {
    mutate: mutateManuallySuspendMarket,
    isPending: isLoadingManuallySuspendMarket
  } = useManuallySuspendMarket(requestCallback());
  const { mutate: mutateOfficialMarket, isPending: isLoadingOfficialMarket } =
    useOfficialMarket(requestCallback());
  const { mutate: mutateRefundMarket, isPending: isLoadingRefundMarket } =
    useRefundMarket(requestCallback());
  const {
    mutate: mutateRefundRequestMarket,
    isPending: isLoadingRefundRequestMarket
  } = useRefundRequestMarket(requestCallback());
  const { mutate: mutateResultMarket, isPending: isLoadingResultMarket } =
    useResultMarket(requestCallback());
  const { mutate: mutateOpenMarket, isPending: isLoadingOpenMarket } =
    useOpenMarket(requestCallback());
  const {
    mutate: mutateUnofficialMarket,
    isPending: isLoadingUnofficialMarket
  } = useUnofficialMarket(requestCallback());
  const {
    mutate: mutateUnAutoSuspendMarket,
    isPending: isLoadingUnAutoSuspendMarket
  } = useUnAutoSuspendMarket(requestCallback());

  const isLoading = [
    isLoadingApproveMarket,
    isLoadingCancelMarket,
    isLoadingCloseMarket,
    isLoadingIncompleteMarket,
    isLoadingManuallySuspendMarket,
    isLoadingOfficialMarket,
    isLoadingRefundMarket,
    isLoadingRefundRequestMarket,
    isLoadingResultMarket,
    isLoadingOpenMarket,
    isLoadingUnofficialMarket,
    isLoadingUnAutoSuspendMarket
  ].some(Boolean);

  const handlers = {
    [MarketCommands.APPROVE_MARKET]: mutateApproveMarket,
    [MarketCommands.CANCEL_MARKET]: mutateCancelMarket,
    [MarketCommands.CLOSE_MARKET]: mutateCloseMarket,
    [MarketCommands.INCOMPLETE_MARKET]: mutateIncompleteMarket,
    [MarketCommands.MANUALLY_SUSPEND_MARKET]: mutateManuallySuspendMarket,
    [MarketCommands.OFFICIAL_MARKET]: mutateOfficialMarket,
    [MarketCommands.REFUND_MARKET]: mutateRefundMarket,
    [MarketCommands.REFUND_REQUESTED_MARKET]: mutateRefundRequestMarket,
    [MarketCommands.RESULT_MARKET]: mutateResultMarket,
    [MarketCommands.OPEN_MARKET]: mutateOpenMarket,
    [MarketCommands.UNOFFICIAL_MARKET]: mutateUnofficialMarket,
    [MarketCommands.UNAUTO_SUSPEND_MARKET]: mutateUnAutoSuspendMarket
  };

  const handleSubmit = () => {
    handlers[command] && handlers[command]({ name: row.name });
  };
  return (
    <MarketActionViewWrapper>
      <Box>
        <Typography variant="h6">{t(`market_actions.${command}`)}</Typography>
      </Box>
      <MarketActionViewBody>
        <Typography variant="h6">{row?.displayName.value}</Typography>
      </MarketActionViewBody>
      <MarketActionViewFooter>
        <LoadingButton
          variant="contained"
          fullWidth
          disableElevation
          sx={row ? buttonStyle() : null}
          type="submit"
          size="large"
          loading={isLoading}
          loadingPosition="start"
          onClick={handleSubmit}
        >
          {t("market_actions.confirm_button")}
        </LoadingButton>
      </MarketActionViewFooter>
    </MarketActionViewWrapper>
  );
}

export default MarketActionView;
