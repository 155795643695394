import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { prism } from "@tsg/1st-grpc-web";
import {
  convertMoneyToDouble,
  convertSecondsToDateString
} from "common/helper";
import { IEnchantedBet } from "./use-page-data";
import CustomCell from "components/common/Grid/custom-cell";

export const Columns = (t): GridColumns<IEnchantedBet> => {
  return [
    {
      field: "name",
      headerName: t("bets_bet_id"),
      width: 250,
      hide: true,
      editable: false
    },
    {
      field: "acceptedTime",
      headerName: t("bets_accepted_time"),
      width: 250,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row?.acceptedTime?.seconds * 1000);
      },
      renderCell: (params: GridRenderCellParams) =>
        params.value?.toLocaleString()
    },
    {
      field: "source",
      headerName: t("bets_wagering_source"),
      width: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.sourceName || params.row.source
    },
    {
      field: "marketType",
      headerName: t("bets_market_type"),
      width: 100,
      minWidth: 100,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.marketNested?.marketType?.displayName?.value ||
        params.row.marketNested?.marketTypeName
    },
    {
      field: "baseBetAmount",
      headerName: t("bets_base_bet_amount"),
      minWidth: 100,
      width: 100,
      editable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        convertMoneyToDouble(params.row.baseBetAmount).toFixed(2)
    },
    {
      field: "betSegments",
      headerName: t("bets_bet_segments"),
      minWidth: 100,
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params}>
          <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {params.row.betSegments?.map((bs, i) => {
              const selections = bs.selections?.join(", ");
              return (
                <ListItem key={selections + i} disableGutters disablePadding>
                  <ListItemText primary={selections} sx={{ pl: 1 }} />
                </ListItem>
              );
            })}
          </List>
        </CustomCell>
      ),
      valueGetter: (params: GridValueGetterParams) =>
        params.row.betSegments
          ?.map(bs => {
            if (!bs?.selections?.length) {
              return "";
            }

            return bs.selections.join(", ");
          })
          .join(" | ") || ""
    },
    {
      field: "market",
      headerName: t("bets_market"),
      width: 250,
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.marketNested?.displayName?.value || params.row.market
    },
    {
      field: "competition",
      headerName: t("bets_competition"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.competitionNested?.displayName?.value ||
        params.row.competitionNested?.name
    },
    {
      field: "parimutuelEvent",
      headerName: t("bets_parimutuel_event"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.parimutuelEvent?.displayName?.value
    },
    {
      field: "betCost",
      headerName: t("bets_bet_cost"),
      minWidth: 100,
      width: 100,
      editable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        convertMoneyToDouble(params.row.betCost).toFixed(2)
    },
    {
      field: "state",
      headerName: t("bets_state_label"),
      minWidth: 100,
      width: 100,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        t(`bets_state.${prism.v1.transaction.BetEnums.State[params.row.state]}`)
    },
    {
      field: "payoutAmount",
      headerName: t("bets_payout_amount"),
      minWidth: 100,
      width: 100,
      editable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        convertMoneyToDouble(params.row.payoutAmount).toFixed(2)
    },
    {
      field: "refundAmount",
      headerName: t("bets_refund_amount"),
      minWidth: 100,
      width: 100,
      editable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        convertMoneyToDouble(params.row.refundAmount).toFixed(2)
    },
    {
      field: "winningAmount",
      headerName: t("bets_winning_amount"),
      minWidth: 100,
      width: 100,
      editable: false,
      type: "number",
      headerAlign: "left",
      align: "left",
      valueGetter: (params: GridValueGetterParams) =>
        convertMoneyToDouble(params.row.winningAmount).toFixed(2)
    },
    {
      field: "commissionAmount",
      headerName: t("bets_commission_amount"),
      minWidth: 100,
      width: 100,
      editable: false,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row?.commissionAmount) {
          return convertMoneyToDouble(params.row.commissionAmount).toFixed(2);
        }
        return "";
      }
    },
    {
      field: "breakage",
      headerName: t("bets_breakage"),
      minWidth: 100,
      width: 100,
      editable: false,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row?.breakage) {
          const { normal } = params.row.breakage;
          if (Object.keys(normal).length) {
            return convertMoneyToDouble(normal).toFixed(2);
          }
          return "0.00";
        }
        return "";
      }
    },
    {
      field: "negativeBreakage",
      headerName: t("bets_negative_breakage"),
      minWidth: 100,
      width: 100,
      editable: false,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row?.breakage) {
          const { minus } = params.row.breakage;
          if (Object.keys(minus).length) {
            return `-${convertMoneyToDouble(minus).toFixed(2)}`;
          }
          return "0.00";
        }
        return "";
      }
    },
    {
      field: "cancelledTime",
      headerName: t("bets_cancelled_time"),
      minWidth: 160,
      width: 160,
      hide: true,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.cancelledTime &&
        new Date(params.row?.cancelledTime?.seconds * 1000),
      renderCell: (params: GridRenderCellParams) =>
        params.value?.toLocaleString()
    },
    {
      field: "resolvedTime",
      headerName: t("bets_resolved_time"),
      minWidth: 160,
      width: 160,
      hide: true,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.resolvedTime &&
        new Date(params.row?.resolvedTime?.seconds * 1000),
      renderCell: (params: GridRenderCellParams) =>
        params.value?.toLocaleString()
    },
    {
      field: "unresolvedTime",
      headerName: t("bets_unresolved_time"),
      minWidth: 160,
      width: 160,
      hide: true,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.unresolvedTime &&
        new Date(params.row?.unresolvedTime?.seconds * 1000),
      renderCell: (params: GridRenderCellParams) =>
        params.value?.toLocaleString()
    },
    {
      field: "createTime",
      headerName: t("created_time"),
      minWidth: 160,
      width: 160,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row?.auditInfo?.createTime?.seconds * 1000);
      },
      renderCell: (params: GridRenderCellParams) =>
        params.value?.toLocaleString()
    },
    {
      field: "updateTime",
      headerName: t("updated_time"),
      minWidth: 160,
      width: 160,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams) => {
        return new Date(params.row?.auditInfo?.updateTime?.seconds * 1000);
      },
      renderCell: (params: GridRenderCellParams) =>
        params.value?.toLocaleString()
    },
    {
      field: "updatedBy",
      headerName: t("updated_by"),
      width: 250,
      minWidth: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.auditInfo?.updatedBy;
      }
    },
    {
      field: "createdBy",
      headerName: t("created_by"),
      width: 250,
      minWidth: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.auditInfo?.createdBy;
      }
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 0,
      width: 0,
      editable: false,
      hide: true,
      getActions: () => []
    }
  ];
};
export default Columns;
