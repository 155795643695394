import React from "react";
import { Box, Typography, styled } from "@mui/material";
import MoneyMatrix from "./money-matrix";
import { prism } from "@tsg/1st-grpc-web";

interface OwnProps<T = Record<string, any>> {
  title: string;
  matrixData: T;
  selectedWageringSourceName?: string;
}

const Panel = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "10px"
});

const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "10px",
  maxWidth: "70%",
  marginRight: "10px"
});

const Title = styled(Typography)({
  marginBottom: "5px"
});

const DetailPanelContent = <
  T extends Record<string, prism.v1.transaction.MarketPool.IPoolCombo[]>
>({
  title,
  matrixData,
  selectedWageringSourceName
}: OwnProps<T>) => {
  return (
    <Panel>
      <Section>
        <Title variant="h6">{title}</Title>
        <MoneyMatrix
          matrixData={matrixData}
          selectedWageringSourceName={selectedWageringSourceName}
        />
      </Section>
    </Panel>
  );
};

export default DetailPanelContent;
