import React, { ReactNode } from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF
} from "@mui/x-data-grid-pro";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { prism } from "@tsg/1st-grpc-web";
import {
  convertMoneyToDouble,
  convertSecondsToDateString
} from "common/helper";
import WageringSourceListItem from "components/transaction/MarketPool/wagering-source-list-item";
import CustomDetailPanelToggle from "components/transaction/MarketPool/detail-panel/custom-detail-panel-toggle";
import { TFunction } from "react-i18next";
import CustomCell from "components/common/Grid/custom-cell";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

enum MoneyField {
  Gross = "gross",
  Refunds = "refunds",
  NetSales = "netSales"
}

const collectMoneyData = (
  array: (
    | prism.v1.transaction.MarketPool.IWageringSourcePool
    | prism.v1.transaction.MarketTotal.IWageringSourceTotal
  )[],
  poolComboProp: string,
  state: any,
  keepAsCombinationPoolTotalsOnly?: boolean
) =>
  (array || [])?.reduce(
    (acc, wsp) => {
      const currentValue = convertMoneyToDouble(wsp[poolComboProp]);
      const sum = (
        wsp as prism.v1.transaction.MarketPool.IWageringSourcePool
      )?.poolCombos?.reduce((pcSum, cur) => {
        if (cur.state === state) {
          return pcSum + convertMoneyToDouble(cur[poolComboProp]);
        }
        return pcSum;
      }, 0);
      if (
        keepAsCombinationPoolTotalsOnly &&
        state ===
          prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
            .MARKET_POOL_COMBO_STATE_REFUND
      ) {
        return { sum: 0, array: [...acc.array, 0] };
      }
      if (keepAsCombinationPoolTotalsOnly) {
        return {
          sum: acc.sum + currentValue,
          array: [...acc.array, currentValue]
        };
      }
      return { sum: acc.sum + sum, array: [...acc.array, sum] };
    },
    { sum: 0, array: [] }
  );

const splitPathValue = (path: string[]) =>
  path[path.length - 1].split("-").slice(-1);

const renderListItem = (value: ReactNode, fontWeight?: string) => (
  <ListItem disableGutters disablePadding>
    <ListItemText primaryTypographyProps={{ fontWeight }} primary={value} />
  </ListItem>
);

const renderCellList = (node: ReactNode | ReactNode[]) => (
  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
    <List dense={true} sx={{ pt: 0, pb: 0 }}>
      {node}
    </List>
  </Box>
);

const getValueGetter = (
  row: any,
  field: MoneyField,
  keepAsCombinationPoolTotalsOnly: boolean
) => {
  if (keepAsCombinationPoolTotalsOnly) {
    switch (field) {
      case MoneyField.Gross:
        return convertMoneyToDouble(
          row?.marketPoolsGrossTotals?.grossTotal || 0
        ).toFixed(2);
      case MoneyField.NetSales:
        return collectMoneyData(
          row.wageringSourceTotals,
          "netAmount",
          prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
            .MARKET_POOL_COMBO_STATE_LIVE,
          keepAsCombinationPoolTotalsOnly
        ).sum.toFixed(2);
      default:
        return "0.00";
    }
  }

  if (field === MoneyField.NetSales) {
    return collectMoneyData(
      row.wageringSourcePools,
      "netAmount",
      prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
        .MARKET_POOL_COMBO_STATE_LIVE
    ).sum.toFixed(2);
  }

  if (field === MoneyField.Gross || field === MoneyField.Refunds) {
    return collectMoneyData(
      row.wageringSourcePools,
      "amount",
      field === MoneyField.Gross
        ? prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
            .MARKET_POOL_COMBO_STATE_LIVE
        : prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
            .MARKET_POOL_COMBO_STATE_REFUND
    ).sum.toFixed(2);
  }
};

const Columns = (
  t: TFunction,
  handleChangeSelectedWageringSource: (
    wageringSourceName: string,
    name: number | string,
    state?: Record<string, string>
  ) => void
): ExtendedGridColDef[] => [
  {
    field: "name",
    headerName: t("market_pool_market_name"),
    width: 250,
    hide: true,
    editable: false,
    renderCell: ({ row }: GridValueGetterParams) => (
      <CustomCell
        sx={{ height: "100%" }}
        customValue={
          row.keepAsCombinationPool ===
            prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
              .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY && row.path.length > 1
            ? ""
            : row.name
        }
      />
    )
  },
  {
    field: "marketDisplayName",
    headerName: t("market_pool_market_display_name"),
    minWidth: 250,
    width: 250,
    renderCell: ({ row }: GridValueGetterParams) => (
      <CustomCell
        sx={{ height: "100%" }}
        customValue={
          row.keepAsCombinationPool ===
            prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
              .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY && row.path.length > 1
            ? ""
            : row.marketNested?.displayName?.value || row.market
        }
      />
    ),
    valueGetter: ({ row }: GridValueGetterParams) =>
      row.path.length > 1
        ? ""
        : row.marketNested?.displayName?.value || row.market,
    editable: false
  },
  {
    field: "wageringSources",
    headerName: t("market_pool_wagering_sources"),
    width: 250,
    minWidth: 250,
    editable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { row, field } = params;

      const selectOnClickWageringSourceName =
        (wageringSourceName: string) =>
        (callback: (wageringSourceName: string) => void) => {
          callback(wageringSourceName);
        };

      let elements = [];

      if (
        row.keepAsCombinationPool ===
        prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
          .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
      ) {
        elements =
          row?.wageringSourceTotals?.map(wsp =>
            renderListItem(
              wsp.wageringSourceNested?.displayName?.value ||
                wsp.wageringSourceName,
              "400"
            )
          ) || [];
      } else {
        const isSupportMatrixView =
          row?.sortedPoolCombos?.length &&
          row?.sortedPoolCombos?.[0]?.combination?.length < 3;

        elements = Object.keys(row.combosWagerSource).map(name => (
          <WageringSourceListItem
            key={name}
            primary={name.split("/").pop()}
            selectedWageringSource={row.selectedWageringSource}
            rowId={row.id}
            rowName={row.name}
            field={field}
            onClick={
              isSupportMatrixView
                ? selectOnClickWageringSourceName(name)
                : undefined
            }
            onChange={handleChangeSelectedWageringSource}
            selected={row?.selectedWageringSource?.[row.name]}
          />
        ));
      }

      elements.push(renderListItem("Local Total", "600"));

      return (
        <CustomCell customValue={params.row?.wageringSourceNames || ""}>
          {renderCellList(elements)}
        </CustomCell>
      );
    },
    valueGetter: ({ row }: GridValueGetterParams) =>
      row.path?.length > 1 &&
      row.keepAsCombinationPool !==
        prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
          .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
        ? row.sortedPoolCombos[splitPathValue(row.path)[0]].combination.join(
            " "
          )
        : "Local Total"
  },
  {
    field: MoneyField.Gross,
    headerName: t("market_pool_gross_sales"),
    minWidth: 100,
    width: 100,
    editable: false,
    sortable: false,
    renderCell: ({ row, value }: GridRenderCellParams) => {
      const data = collectMoneyData(
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
          ? row.wageringSourceTotals
          : row.wageringSourcePools,
        "amount",
        prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
          .MARKET_POOL_COMBO_STATE_LIVE,
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
      );
      const elements = data.array.map(amount =>
        renderListItem(amount.toFixed(2))
      );
      elements.push(renderListItem(value));

      return (
        <CustomCell
          customValue={[...data.array.map(amount => amount.toFixed(2)), value]}
        >
          {renderCellList(elements)}
        </CustomCell>
      );
    },
    valueGetter: ({ row }: GridValueGetterParams) =>
      getValueGetter(
        row,
        MoneyField.Gross,
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
      )
  },
  {
    field: MoneyField.Refunds,
    headerName: t("market_pool_refunds"),
    minWidth: 100,
    width: 100,
    editable: false,
    sortable: false,
    renderCell: ({ row, value }: GridRenderCellParams) => {
      const data = collectMoneyData(
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
          ? row.wageringSourceTotals
          : row.wageringSourcePools,
        "amount",
        prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
          .MARKET_POOL_COMBO_STATE_REFUND,
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
      );
      const elements = data.array.map(amount =>
        renderListItem(amount.toFixed(2))
      );
      elements.push(renderListItem(value));

      return (
        <CustomCell
          customValue={[...data.array.map(amount => amount.toFixed(2)), value]}
        >
          {renderCellList(elements)}
        </CustomCell>
      );
    },
    valueGetter: ({ row }: GridValueGetterParams) =>
      getValueGetter(
        row,
        MoneyField.Refunds,
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
      )
  },
  {
    field: MoneyField.NetSales,
    headerName: t("market_pool_net_sales"),
    minWidth: 100,
    width: 100,
    editable: false,
    sortable: false,
    renderCell: ({ row, value }: GridRenderCellParams) => {
      const data = collectMoneyData(
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
          ? row.wageringSourceTotals
          : row.wageringSourcePools,
        "netAmount",
        prism.v1.transaction.MarketPoolCommonEnums.MarketPoolComboState
          .MARKET_POOL_COMBO_STATE_LIVE,
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
      );
      const elements = data.array.map(amount =>
        renderListItem(amount.toFixed(2))
      );
      elements.push(renderListItem(value));

      return (
        <CustomCell
          customValue={[...data.array.map(amount => amount.toFixed(2)), value]}
        >
          {renderCellList(elements)}
        </CustomCell>
      );
    },
    valueGetter: ({ row }: GridValueGetterParams) =>
      getValueGetter(
        row,
        MoneyField.NetSales,
        row.keepAsCombinationPool ===
          prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
            .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY
      )
  },
  {
    field: "state",
    headerName: t("market_pool_state_label"),
    minWidth: 100,
    width: 100,
    editable: false,
    renderCell: (params: GridValueGetterParams) => (
      <CustomCell sx={{ height: "100%" }} params={params} />
    ),
    valueGetter: ({ row }: GridValueGetterParams) =>
      row.state
        ? t(
            `market_pool_state.${
              prism.v1.transaction.MarketPoolCommonEnums.MarketPoolState[
                row.state
              ]
            }`
          )
        : ""
  },
  {
    field: "updateTime",
    headerName: t("updated_time"),
    width: 250,
    hide: true,
    renderCell: (params: GridValueGetterParams) => (
      <CustomCell sx={{ height: "100%" }} params={params} />
    ),
    valueGetter: ({ row }: GridValueGetterParams) => {
      return convertSecondsToDateString(row?.auditInfo?.updateTime);
    }
  },
  {
    field: "createTime",
    headerName: t("created_time"),
    width: 250,
    hide: true,
    renderCell: (params: GridValueGetterParams) => (
      <CustomCell sx={{ height: "100%" }} params={params} />
    ),
    valueGetter: ({ row }: GridValueGetterParams) => {
      return convertSecondsToDateString(row?.auditInfo?.createTime);
    }
  },
  {
    field: "updatedBy",
    headerName: t("updated_by"),
    width: 250,
    hide: true,
    renderCell: (params: GridValueGetterParams) => (
      <CustomCell sx={{ height: "100%" }} params={params} />
    ),
    valueGetter: ({ row }: GridValueGetterParams) => {
      return row?.auditInfo?.updatedBy;
    }
  },
  {
    field: "createdBy",
    headerName: t("created_by"),
    width: 250,
    hide: true,
    renderCell: (params: GridValueGetterParams) => (
      <CustomCell sx={{ height: "100%" }} params={params} />
    ),
    valueGetter: ({ row }: GridValueGetterParams) => {
      return row?.auditInfo?.createdBy;
    }
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ({ row, value, id }: GridValueGetterParams) => {
      const keepAsCombinationPoolPoolTotalsOnly =
        row.keepAsCombinationPool ===
        prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
          .KEEP_AS_COMBINATION_POOL_TOTALS_ONLY;

      const isSupportMatrixView =
        row?.sortedPoolCombos?.length &&
        row?.sortedPoolCombos?.[0]?.combination?.length < 3;

      if (keepAsCombinationPoolPoolTotalsOnly) {
        return null;
      }

      return (
        <CustomDetailPanelToggle
          centralize
          id={id}
          name={row.name}
          value={value}
          disabled={!isSupportMatrixView}
          onChange={() =>
            handleChangeSelectedWageringSource(
              "",
              row.name,
              row.selectedWageringSource
            )
          }
        />
      );
    }
  }
];

export default Columns;
