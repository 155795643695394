import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import { prism } from "@tsg/1st-grpc-web";
import AlertSubjectType from "./alert-subject-type";
import AlertActionsButton from "./alert-actions-button";

interface OwnProps {
  activeAlert: prism.v1.core.IAlert;
}

const AlertDetails = ({ activeAlert }: OwnProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<prism.v1.core.AlertEnums.State>(
    activeAlert?.state || 0
  );

  const date = Number(activeAlert?.auditInfo?.createTime?.seconds) * 1000;

  const handleChange = (
    event: SelectChangeEvent<prism.v1.core.AlertEnums.State>
  ) => {
    setStatus(+event.target.value);
    // This should be a call to the backend to update the status of the alert
  };

  useEffect(() => {
    setStatus(activeAlert?.state || 0);
  }, [activeAlert?.state]);

  return (
    <>
      <Container
        sx={{
          minWidth: "420px"
        }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            container
            alignItems="center"
            spacing={0}
            xs={12}
            sx={{
              marginTop: "12px",
              marginBottom: "24px"
            }}
          >
            <AlertSubjectType
              subjectType={activeAlert?.subjectType}
              parent={activeAlert?.parent}
              fontSize="20px"
            />
          </Grid>
          <Grid item container alignItems="center" spacing={0} xs={12}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {t("alert.details.statusLabel")}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <FormControl sx={{ minWidth: 120 }} size="small">
                <Select value={status} onChange={handleChange}>
                  <MenuItem
                    value={prism.v1.core.AlertEnums.State.STATE_ACKNOWLEDGED}
                  >
                    {t("alert.details.STATE_ACKNOWLEDGED")}
                  </MenuItem>
                  <MenuItem
                    value={prism.v1.core.AlertEnums.State.STATE_UNACKNOWLEDGED}
                  >
                    {t("alert.details.STATE_UNACKNOWLEDGED")}
                  </MenuItem>
                  <MenuItem
                    value={prism.v1.core.AlertEnums.State.STATE_RESOLVED}
                  >
                    {t("alert.details.STATE_RESOLVED")}
                  </MenuItem>
                  <MenuItem value={prism.v1.core.AlertEnums.State.STATE_MUTED}>
                    {t("alert.details.STATE_MUTED")}
                  </MenuItem>
                  <MenuItem
                    value={prism.v1.core.AlertEnums.State.STATE_UNSPECIFIED}
                  >
                    {t("alert.details.STATE_UNSPECIFIED")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs display={"flex"} justifyContent={"flex-end"}>
              <Box>
                <AlertActionsButton
                  alertName={activeAlert?.name || ""}
                  alertActions={activeAlert?.actions || []}
                  disabled={!activeAlert}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={0} xs={12}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {t("alert.details.timeLabel")}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography
                sx={{
                  color: "#484686",
                  fontWeight: 500,
                  fontSize: "16px"
                }}
              >
                {date && format(date, "PPPp")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={0} xs={12}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {t("alert.details.subjectLabel")}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography
                sx={{
                  color: "#484686",
                  fontWeight: 500,
                  fontSize: "16px"
                }}
              >
                {activeAlert?.subject}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={0} xs={12}>
            <Grid item xs={2}>
              <Typography
                sx={{
                  fontWeight: 600
                }}
              >
                {t("alert.details.nameLabel")}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <Typography
                sx={{
                  color: "#484686",
                  fontWeight: 500,
                  fontSize: "16px"
                }}
              >
                {activeAlert?.displayName?.value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            marginTop: "35px"
          }}
        >
          {t("alert.details.descriptionLabel")}
          {activeAlert?.description?.value}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            marginTop: "24px"
          }}
        >
          {t("alert.details.parentLabel")}
          {activeAlert?.parent}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            marginTop: "24px"
          }}
        >
          {t("alert.details.shortNameLabel")}
          {activeAlert?.shortName}
        </Typography>
        <Divider
          sx={{
            margin: "24px 0px 40px 0px",
            borderColor: "#6361B880",
            borderWidth: "1px"
          }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            marginBottom: "24px"
          }}
        >
          {t("alert.details.historyLabel")}
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          sx={{ marginBottom: "16px" }}
        >
          <Grid item xs={3}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#484686"
              }}
            >
              {t("alert.details.timeSentLabel")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#484686"
              }}
            >
              {t("alert.details.senderLabel")}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#484686"
              }}
            >
              {t("alert.details.recipientsLabel")}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          sx={{
            margin: "16px 0px",
            borderColor: "#6361B880",
            borderWidth: "1px"
          }}
        />
      </Container>
    </>
  );
};

export default AlertDetails;
