import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountPageSlider from "components/common/AccountPageSlider";
import { usePrismWebContext } from "components/common/context";
import {
  menuWidth,
  subMenuWidth
} from "components/common/Layout/app-sidebar/app-sidebar";
import AlertIconButton from "components/alerts/alert-icon-button";
import useClasses from "./classes";

export interface Props {
  isSidebarOpen: boolean;
  isProfilePage?: boolean;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const HeaderWrapper = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
  width: `calc(100% - ${menuWidth}px)`,
  left: menuWidth,
  right: 0,
  transition: theme.transitions.create(["left", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    left: menuWidth + subMenuWidth,
    width: `calc(100% - ${menuWidth + subMenuWidth}px)`,
    transition: theme.transitions.create(["left", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const AppHeader = (props: Props) => {
  const classes = useClasses();
  const { isSidebarOpen } = props;
  const { currentPlatform, activeMenu } = usePrismWebContext();
  const [platformName, setPlatformName] = currentPlatform;
  const [activeMenuItem] = activeMenu;
  const [openAccountSlider, setOpenAccountSlider] = useState<boolean>(false);
  const handleToggleAccountSlider = state => {
    setOpenAccountSlider(state);
  };

  const headersToHide = ["Usage", "High Usage", "Publisher", "User"];

  return (
    <>
      <DrawerHeader />
      <HeaderWrapper open={isSidebarOpen}>
        <Box className={classes.header}>
          {!headersToHide?.includes(activeMenuItem[platformName]) && (
            <Typography className={classes.headerTitle}>
              {activeMenuItem[platformName]}
            </Typography>
          )}
          <Box className={classes.buttonsWrapper}>
            <IconButton>
              <SettingsIcon className={classes.icon} />
            </IconButton>
            <AlertIconButton />
            <IconButton onClick={handleToggleAccountSlider}>
              <AccountCircleIcon className={classes.icon} />
            </IconButton>
          </Box>
        </Box>
      </HeaderWrapper>
      <AccountPageSlider
        open={openAccountSlider}
        onToggleAccountSlider={handleToggleAccountSlider}
      />
    </>
  );
};

export default AppHeader;
