import React from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ConnectionCommands } from "common/utils/enum-utils";
import EventIcon from "@mui/icons-material/Event";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { prism } from "@tsg/1st-grpc-web";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import styled from "../../../theme";

const IconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[500],
  marginRight: 8
}));

const options = [
  {
    command: ConnectionCommands.ADD_GUEST_CONNECTION_WAGERING_SOURCES,
    icon: <AddCircleOutlineIcon />
  },
  {
    command: ConnectionCommands.REMOVE_GUEST_CONNECTION_WAGERING_SOURCES,
    icon: <DeleteIcon />
  },
  { command: ConnectionCommands.UPDATE_WAGERING_SOURCES, icon: <EditIcon /> },
  { command: ConnectionCommands.DISABLE_ITSP_CONNECTION, icon: <EventIcon /> },
  { command: ConnectionCommands.ENABLE_ITSP_CONNECTION, icon: <EventIcon /> },
  { command: ConnectionCommands.ARCHIVE_ITSP_CONNECTION, icon: <EventIcon /> },
  {
    command: ConnectionCommands.ADD_PARIMUTUEL_PROGRAMS,
    icon: <AddCircleOutlineIcon />
  },
  {
    command: ConnectionCommands.REMOVE_PARIMUTUEL_PROGRAMS,
    icon: <DeleteIcon />
  },
  { command: ConnectionCommands.SEND_SETTLEMENT_REPORT, icon: <EventIcon /> },
  { command: ConnectionCommands.START_ITSP_CONNECTION, icon: <EventIcon /> },
  { command: ConnectionCommands.STOP_ITSP_CONNECTION, icon: <EventIcon /> },
  { command: ConnectionCommands.DELETE_ITSP_CONNECTION, icon: <DeleteIcon /> },
  { command: ConnectionCommands.UPDATE_ITSP_CONNECTION, icon: <EditIcon /> }
];

interface IProps {
  row: prism.v1.itsp.IItspConnection;
  onClickCommand: (data: {
    command: ConnectionCommands;
    row: prism.v1.itsp.IItspConnection;
  }) => void;
}

const ConnectionActions = ({ row, onClickCommand }: IProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleItemClick = (command: ConnectionCommands) => event => {
    event.preventDefault();
    event.stopPropagation();
    onClickCommand && onClickCommand({ command, row });
    handleClose(event);
  };
  return (
    <div>
      <IconButton
        aria-label="Connection Commands"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.command}
            onClick={handleItemClick(option.command)}
          >
            <IconWrapper>{option.icon}</IconWrapper>
            {t(`connection_action.${option.command}`)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ConnectionActions;
