import { prism } from "@tsg/1st-grpc-web";

import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import { useDataApi } from "hooks/api/data/DataAPI";
import { FilterValues } from "components/transaction/Bets/BetsFilter/bets-filter";
import {
  BETS_KEY,
  COMPETITIONS_KEY,
  MARKETS_KEY,
  MARKET_TYPES_KEY,
  WAGERING_SOURCES_KEY,
  DATA_PARIMUTUEL_EVENT_KEY
} from "common/QueryKeys";
import { convertMoneyToDouble } from "common/helper";
import { convertToKeyValue } from "utils/data";
import {
  getEndOfDay,
  getStartOfDay,
  isAfterDate,
  isBeforeDate,
  isBetweenDates,
  toTimerange
} from "utils/date-utils";

export interface IEnchantedBet extends prism.v1.transaction.IBet {
  marketNested?: prism.v1.data.IMarket;
  wageringSourceName?: string;
}

interface IData {
  bets: IEnchantedBet[];
  isLoading: boolean;
  refetch: () => void;
  isRefetching: boolean;
}

const toRangeFilter = (range): prism.v1.common.TimestampMatch[] => {
  const onOrAfter = range?.startTime
    ? {
        timestamp: range?.startTime
      }
    : undefined;
  const before = range?.endTime
    ? {
        timestamp: range?.endTime
      }
    : undefined;

  if (!onOrAfter && !before) {
    return [];
  }
  return [
    {
      range: {
        absolute:
          onOrAfter || before
            ? {
                onOrAfter,
                before
              }
            : undefined
      }
    } as prism.v1.common.TimestampMatch
  ];
};

export const usePageData = (filterValues: FilterValues): IData => {
  const { useListBets, useListWageringSources } = useTransactionApi();
  const {
    useListCompetitions,
    useListMarkets,
    useListParimutuelEvents,
    useListMarketTypes
  } = useDataApi();

  const {
    data: competitionsData,
    isLoading: isCompetitionsLoading,
    refetch: refetchCompetitions,
    isRefetching: isCompetitionsRefetching
  } = useListCompetitions(COMPETITIONS_KEY, {
    pagingOptions: {
      maxResults: 99999
    }
  });
  const {
    data: marketsData,
    isLoading: isMarketsLoading,
    refetch: refetchMarkets,
    isRefetching: isMarketsRefetching
  } = useListMarkets(MARKETS_KEY, {
    pagingOptions: {
      maxResults: 99999
    }
  });
  const {
    data: marketTypesData,
    isLoading: isMarketTypesLoading,
    refetch: refetchMarketTypes,
    isRefetching: isMarketTypesRefetching
  } = useListMarketTypes(MARKET_TYPES_KEY, {
    pagingOptions: {
      maxResults: 99999
    }
  });
  const {
    data: wageringSourcesData,
    isLoading: isWageringSourcesLoading,
    refetch: refetchWageringSources,
    isRefetching: isWageringSourcesRefetching
  } = useListWageringSources(WAGERING_SOURCES_KEY, {
    pagingOptions: {
      maxResults: 99999
    }
  });

  const isFilterAcceptedTime =
    !!filterValues?.acceptedTime?.startTime ||
    !!filterValues?.acceptedTime?.endTime;

  const acceptedTime = isFilterAcceptedTime
    ? toRangeFilter(filterValues.acceptedTime)
    : toRangeFilter(toTimerange(getStartOfDay(), getEndOfDay()));

  const {
    data: betsData,
    isLoading: isBetsLoading,
    refetch: refetchBets,
    isRefetching: isBetsRefetching
  } = useListBets(BETS_KEY, {
    filter: [
      {
        acceptedTime,
        resolvedTime: toRangeFilter(filterValues.resolvedTime),
        unresolvedTime: toRangeFilter(filterValues.unresolvedTime),
        name: filterValues.name ? [filterValues.name] : undefined,
        state: filterValues.state,
        market: filterValues.markets,
        source: filterValues.wageringSources?.length
          ? [
              {
                name: filterValues?.wageringSources
              }
            ]
          : undefined
      }
    ],
    pagingOptions: {
      maxResults: 99999
    }
  });
  const {
    data: parimutuelEventsData,
    isLoading: isParimutuelEventsLoading,
    refetch: refetchParimutuelEvents,
    isRefetching: isParimutuelEventsRefetching
  } = useListParimutuelEvents(DATA_PARIMUTUEL_EVENT_KEY, {
    pagingOptions: {
      maxResults: 99999
    }
  });

  const parimutuelEventsByCompetitionName =
    parimutuelEventsData?.parimutuelEvents.reduce((acc, cur) => {
      cur.competitions.forEach(competition => {
        acc[competition.competitionName] = cur;
      });
      return acc;
    }, {});
  const competitionsByName = convertToKeyValue(competitionsData?.competitions);
  const marketsByName = convertToKeyValue(marketsData?.markets);
  const marketTypesByName = convertToKeyValue(marketTypesData?.marketTypes);
  const wageringSourcesByName = convertToKeyValue(
    wageringSourcesData?.wageringSources
  );

  const isRefetchingBets = [
    isCompetitionsRefetching,
    isMarketsRefetching,
    isMarketTypesRefetching,
    isWageringSourcesRefetching,
    isParimutuelEventsRefetching,
    isBetsRefetching
  ].some(Boolean);

  const isLoading = [
    isBetsLoading,
    isCompetitionsLoading,
    isMarketsLoading,
    isMarketTypesLoading,
    isWageringSourcesLoading,
    isRefetchingBets,
    isParimutuelEventsLoading
  ].some(Boolean);

  const competitionsObj = {};
  let bets = [];
  if (betsData && betsData.bets && !isLoading) {
    bets = betsData.bets.map(bet => {
      const market = marketsByName[bet.market];
      const competition = competitionsByName[bet.competition];
      competitionsObj[competition?.name] = competition;
      const parimutuelEvent =
        parimutuelEventsByCompetitionName[competition?.name];
      return {
        ...bet,
        competitionNested: competition,
        marketNested: {
          ...market,
          marketType: marketTypesByName[market?.marketTypeName]
        },
        parimutuelEvent,
        wageringSourceName:
          wageringSourcesByName[bet.sourceName]?.displayName?.value
      };
    });
    bets = filterValues.marketTypes?.length
      ? bets?.filter(bet =>
          filterValues.marketTypes.includes(bet.marketNested?.marketTypeName)
        )
      : bets;
    bets = filterValues.wageringSources?.length
      ? bets?.filter(bet =>
          filterValues.wageringSources.includes(bet.sourceName)
        )
      : bets;
    if (
      filterValues.cancelledTime?.startTime ||
      filterValues.cancelledTime?.endTime
    ) {
      bets = bets.filter(bet => {
        if (
          filterValues.cancelledTime?.startTime &&
          filterValues.cancelledTime?.endTime
        ) {
          return isBetweenDates(
            bet.cancelledTime,
            filterValues.cancelledTime.startTime,
            filterValues.cancelledTime.endTime
          );
        }
        if (filterValues.cancelledTime?.startTime) {
          return isAfterDate(
            bet.cancelledTime,
            filterValues.cancelledTime.startTime
          );
        }
        if (filterValues.cancelledTime?.endTime) {
          return isBeforeDate(
            bet.cancelledTime,
            filterValues.cancelledTime.endTime
          );
        }
      });
    }
    bets = ["baseBetAmount", "betCost", "payoutAmount"].reduce((acc, key) => {
      if (filterValues[key] !== undefined && filterValues[key] !== "") {
        acc = acc.filter(bet => {
          const checkValue =
            bet[key]?.nanos || bet[key]?.units || bet[key]?.currencyCode
              ? convertMoneyToDouble(bet[key])
              : bet[key];
          return checkValue === filterValues[key];
        });
      }
      return acc;
    }, bets);
  }
  const refetch = () => {
    refetchCompetitions();
    refetchMarkets();
    refetchMarketTypes();
    refetchWageringSources();
    refetchBets();
    refetchParimutuelEvents();
  };
  return {
    bets,
    isLoading,
    refetch,
    isRefetching: isRefetchingBets
  };
};
