import React, { useState, useEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import { Allotment } from "allotment";
import "allotment/dist/style.css";
import AlertList from "./alert-list";
import { useAlertApi } from "hooks/api/alerts/useAlertApi";
import { ALERTS_KEY } from "common/QueryKeys";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import AlertDetails from "./alert-details";
import { prism } from "@tsg/1st-grpc-web";
import { useSelector } from "react-redux";

type SortingDirection = "asc" | "desc";
interface OwnProps {
  handleClose: () => void;
}

const AlertsCenter = ({ handleClose }: OwnProps) => {
  const [activeAlert, setActiveAlert] = useState<prism.v1.core.IAlert>(null);
  const [sortingDirection, setSortingDirection] =
    useState<SortingDirection>("desc");
  const { activeAlertId } = useSelector((state: any) => state.alerts);

  const { useListAlerts } = useAlertApi();
  const { data: alertsData, isLoading: isAlertsLoading } = useListAlerts(
    ALERTS_KEY,
    {
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT
      },
      getOptions: {
        orderBy: [`auditInfo.createTime ${sortingDirection}`]
      }
    }
  );
  console.log(alertsData?.alerts);

  useEffect(() => {
    if (activeAlertId) {
      const alert = alertsData?.alerts?.find(
        alert => alert.name === activeAlertId
      );
      setActiveAlert(alert);
    }
  }, [activeAlertId]);

  const handleChangeSortingDirection = (direction: SortingDirection) => {
    setSortingDirection(direction);
  };

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: "#282535",
          justifyContent: "center"
        }}
      >
        <Toolbar>
          <Button
            onClick={handleClose}
            sx={{
              color: "#7ED8FF",
              fontSize: "20px",
              lineHeight: "22px",
              textTransform: "none",
              fontWeight: 400,
              flex: "1",
              marginRight: "auto",
              justifyContent: "flex-start",
              padding: 0
            }}
          >
            Close
          </Button>
          <Typography
            align="center"
            sx={{
              color: "#FFFFFF",
              fontSize: "20px",
              fontWeight: 500,
              flex: "1"
            }}
          >
            Alerts Center
          </Typography>
          <Box
            sx={{
              flex: "1",
              marginLeft: "auto"
            }}
          />
        </Toolbar>
      </AppBar>
      <Allotment>
        <AlertList
          alerts={alertsData?.alerts}
          isLoading={isAlertsLoading}
          onChangeSortingDirection={handleChangeSortingDirection}
        />
        <AlertDetails activeAlert={activeAlert} />
      </Allotment>
    </>
  );
};

export default AlertsCenter;
