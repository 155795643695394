import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  onOpen?: boolean;
  handleClose?: (text: boolean) => void;
  text?: string;
  targetName?: string;
}

export default function ConfirmationDialog({
  onOpen,
  handleClose,
  text = "exit",
  targetName
}: Props) {
  const [open, setOpen] = React.useState(onOpen);
  const { t } = useTranslation();
  React.useEffect(() => {
    setOpen(onOpen);
  }, [onOpen]);

  const handleYesClose = () => {
    onOpen = false;
    setOpen(false);
    if (handleClose) {
      handleClose(true);
    }
  };

  const handleNoClose = () => {
    onOpen = false;
    setOpen(false);
    if (handleClose) {
      handleClose(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleYesClose();
          }
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{ zIndex: "1302" }}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t(text)}
          </DialogContentText>
          {targetName && (
            <TextField
              id="alert-dialog-slide-read-only-target-name"
              label={t("wagering_source_name_label")}
              value={targetName}
              InputProps={{
                readOnly: true
              }}
              fullWidth
              sx={{ marginTop: "30px" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoClose}>{t("confirmation_no")}</Button>
          <Button onClick={handleYesClose}>{t("confirmation_yes")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
