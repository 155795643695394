import React from "react";
import { useTranslation } from "react-i18next";

import IconButton from "@mui/material/IconButton";
import { Avatar, Box, Button, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import { Bolt, ExpandMore, ExpandLess } from "@mui/icons-material";
import { alpha, Theme } from "@mui/material/styles";
import { prism } from "@tsg/1st-grpc-web";
import { useAlertApi } from "hooks/api/alerts/useAlertApi";
import styled from "../../theme";
import IAlertAction = prism.v1.core.IAlertAction;

const styles = (theme: Theme) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.custom.bluishPurple,

  ":hover": {
    backgroundColor: alpha(theme.palette.custom.bluishPurple, 0.9)
  },
  ":disabled": {
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.custom.bluishPurple, 0.5)
  }
});

const AlertIcon = styled(Avatar)(({ theme }) => {
  return { ...styles(theme), width: 32, height: 32 };
});
const AlertButton = styled(Button)(({ theme }) => {
  return styles(theme);
});

type AlertActions = {
  mark_resolved: () => void;
  mute_alert: () => void;
};

interface IProps {
  alertName: string | null;
  alertActions: IAlertAction[];
  useIconButton?: boolean;
  disabled?: boolean;
}

const AlertActionsButton = ({
  useIconButton = false,
  disabled = false,
  alertName = null,
  alertActions = []
}: IProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { useMuteAlert, useResolveAlert, useExecuteAlertAction } =
    useAlertApi();

  const { mutate: mutateMuteAlert, isPending: isPendingMuteAlert } =
    useMuteAlert();
  const { mutate: mutateResolveAlert, isPending: isPendingResolveAlert } =
    useResolveAlert();
  const {
    mutate: mutateExecuteAlertAction,
    isPending: isPendingExecuteAlertAction
  } = useExecuteAlertAction();
  const actions: AlertActions = {
    mark_resolved: () => mutateResolveAlert({ name: alertName }),
    mute_alert: () => mutateMuteAlert({ name: alertName })
  };

  const isLoading = [
    isPendingMuteAlert,
    isPendingResolveAlert,
    isPendingExecuteAlertAction
  ].some(Boolean);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };
  const handleAction = (type: keyof AlertActions) => () => {
    actions[type]();
    setAnchorEl(null);
  };
  const handleAlertAction = (action: string) => () => {
    mutateExecuteAlertAction({
      name: alertName,
      actionShortName: action
    });
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {useIconButton ? (
          <Tooltip title={t("alert.actions_button")}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "action-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disabled={disabled || isLoading}
              data-testid={"alert-action-button-icon"}
            >
              <AlertIcon>
                <Bolt />
              </AlertIcon>
            </IconButton>
          </Tooltip>
        ) : (
          <AlertButton
            onClick={handleClick}
            aria-controls={open ? "action-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            startIcon={<Bolt />}
            endIcon={open ? <ExpandLess /> : <ExpandMore />}
            disabled={disabled || isLoading}
            data-testid={"alert-action-button"}
          >
            {t("alert.actions_button")}
          </AlertButton>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))"
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {alertActions.map(action => {
          if (!action.shortName) {
            return null;
          }
          return (
            <MenuItem
              key={action.shortName}
              onClick={handleAlertAction(action.shortName)}
            >
              {action?.displayName?.value || action.shortName}
            </MenuItem>
          );
        })}
        {Boolean(alertActions.length) && <Divider />}
        <MenuItem onClick={handleAction("mark_resolved")}>
          {t("alert.mark_resolved")}
        </MenuItem>
        <MenuItem onClick={handleAction("mute_alert")}>
          {t("alert.mute_alert")}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default AlertActionsButton;
