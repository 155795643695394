import React, { useMemo } from "react";
import uniqueId from "lodash/uniqueId";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { convertMoneyToDouble } from "common/helper";
import Box from "@mui/material/Box";
import { Theme, styled, useTheme } from "@mui/material/styles";
import { prism } from "@tsg/1st-grpc-web";
import isEqual from "lodash/isEqual";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

interface OwnProps<T = Record<string, any>> {
  matrixData: T[] | T;
  selectedWageringSourceName?: string;
}

interface CellProps {
  filled: boolean;
}

const Container = styled(Box)({
  width: "100%"
});

const Matrix = styled(Table, {
  name: "MoneyMatrix",
  slot: "Root"
})({
  "& .MuiTableCell-root": {
    borderRight: "1px solid"
  },
  "&:first-child": {
    borderLeft: "1px solid"
  }
});

const MatrixHead = styled(TableHead, {
  name: "MatrixHead",
  slot: "Root"
})({
  "& .MuiTableCell-root": {
    border: "1px solid"
  }
});

const Cell = styled(TableCell)(
  ({ filled, theme }: CellProps & { theme: Theme }) => ({
    backgroundColor: filled ? theme.palette.secondary.grey : ""
  })
);

const ListItemCustom = styled(ListItem, {
  name: "ListItemCustom",
  slot: "Root"
})({
  display: "flex",
  padding: 0,
  height: "53px"
});

const ListItemTextCustom = styled(Box, {
  name: "ListItemTextCustom",
  slot: "Root"
})({
  flex: 1,
  borderTop: "1px solid",
  borderRight: "1px solid",
  height: "100%",
  display: "flex",
  paddingLeft: "16px",
  alignItems: "center"
});

const ComboNumber = styled(Box, {
  name: "ComboNumber",
  slot: "Root"
})({
  borderTop: "1px solid",
  borderLeft: "1px solid",
  borderRight: "1px solid",
  display: "flex",
  height: "100%",
  width: "78px",
  alignItems: "center",
  justifyContent: "center"
});

const findMatches = (
  combos: string[],
  poolCombos: prism.v1.transaction.MarketPool.IPoolCombo[]
) => {
  return poolCombos.filter(combo => isEqual(combos, combo.combination));
};

const MoneyMatrix = <
  T extends Record<string, prism.v1.transaction.MarketPool.IPoolCombo[]>
>({
  matrixData,
  selectedWageringSourceName
}: OwnProps<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const combos: prism.v1.transaction.MarketPool.IPoolCombo[] = useMemo(() => {
    const isPlainObject =
      typeof matrixData === "object" && !Array.isArray(matrixData);

    if (
      selectedWageringSourceName &&
      typeof matrixData?.[selectedWageringSourceName] === "object"
    ) {
      return Object.values(matrixData[selectedWageringSourceName]).flat();
    }

    if (isPlainObject) {
      return Object.values(matrixData).flat().filter(Boolean);
    }

    return matrixData.flat().filter(Boolean);
  }, [selectedWageringSourceName, matrixData]);

  const isMatrix = combos.some(combo => combo?.combination.length > 1);
  const matrixSize = combos?.[0]?.combination.length || 0;

  const maxMatrix = Math.max(
    ...combos.flatMap(combo => combo?.combination.map(c => Number.parseInt(c)))
  );

  const rows = Array.from({ length: maxMatrix });
  const cells = Array.from({ length: maxMatrix + 1 });

  const renderMatrix = () => {
    return rows
      .map((_, cellNumber) => {
        const amounts = cells.map((_, i) => {
          if (i === 0) {
            return cellNumber + 1;
          }

          const currentCombos = findMatches(
            [`${cellNumber + 1}`, `${i}`],
            combos
          );

          if (currentCombos.length) {
            return `${currentCombos
              .reduce(
                (total, currentCombo) =>
                  total + convertMoneyToDouble(currentCombo.amount),
                0
              )
              .toFixed(2)}`;
          }

          return null;
        });

        if (amounts.slice(1).every(cell => cell === null)) {
          return null;
        }

        return (
          <TableRow key={cellNumber}>
            {amounts.map((amount, i) => (
              <Cell filled={i === 0} key={uniqueId("amount-")}>
                {amount || "0.00"}
              </Cell>
            ))}
          </TableRow>
        );
      })
      .filter(Boolean);
  };

  const renderList = () => {
    return combos.map((combo, idx) => {
      const borderBottom =
        idx === combos.length - 1
          ? `1px solid ${theme.palette.primary.grey}`
          : "";

      return (
        <ListItemCustom key={idx}>
          <ComboNumber sx={{ borderBottom }}>
            {combo?.combination?.join("")}
          </ComboNumber>
          <ListItemTextCustom sx={{ borderBottom }}>
            {convertMoneyToDouble(combo.amount).toFixed(2)}
          </ListItemTextCustom>
        </ListItemCustom>
      );
    });
  };

  const tableHeader = useMemo(() => {
    if (isMatrix) {
      return cells.map((_, idx) => (
        <TableCell key={idx}>{idx === 0 ? null : idx}</TableCell>
      ));
    }

    return combos.map((combo, idx) => (
      <TableCell key={idx}>{combo?.combination?.join("")}</TableCell>
    ));
  }, [isMatrix, combos, cells]);

  if (isEmpty(matrixData) || matrixSize >= 3) {
    return <Box>{t("market_pool_matrix_no_data")}</Box>;
  }

  return (
    <Container>
      <TableContainer component={Box}>
        {isMatrix ? (
          <Matrix aria-label="money-matrix">
            <MatrixHead>
              <TableRow>{tableHeader}</TableRow>
            </MatrixHead>
            <TableBody>{renderMatrix()}</TableBody>
          </Matrix>
        ) : (
          <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {renderList()}
          </List>
        )}
      </TableContainer>
    </Container>
  );
};

export default MoneyMatrix;
