import React, { useState } from "react";
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridExpandMoreIcon,
  GridRenderCellParams,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import {
  convertMoneyToDouble,
  convertSecondsToDateString
} from "common/helper";
import { prism } from "@tsg/1st-grpc-web";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import ISegmentResult = prism.v1.transaction.ISegmentResult;
import IWageringSourcePricing = prism.v1.transaction.IWageringSourcePricing;
import styled from "../../../theme";
import CustomCell from "components/common/Grid/custom-cell";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}
const ExpandMoreIcon = styled(GridExpandMoreIcon)(({ isExpanded, theme }) => ({
  transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));
const getMarketResultText = (marketResult: ISegmentResult[]) => {
  return marketResult.map(sr => sr.selections.join(",")).join("/");
};
const getWageringSourcePricesWithoutDuplicates = (
  wageringSourcePrices: IWageringSourcePricing[]
) => {
  return wageringSourcePrices.reduce((acc, item) => {
    const data = [...acc];
    if (
      data.filter(
        el =>
          el.payableCombos.length === item.payableCombos.length &&
          el.payableCombos.every(
            (pc, idx) =>
              pc.unitPrice?.amount.units ===
              item.payableCombos[idx].unitPrice?.amount.units
          ) &&
          el.payableCombos.every(
            (pc, idx) =>
              getMarketResultText(pc.marketResult.segmentResults) ===
              getMarketResultText(
                item.payableCombos[idx].marketResult.segmentResults
              )
          )
      ).length
    ) {
      return data;
    }
    data.push(item);
    return data;
  }, []);
};
const ExpandCell = (params: GridValueGetterParams) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = () => setIsExpanded(!isExpanded);
  return (
    <IconButton size="small" onClick={handleClick}>
      <ExpandMoreIcon isExpanded={isExpanded} fontSize="inherit" />
    </IconButton>
  );
};
export const Columns = (t): ExtendedGridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("market_price_name"),
      width: 250,
      hide: true,
      editable: false
    },
    {
      field: "parent",
      headerName: t("market_price_source"),
      width: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.marketNested?.source?.displayName?.value ||
        params.row.marketNested?.sourceName
    },
    {
      field: "marketDisplayName",
      headerName: t("market_price_market_display_name"),
      width: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.marketNested?.displayName?.value || params.row.market
    },
    {
      field: "winningCombinations",
      headerName: t("market_price_winning_combinations"),
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params}>
          <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {params.row.marketPriceConfigurationPrices
              ?.filter(
                mpcp =>
                  mpcp.mpcPriceType ===
                  prism.v1.transaction.MarketPriceEnums.MpcPriceType
                    .MPC_PRICE_TYPE_PRICE
              )
              .map(mpcp =>
                getWageringSourcePricesWithoutDuplicates(
                  mpcp.wageringSourcePrices
                ).map(wsp => (
                  <List key={wsp.wageringSourceName}>
                    {wsp.payableCombos.map((pc, i) => (
                      <ListItem key={i} disableGutters disablePadding>
                        <ListItemText
                          primary={getMarketResultText(
                            pc.marketResult.segmentResults
                          )}
                          sx={{ pl: 1 }}
                        />
                        {i === wsp.payableCombos.length - 1 && <Divider />}
                      </ListItem>
                    ))}
                  </List>
                ))
              )}
          </List>
        </CustomCell>
      ),
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.marketPriceConfigurationPrices
          ?.filter(
            mpcp =>
              mpcp.mpcPriceType ===
              prism.v1.transaction.MarketPriceEnums.MpcPriceType
                .MPC_PRICE_TYPE_PRICE
          )
          .map(mpcp =>
            getWageringSourcePricesWithoutDuplicates(
              mpcp.wageringSourcePrices
            ).map(wsp =>
              wsp.payableCombos.map(pc =>
                getMarketResultText(pc.marketResult.segmentResults)
              )
            )
          )
          .join(" | ");
      }
    },
    {
      field: "1.00Price",
      headerName: t("market_price_1_00_price"),
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params}>
          <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {params.row.marketPriceConfigurationPrices
              ?.filter(
                mpcp =>
                  mpcp.mpcPriceType ===
                  prism.v1.transaction.MarketPriceEnums.MpcPriceType
                    .MPC_PRICE_TYPE_PRICE
              )
              .map(mpcp =>
                getWageringSourcePricesWithoutDuplicates(
                  mpcp.wageringSourcePrices
                ).map(wsp => (
                  <List key={wsp.wageringSourceName}>
                    {wsp.payableCombos.map((pc, i) => (
                      <ListItem key={i} disableGutters disablePadding>
                        <ListItemText
                          primary={convertMoneyToDouble(
                            pc.unitPrice?.amount
                          ).toFixed(2)}
                          sx={{ pl: 1 }}
                        />
                      </ListItem>
                    ))}
                  </List>
                ))
              )}
          </List>
        </CustomCell>
      ),
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.marketPriceConfigurationPrices
          ?.filter(
            mpcp =>
              mpcp.mpcPriceType ===
              prism.v1.transaction.MarketPriceEnums.MpcPriceType
                .MPC_PRICE_TYPE_PRICE
          )
          .map(mpcp =>
            getWageringSourcePricesWithoutDuplicates(
              mpcp.wageringSourcePrices
            ).map(wsp =>
              wsp.payableCombos.map(pc =>
                convertMoneyToDouble(pc.unitPrice?.amount).toFixed(2)
              )
            )
          )
          .join(" | ");
      }
    },
    {
      field: "marketState",
      headerName: t("market_price_market_state"),
      width: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.marketNested?.state
          ? t(
              `market_price_market_state_enum.${
                prism.v1.data.MarketEnums.State[params.row.marketNested?.state]
              }`
            )
          : "";
      }
    },
    {
      field: "updateTime",
      headerName: t("updated_time"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.auditInfo?.updateTime);
      }
    },
    {
      field: "createTime",
      headerName: t("created_time"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.auditInfo?.createTime);
      }
    },
    {
      field: "updatedBy",
      headerName: t("updated_by"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.auditInfo?.updatedBy;
      }
    },
    {
      field: "createdBy",
      headerName: t("created_by"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.auditInfo?.createdBy;
      }
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: params => <ExpandCell {...params} />
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 0,
      width: 0,
      editable: false,
      hide: true,
      getActions: () => []
    }
  ];
};
export default Columns;
