import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CommandConfirmationDialogTitle = styled(DialogTitle)({
  paddingRight: 64
});
const CommandConfirmationDialogCloseButton = styled(IconButton)(
  ({ theme }) => ({
    position: "absolute",
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  })
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  body?: string | React.ReactElement;
  title?: string;
  buttonText?: string;
}

export default function CommandConfirmationDialog({
  isOpen,
  onClose,
  onConfirm,
  title,
  body,
  buttonText
}: Props) {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose && onClose();
  };
  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
      >
        <CommandConfirmationDialogTitle>
          {t(title)}
          <CommandConfirmationDialogCloseButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </CommandConfirmationDialogCloseButton>
        </CommandConfirmationDialogTitle>
        <DialogContent>
          {typeof body === "string" ? (
            <DialogContentText id="alert-dialog-slide-description">
              {body}
            </DialogContentText>
          ) : (
            body
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>
            {buttonText || t("button_confirmation")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
