import React, { useState, useReducer, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import difference from "lodash/difference";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { prism } from "@tsg/1st-grpc-web";
import {
  LIST_CONNECTIONS,
  LIST_WAGERING_SOURCES,
  LIST_PARIMUTUEL_PROGRAMS
} from "common/QueryKeys";
import InfoPopover from "components/info-popover";
import IpTextInput from "components/ip-text-input";
import { useSnackbar } from "components/snackbar-context/snackbar-context";
import VirtualizedAutocomplete from "components/virtualized-autocomplete";
import { useDataApi } from "hooks/api/data/useDataApi";
import { useItspApi } from "hooks/api/itsp/useItspApi";
import { useWageringSourceApi } from "hooks/api/wagering-source/wagering-source-api";
import useDebouncedNameValue from "hooks/useDebounce";
import { enumKeyToLabel } from "utils/enum-parser";
import useStyles from "./styles";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";

interface ItspConnectionState extends Partial<prism.v1.itsp.ItspConnection> {
  org: string;
  displayNameValue: string;
}

interface IProps {
  connection?: prism.v1.itsp.IItspConnection;
  onCancel?: () => void;
  onSave?: () => void;
  isLoading?: boolean;
  onCreateUpdate?: () => void;
  onError?: () => void;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
}

type TAction = {
  type: string;
  fieldName?: keyof ItspConnectionState;
  connection?: prism.v1.itsp.IItspConnection;
  value?:
    | string
    | string[]
    | number
    | prism.v1.itsp.ItspConnectionEnums.ItspVersion
    | prism.v1.itsp.ItspConnectionEnums.ItspRole
    | prism.v1.itsp.ItspConnectionEnums.ConnectionState
    | prism.v1.itsp.ItspConnectionEnums.ConnectionType;
};

const initialState: ItspConnectionState = {
  org: "",
  sources: [],
  displayNameValue: "",
  itspVersion:
    prism.v1.itsp.ItspConnectionEnums?.ItspVersion.ITSP_VERSION_UNSPECIFIED,
  itspRole: prism.v1.itsp.ItspConnectionEnums?.ItspRole.ITSP_ROLE_UNSPECIFIED,
  ipAddress: "",
  port: undefined,
  parimutuelPrograms: [],
  connectionState:
    prism.v1.itsp.ItspConnectionEnums.ConnectionState
      .CONNECTION_STATE_UNSPECIFIED,
  connectionType:
    prism.v1.itsp.ItspConnectionEnums.ConnectionType
      .CONNECTION_TYPE_UNSPECIFIED,
  localConnectionId: "",
  remoteConnectionId: ""
};

const mapConnectionToState = connection => {
  if (connection) {
    return {
      ...initialState,
      ...connection,
      org: connection.name.split("/")[1],
      displayNameValue: connection.displayName.value
    };
  }
  return initialState;
};

export const reducer = (
  state: ItspConnectionState,
  action: TAction
): ItspConnectionState => {
  switch (action.type) {
    case "CHANGE_FIELD": {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }
    case "CHANGE_TEXT_FIELD": {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }
    case "REFRESH": {
      return {
        ...mapConnectionToState(action.connection)
      };
    }
    case "RESET": {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};

const ConnectionForm = (props: IProps) => {
  const {
    connection,
    onCancel,
    onSave,
    isLoading,
    onCreateUpdate,
    onError,
    handleSuccess,
    handleError
  } = props;
  const { root } = useStyles();
  const { t } = useTranslation();
  const {
    useAddParimutuelPrograms,
    useCreateItspConnection,
    useUpdateItspConnection,
    useUpdateWageringSources,
    useRemoveParimutuelPrograms
  } = useItspApi();
  const { useListWageringSources } = useWageringSourceApi();
  const { useListParimutuelPrograms } = useDataApi();
  const { showSuccessSnack } = useSnackbar();

  const queryClient = useQueryClient();
  const [filterFocus, setFilterFocus] = useState<boolean>(false);
  const [readonly, setReadonly] = useState<boolean>(Boolean(connection));
  const [sourcesFilter, setSourcesFilter] = useState<string>("");
  const debouncedSourcesFilter: string = useDebouncedNameValue(sourcesFilter, {
    timeout: 500,
    startFrom: 2
  });
  const [state, dispatch] = useReducer(
    reducer,
    connection,
    mapConnectionToState
  );

  const upsertCallback = () => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [LIST_CONNECTIONS] });
      },
      onError: errorResponse => {
        handleError?.(errorResponse?.message);
      }
    };
  };

  useEffect(() => {
    if (readonly) {
      dispatch({
        type: "REFRESH",
        connection
      });
    }
  }, [connection]);

  const handleChange =
    <T = { target: { value: unknown } },>(fieldName) =>
    (e: T): void => {
      dispatch({
        type: "CHANGE_FIELD",
        fieldName,
        value: e.target.value
      });
    };

  const handleAutocompleteChange =
    <T = { target: { value: string[] } },>(fieldName) =>
    (e: T, value: string[]): void => {
      dispatch({
        type: "CHANGE_FIELD",
        fieldName,
        value
      });
    };

  const { mutateAsync: createItspConnection, isPending: isCreating } =
    useCreateItspConnection(upsertCallback());

  const { mutateAsync: updateItspConnection, isPending: isUpdating } =
    useUpdateItspConnection(upsertCallback());

  const {
    mutateAsync: updateWageringSources,
    isPending: isUpdatingWageringSources
  } = useUpdateWageringSources();

  const {
    mutateAsync: addParimutuelPrograms,
    isPending: isUpdatingParimutuelPrograms
  } = useAddParimutuelPrograms();

  const {
    mutateAsync: removeParimutuelPrograms,
    isPending: isRemovingParimutuelPrograms
  } = useRemoveParimutuelPrograms();

  const { isLoading: isWageringSourcesLoading, data: wageringSourcesData } =
    useListWageringSources(LIST_WAGERING_SOURCES, {
      filter: debouncedSourcesFilter
        ? [
            {
              org: [debouncedSourcesFilter]
            }
          ]
        : undefined,
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT,
        includeSummary: true
      }
    });

  const {
    isLoading: isParimutuelProgramsLoading,
    data: parimutuelProgramsData
  } = useListParimutuelPrograms(LIST_PARIMUTUEL_PROGRAMS, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT,
      includeSummary: true
    }
  });

  const handleSaveClick = async () => {
    onCreateUpdate?.();
    const id = v4();
    const { org, displayNameValue, parimutuelPrograms, sources, ...rest } =
      state;
    const itspConnection = {
      ...rest,
      displayName: {
        value: displayNameValue,
        i18n: [
          {
            text: `prism.itsp.${state.displayNameValue
              .trim()
              .replaceAll(/\s+/gi, "-")}`,
            languageCode: "EN"
          }
        ]
      }
    };
    if (connection) {
      const ppsToAdd = difference(
        state.parimutuelPrograms,
        connection.parimutuelPrograms
      );
      const ppsToRemove = difference(
        connection.parimutuelPrograms,
        state.parimutuelPrograms
      );

      updateItspConnection({
        itspConnection,
        fieldMask: {
          paths: [
            "displayName",
            "ipAddress",
            "port",
            "itspVersion",
            "localConnectionId",
            "remoteConnectionId"
          ]
        }
      })
        .then(updatedConnection =>
          ppsToAdd.length
            ? addParimutuelPrograms({
                name: connection.name,
                parimutuelPrograms: ppsToAdd
              })
            : Promise.resolve(updatedConnection)
        )
        .then(updatedConnection =>
          ppsToRemove.length
            ? removeParimutuelPrograms({
                name: connection.name,
                parimutuelPrograms: ppsToRemove
              })
            : Promise.resolve(updatedConnection)
        )
        .then(() =>
          updateWageringSources({
            name: connection.name,
            wageringSources: state.sources
          })
        )
        .then(() =>
          queryClient.invalidateQueries({ queryKey: [LIST_CONNECTIONS] })
        )
        .then(() => {
          setReadonly(true);
          handleSuccess?.(t("connection_form.saved")) ||
            showSuccessSnack(t("connection_form.saved"));
          onSave?.();
        })
        .catch(error => {
          console.error(error);
          onError?.();
        });
    } else {
      try {
        await createItspConnection({
          itspConnection: {
            ...itspConnection,
            name: `org/${org}/itspConnections/${id}`,
            parimutuelPrograms,
            sources,
            itspVersion:
              prism.v1.itsp.ItspConnectionEnums?.ItspVersion
                .ITSP_VERSION_VERSION_6_0_0,
            localConnectionId: v4(),
            remoteConnectionId: v4(),
            connectionType:
              prism.v1.itsp.ItspConnectionEnums.ConnectionType
                .CONNECTION_TYPE_SOCKET,
            connectionState:
              prism.v1.itsp.ItspConnectionEnums.ConnectionState
                .CONNECTION_STATE_ACTIVE
          }
        });

        await queryClient.invalidateQueries({ queryKey: [LIST_CONNECTIONS] });

        handleSuccess?.(t("connection_form.created")) ||
          showSuccessSnack(t("connection_form.created"));
        onSave?.();
      } catch (error) {
        console.error(error);
        onError?.();
      }
    }
  };

  const handleCancelClick = () => {
    dispatch({
      type: "RESET"
    });
    onCancel?.();
  };

  const handleEditClick = () => {
    setReadonly(false);
  };

  const handleSourcesFilterChange = event => {
    setSourcesFilter(event.target.value);
  };

  const handleFilterFocus = () => {
    setFilterFocus(true);
  };

  const handleFilterBlur = () => {
    setFilterFocus(false);
  };

  const handleReduceDataMap = (data: any) => {
    return (
      data?.reduce((acc, cur) => {
        return { ...acc, [cur.name]: cur };
      }, {}) || {}
    );
  };

  const wageringSourcesMap = handleReduceDataMap(
    wageringSourcesData?.wageringSources
  );

  const parimutuelProgramsMap = handleReduceDataMap(
    parimutuelProgramsData?.parimutuelPrograms
  );

  const isSomethingLoading = [
    isLoading,
    isCreating,
    isUpdating,
    isUpdatingWageringSources,
    isUpdatingParimutuelPrograms,
    isRemovingParimutuelPrograms
  ].some(Boolean);

  useEffect(() => {
    if (isSomethingLoading) {
      onCreateUpdate?.();
    }
  }, [isSomethingLoading]);

  const itspRoles = prism.v1.itsp.ItspConnectionEnums?.ItspRole || {};
  const itspVersions = prism.v1.itsp.ItspConnectionEnums?.ItspVersion || {};

  return (
    <Box sx={{ position: "relative" }} padding={2} className={root}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item flexGrow={1}>
              <Typography variant={"h6"}>
                {connection
                  ? t("connection_form.title_details")
                  : t("connection_form.title_create")}
              </Typography>
            </Grid>
            {Boolean(connection) && (
              <>
                <Grid item>
                  {readonly ? (
                    <IconButton
                      onClick={handleEditClick}
                      disabled={
                        connection.connectionState ===
                        prism.v1.itsp.ItspConnectionEnums.ConnectionState
                          .CONNECTION_STATE_ARCHIVED
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={handleSaveClick}
                      disabled={isSomethingLoading}
                    >
                      <SaveIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  <InfoPopover>
                    <Stack>
                      <Typography>
                        <b>{t("connection_form.info_name")}</b>{" "}
                        {connection.name}
                      </Typography>
                      <Typography>
                        <b>{t("connection_form.info_local_id")}</b>{" "}
                        {connection.localConnectionId}
                      </Typography>
                      <Typography>
                        <b>{t("connection_form.info_remote_id")}</b>{" "}
                        {connection.remoteConnectionId}
                      </Typography>
                    </Stack>
                  </InfoPopover>
                </Grid>
              </>
            )}
          </Grid>
          <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
            <TextField
              margin="dense"
              id="displayNameValue"
              label={t("connection_form.label.displayNameValue")}
              type="text"
              fullWidth
              variant="standard"
              disabled={readonly}
              value={state.displayNameValue}
              onChange={handleChange("displayNameValue")}
            />
            <TextField
              margin="dense"
              id="org"
              label={t("connection_form.label.org")}
              type="text"
              fullWidth
              variant="standard"
              disabled={readonly}
              value={state.org}
              onChange={handleChange("org")}
            />
          </Stack>
          <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="itsp-role-label">
                {t("connection_form.label.itspRole")}
              </InputLabel>
              <Select
                labelId="itsp-role-label"
                value={state.itspRole}
                onChange={handleChange("itspRole")}
                label={t("connection_form.label.itspRole")}
                disabled={Boolean(connection)}
              >
                {Object.keys(itspRoles).map(key => (
                  <MenuItem value={itspRoles[key]} key={key}>
                    {enumKeyToLabel(key, "ITSPROLE_")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="itsp-role-version">
                {t("connection_form.label.itspVersion")}
              </InputLabel>
              <Select
                labelId="itsp-role-version"
                value={
                  state.itspVersion ||
                  prism.v1.itsp.ItspConnectionEnums?.ItspVersion
                    ?.ITSP_VERSION_VERSION_6_0_0
                }
                onChange={handleChange("itspVersion")}
                label={t("connection_form.label.itspVersion")}
                disabled={readonly}
              >
                {Object.keys(itspVersions).map(key => (
                  <MenuItem value={itspVersions[key]} key={key}>
                    {enumKeyToLabel(key, "ITSPVERSION_")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={"row"} sx={{ alignItems: "end" }}>
            {!readonly && (
              <TextField
                variant="standard"
                margin="dense"
                label={t("connection_form.label.sources_filter")}
                placeholder={
                  readonly
                    ? ""
                    : t("connection_form.placeholder.sources_filter")
                }
                type="text"
                disabled={readonly}
                value={sourcesFilter}
                onChange={handleSourcesFilterChange}
                onFocus={handleFilterFocus}
                onBlur={handleFilterBlur}
                sx={{
                  width: "130px"
                }}
              />
            )}
            <VirtualizedAutocomplete
              open={filterFocus}
              fullWidth
              multiple
              openOnFocus
              disableCloseOnSelect
              options={Object.keys(wageringSourcesMap)}
              loading={isWageringSourcesLoading}
              getOptionLabel={(option: string) =>
                wageringSourcesMap[option]?.displayName?.value || option
              }
              onChange={handleAutocompleteChange("sources")}
              value={state.sources}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option + wageringSourcesMap[option]?.displayName.value}
                  title={option}
                >
                  {wageringSourcesMap[option]?.displayName.value || option}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant="standard"
                  label={t("connection_form.label.sources")}
                  placeholder={
                    readonly ? "" : t("connection_form.placeholder.sources")
                  }
                  onFocus={handleFilterFocus}
                  onBlur={handleFilterBlur}
                />
              )}
              readOnly={readonly}
              disabled={readonly}
            />
          </Stack>
          <Autocomplete
            multiple
            options={Object.keys(parimutuelProgramsMap)}
            disableCloseOnSelect
            getOptionLabel={(option: string) =>
              parimutuelProgramsMap[option]?.displayName.value || option
            }
            loading={isParimutuelProgramsLoading}
            onChange={handleAutocompleteChange("parimutuelPrograms")}
            value={state.parimutuelPrograms}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option + parimutuelProgramsMap[option]?.displayName.value}
                title={option}
              >
                {parimutuelProgramsMap[option]?.displayName.value || option}
              </li>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={t("connection_form.label.parimutuelPrograms")}
                placeholder={
                  readonly
                    ? ""
                    : t("connection_form.placeholder.parimutuelPrograms")
                }
              />
            )}
            readOnly={readonly}
            disabled={readonly}
          />
          <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
            <IpTextInput
              onChange={handleChange("ipAddress")}
              value={state.ipAddress}
              disabled={readonly}
            />
            <TextField
              margin="dense"
              id="port"
              label={t("connection_form.label.port")}
              fullWidth
              type="number"
              variant="standard"
              onChange={handleChange("port")}
              value={state.port}
              disabled={readonly}
            />
          </Stack>
          <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
            <TextField
              margin="dense"
              id="localConnectionId"
              label={t("connection_form.label.localConnectionId")}
              fullWidth
              variant="standard"
              onChange={handleChange("localConnectionId")}
              value={state.localConnectionId}
              disabled={readonly}
            />
            <TextField
              margin="dense"
              id="remoteConnectionId"
              label={t("connection_form.label.remoteConnectionId")}
              fullWidth
              variant="standard"
              onChange={handleChange("remoteConnectionId")}
              value={state.remoteConnectionId}
              disabled={readonly}
            />
          </Stack>
        </Stack>

        {!connection && (
          <Grid container justifyContent={"right"}>
            <Button onClick={handleSaveClick} disabled={isSomethingLoading}>
              {t("connection_action_button.save")}
            </Button>
            {onCancel && (
              <Button onClick={handleCancelClick} disabled={isSomethingLoading}>
                {t("connection_action_button.cancel")}
              </Button>
            )}
          </Grid>
        )}
      </Stack>
    </Box>
  );
};

export default ConnectionForm;
