import React from "react";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { prism } from "@tsg/1st-grpc-web";

import TableContainer from "@mui/material/TableContainer";
import { Box, Paper } from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { convertMoneyToDouble } from "../../../common/helper";
import ISegmentResult = prism.v1.transaction.ISegmentResult;

export interface IEnchantedMarketPrice
  extends prism.v1.transaction.IMarketPriceConfiguration {
  marketNested?: prism.v1.data.IMarket;
  marketPriceConfigurationPrices?: prism.v1.transaction.MarketPriceConfigurationPrice[];
}

const getMarketResultText = (marketResult: ISegmentResult[]) => {
  return marketResult.map(sr => sr.selections.join(",")).join("/");
};
const columns = (t): GridColDef[] => [
  {
    field: "marketPriceConfigurationName",
    headerName: t("market_price.market_price_configuration"),
    width: "200"
  },
  {
    field: "segmentResults",
    headerName: t("market_price.winning_combination"),
    width: "200",
    renderCell: params => {
      return (
        <Box>
          {params.row.wageringSourcePrices.map(wsp => (
            <List key={wsp.wageringSourceName}>
              {wsp.payableCombos.map((pc, i) => (
                <ListItem key={i} disableGutters disablePadding>
                  <ListItemText
                    primary={getMarketResultText(
                      pc.marketResult.segmentResults
                    )}
                    sx={{ pl: 1, textAlign: "right" }}
                  />
                  {i === wsp.payableCombos.length - 1 && <Divider />}
                </ListItem>
              ))}
            </List>
          ))}
        </Box>
      );
    },
    valueGetter: params => {
      return params.row.wageringSourcePrices.map(wsp =>
        wsp.payableCombos
          .map(pc => getMarketResultText(pc.marketResult.segmentResults))
          .join(" / ")
      );
    }
  },
  {
    field: "unitPrice",
    headerName: t("market_price.mpc_price"),
    width: "200",
    renderCell: ({ row }) => {
      return (
        <Box>
          {row.wageringSourcePrices.map(wsp => (
            <List key={wsp.wageringSourceName}>
              {wsp.payableCombos.map((pc, i) => (
                <ListItem key={i} disableGutters disablePadding>
                  <ListItemText
                    primary={convertMoneyToDouble(pc.unitPrice?.amount).toFixed(
                      2
                    )}
                    sx={{ pl: 1, textAlign: "right" }}
                  />
                </ListItem>
              ))}
            </List>
          ))}
        </Box>
      );
    },
    valueGetter: ({ row }) => {
      return row.wageringSourcePrices.map(wsp =>
        wsp.payableCombos.map(pc =>
          convertMoneyToDouble(pc.unitPrice?.amount).toFixed(2)
        )
      );
    }
  },
  {
    field: "mpcPriceState",
    headerName: t("market_price.mpc_price_state"),
    width: "200",
    valueGetter: ({ row }) =>
      t(
        `mpc_price_state.${
          prism.v1.transaction.MarketPriceEnums.MpcPriceState[row.mpcPriceState]
        }`
      )
  }
];
export default function MarketPricesDataPanelTable({
  rows
}: {
  rows: IEnchantedMarketPrice;
}) {
  const { t } = useTranslation();
  return (
    <TableContainer
      sx={{
        display: "flex",
        justifyContent: "flex-end"
      }}
      component={Paper}
    >
      <Box sx={{ width: "80%" }}>
        <DataGridPro
          columns={columns(t)}
          rows={rows.marketPriceConfigurationPrices}
          getRowId={row => row.marketPriceConfigurationName}
          isCellEditable={() => false}
          sortingOrder={["asc", "desc"]}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          getRowHeight={() => "auto"}
        />
      </Box>
    </TableContainer>
  );
}
