import React from "react";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Field, Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import { prism } from "@tsg/1st-grpc-web";
import { useDataApi } from "hooks/api/data/DataAPI";
import ForeignIdentifier from "../../../common/ForeignId/foreign-id";
import {
  DATA_PARIMUTUEL_PROGRAM_KEY,
  DATA_MARKET_SOURCE_KEY,
  MARKET_SOURCES_KEY
} from "common/QueryKeys";
import Autocomplete from "@mui/material/Autocomplete";
import { DropdownInput } from "components/common/Input";
import AutocompleteFilterField from "components/common/AutocompleteFilterField/autocomplete-filter-field";
interface Props {
  row?: prism.v1.data.IParimutuelProgram;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
}

function ParimutuelProgramUpsert({ row, handleSuccess, handleError }: Props) {
  const { t } = useTranslation();
  const {
    useCreateParimutuelProgram,
    useUpdateParimutuelProgram,
    useListParimutuelPrograms,
    useListMarketSources
  } = useDataApi();
  const queryClient = useQueryClient();

  const { data, isLoading } = useListParimutuelPrograms(
    DATA_PARIMUTUEL_PROGRAM_KEY,
    {
      pagingOptions: {
        maxResults: 1000
      }
    }
  );

  const { data: dataSourcesData } = useListMarketSources(
    DATA_MARKET_SOURCE_KEY,
    {
      pagingOptions: {
        maxResults: 1000
      }
    }
  );
  const sources = dataSourcesData?.marketSources || [];

  const upsertCallback = successMessage => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: [DATA_PARIMUTUEL_PROGRAM_KEY]
        });
        handleSuccess(t(successMessage));
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };

  const { mutate: mutateAdd, isPending: addIsLoading } =
    useCreateParimutuelProgram(
      upsertCallback("parimutuel_add_success_message")
    );
  const { mutate: mutateUpdate, isPending: updateIsLoading } =
    useUpdateParimutuelProgram(
      upsertCallback("parimutuel_update_success_message")
    );

  return (
    <Box sx={{ padding: "16px 25px" }}>
      <Typography variant="h6">
        {row ? t("update") : t("add")} {t("parimutuel_program")}
      </Typography>
      <Box sx={{ marginTop: "26px", "& .MuiTextField-root": { mb: 2 } }}>
        <Formik
          initialValues={{
            displayName: row?.displayName?.value || "",
            source: row?.source || "",
            foreignIds: row?.foreignIds || [],
            state:
              row?.state ||
              prism.v1.data.ParimutuelProgramEnums.State.STATE_ACTIVE
          }}
          onSubmit={data => {
            if (row) {
              const parimutuelProgramRequest = {
                parimutuelProgram: {
                  name: row.name,
                  displayName: { value: data.displayName },
                  source: data.source,
                  foreignIds: data.foreignIds
                },
                fieldMask: {
                  paths: ["display_name", "source", "foreign_ids"]
                }
              };
              mutateUpdate(parimutuelProgramRequest);
            } else {
              const parimutuelProgramRequest = {
                parimutuelProgram: {
                  displayName: { value: data.displayName },
                  source: data.source,
                  foreignIds: data.foreignIds,
                  state:
                    prism.v1.data.ParimutuelProgramEnums.State.STATE_UNSPECIFIED
                }
              };
              mutateAdd(parimutuelProgramRequest);
            }
          }}
        >
          {formik => (
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                id="displayName"
                label={t("display_name")}
                variant="outlined"
                size="medium"
                fullWidth
                required
                name="displayName"
                onChange={formik.handleChange}
                defaultValue={row?.displayName?.value || ""}
                error={
                  formik.touched.displayName &&
                  Boolean(formik.errors.displayName)
                }
              />
              <Autocomplete
                multiple={false}
                options={sources}
                fullWidth
                getOptionLabel={option =>
                  option.name ||
                  sources.find(p => p.name === String(option))?.name ||
                  String(option)
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("source", newValue?.name);
                }}
                defaultValue={row?.source}
                renderInput={params => (
                  <DropdownInput
                    {...params}
                    formik={formik}
                    field="parent"
                    label={t("parimutuel_source")}
                    placeholder={t("parimutuel_source")}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Field name="foreignIds" component={ForeignIdentifier} />
              <LoadingButton
                variant="contained"
                fullWidth
                disableElevation
                size="large"
                type="submit"
                loading={addIsLoading || updateIsLoading}
                loadingPosition="start"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {row ? t("update_button_text") : t("add_button_text")}
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
export default ParimutuelProgramUpsert;
