import React from "react";
import { IPlatformMenu } from "common/model/platform-menu";
import routes from "common/routes";
import TransactionIcon from "../common/Icons/transaction";
import DashboardOutlined from "@mui/icons-material/DashboardOutlined";

const Transaction: IPlatformMenu = {
  orgFilter: true,
  title: "Transaction",
  icon: <TransactionIcon />,
  sideMenus: [
    {
      text: "Wagering Source",
      icon: <DashboardOutlined />,
      path: routes.wageringSources.path,
      hideDropdowns: false
    },
    {
      text: "Price Configuration",
      icon: <DashboardOutlined />,
      path: routes.priceConfiguration.path,
      hideDropdowns: false
    },
    {
      text: "Bets",
      icon: <DashboardOutlined />,
      path: routes.bets.path,
      hideDropdowns: true
    },
    {
      text: "Market Pools",
      icon: <DashboardOutlined />,
      path: routes.marketPools.path,
      hideDropdowns: true
    },
    {
      text: "Market Network Pools",
      icon: <DashboardOutlined />,
      path: routes.marketNetworkPools.path
    },
    {
      text: "Market Price Configuration",
      icon: <DashboardOutlined />,
      path: routes.marketPriceConfiguration.path,
      hideDropdowns: true
    },
    {
      text: "Market Price",
      icon: <DashboardOutlined />,
      path: routes.marketPrice.path,
      hideDropdowns: true
    },
    {
      text: "Market Odds",
      icon: <DashboardOutlined />,
      path: routes.marketOdds.path,
      hideDropdowns: true
    },
    {
      text: "Market Will Pays",
      icon: <DashboardOutlined />,
      path: routes.marketWillPays.path,
      hideDropdowns: true
    },
    {
      text: "System Integrity",
      icon: <DashboardOutlined />,
      path: routes.systemIntegrity.path,
      hideDropdowns: true
    },
    {
      text: "Market Collation",
      icon: <DashboardOutlined />,
      path: routes.marketCollation.path,
      hideDropdowns: true
    },
    {
      text: "Market Win Summary",
      icon: <DashboardOutlined />,
      path: routes.marketWinSummary.path,
      hideDropdowns: true
    },
    {
      text: "Bet Valuation",
      icon: <DashboardOutlined />,
      path: routes.betValuator.path,
      hideDropdowns: true
    }
  ]
};

export default Transaction;
