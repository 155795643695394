import React from "react";
import MaskedInput from "react-text-mask";
import TextField from "@mui/material/TextField";

interface IProps {
  onChange: (e: React.SyntheticEvent) => void;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
}

const IpMaskInput = React.forwardRef(props => {
  const { ...rest } = props;
  return (
    <MaskedInput
      {...rest}
      guide={false}
      mask={value => {
        const mask = new Array(value.length).fill(/[\d.]/);
        const chunks = value.split(".");
        for (let i = 0; i < 4; ++i) {
          const chunk = chunks[i] || "";
          if (255 % +chunk === 255) {
            mask[value.length - 1] = ".";
            mask[value.length] = /[\d.]/;
          }
        }
        return mask;
      }}
      pipe={value => {
        if (value === "." || value.endsWith("..")) return false;

        const parts = value.split(".");

        if (
          parts.length > 4 ||
          parts.some(
            part => part === "00" || Number(part) < 0 || Number(part) > 255
          )
        ) {
          return false;
        }

        return value;
      }}
      // render={(textMaskRef, props) => (
      //   <input
      //     {...props}
      //     ref={node => {
      //       textMaskRef(node); // Keep this so the component can still function
      //       ref.current = node; // Copy the ref for yourself
      //     }}
      //   />
      // )}
      // placeholderChar={"_"}
      // keepCharPositions={false}
      // showMask
    />
  );
});

const IpTextInput = (props: IProps) => {
  const { onChange, ...rest } = props;

  return (
    <TextField
      {...rest}
      margin="dense"
      id="ipAddress"
      label="IP Address"
      type="text"
      fullWidth
      variant="standard"
      onChange={onChange}
      InputProps={
        !props.disabled && {
          inputComponent: IpMaskInput
        }
      }
      InputLabelProps={
        !props.disabled &&
        props.value?.length > 0 && {
          shrink: true
        }
      }
    />
  );
};

export default IpTextInput;
export { IpMaskInput };
