import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Competitors from "pages/data/Competitor";

import {
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { prism } from "@tsg/1st-grpc-web";
import { convertSecondsToDateString } from "common/helper";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

export interface CompetitorDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  selectedData: any[];
}

function CompetitorDialog(props: CompetitorDialogProps) {
  const { onClose, open, selectedData } = props;

  const handleClose = () => {
    onClose("");
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Competitors selectedData={selectedData} />
    </Dialog>
  );
}

export const Columns = (handleActionClick, t): ExtendedGridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("competition_name"),
      width: 200,
      hide: true
    },
    {
      field: "sourceName",
      headerName: t("competition_source"),
      width: 200,
      editable: false,
      hide: false
    },
    {
      field: "display_name",
      headerName: t("competition_display_name"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.displayName.value
    },
    {
      field: "scheduledStartTime",
      headerName: t("competition_sch_start_time"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.scheduledStartTime);
      }
    },
    {
      field: "estimatedStartTime",
      headerName: t("competition_est_start_time"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.estimatedStartTime);
      }
    },
    {
      field: "type",
      headerName: t("competition_type"),
      width: 200,
      hide: false,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        t(
          `competition_type_enum.${
            prism.v1.data.CompetitionEnums.Type[params.row.type]
          }`
        )
    },
    {
      field: "state",
      headerName: t("competition_state"),
      width: 200,
      editable: false,
      hide: false,
      valueGetter: (params: GridValueGetterParams) =>
        t(
          `competition_state_enum.${
            prism.v1.data.CompetitionEnums.State[params.row.state]
          }`
        )
    },
    {
      field: "title",
      headerName: t("competition_title"),
      width: 200,
      editable: true
    },
    {
      field: "facilityName",
      headerName: t("competition_facility"),
      width: 200,
      editable: false,
      hide: false
    },
    {
      field: "Competitors",
      renderCell: cellValues => {
        const [open, setOpen] = React.useState(false);
        const handleClickOpen = (event, cellValues) => {
          setOpen(true);
          console.log(cellValues.row);
        };

        const handleClose = (value: string) => {
          setOpen(false);
        };
        return (
          <div>
            <Button
              variant="outlined"
              onClick={event => {
                handleClickOpen(event, cellValues);
              }}
            >
              Competitors
            </Button>
            <CompetitorDialog
              open={open}
              onClose={handleClose}
              selectedData={cellValues.row}
            />
          </div>
        );
      }
    },
    {
      field: "approvedTime",
      headerName: t("competition_approved"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.approvedTime);
      }
    },
    {
      field: "actualStartTime",
      headerName: t("competition_actual_time"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.actualStartTime);
      }
    },
    {
      field: "resetTime",
      headerName: t("competition_reset"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.resetTime);
      }
    },
    {
      field: "cancelledTime",
      headerName: t("competition_canceled"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.cancelledTime);
      }
    },
    {
      field: "officialedTime",
      headerName: t("competition_official"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.officialedTime);
      }
    },
    {
      field: "unofficaledTime",
      headerName: t("competition_unofficial"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.unofficialedTime);
      }
    },
    {
      field: "resultedTime",
      headerName: t("competition_resulted"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.resultedTime);
      }
    },
    //cancelreason
    {
      field: "racingCompetition",
      headerName: t("competition_racing"),
      width: 200,
      editable: false,
      hide: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.racingCompetition.raceNumber
    },
    //teamcompetition
    {
      field: "sportName",
      headerName: t("competition_sport_name"),
      width: 200,
      editable: false,
      hide: false
    },
    {
      field: "leagueName",
      headerName: t("competition_league_name"),
      width: 200,
      editable: false,
      hide: false
    },
    {
      field: "eventDate",
      headerName: t("competition_event_date"),
      width: 200,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return (
          params.row?.eventDate.month +
          "/" +
          params.row?.eventDate.day +
          "/" +
          params.row?.eventDate.year
        );
      }
    },
    {
      field: "actions",
      type: "actions",
      width: 40,
      editable: false,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleActionClick("edit", params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleActionClick("delete", params)}
          showInMenu
        />,
        params.row.state ===
        prism.v1.data.CompetitionEnums.State.STATE_INCOMPLETE ? (
          <GridActionsCellItem
            icon={<DoneIcon />}
            label="Approve"
            onClick={() => handleActionClick("approve", params)}
            showInMenu
          />
        ) : params.row.state ===
          prism.v1.data.CompetitionEnums.State.STATE_APPROVED ? (
          <GridActionsCellItem
            icon={<DoneIcon />}
            label="Liven"
            onClick={() => handleActionClick("liven", params)}
            showInMenu
          />
        ) : params.row.state ===
          prism.v1.data.CompetitionEnums.State.STATE_LIVE ? (
          <GridActionsCellItem
            icon={<DoneIcon />}
            label="Official"
            onClick={() => handleActionClick("official", params)}
            showInMenu
          />
        ) : params.row.state ===
          prism.v1.data.CompetitionEnums.State.STATE_OFFICIAL ? (
          <GridActionsCellItem
            icon={<DoneIcon />}
            label="Unofficial"
            onClick={() => handleActionClick("unofficial", params)}
            showInMenu
          />
        ) : (
          <></>
        )
      ]
    }
  ];
};
export default Columns;
