import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { prism } from "@tsg/1st-grpc-web";
import { ConnectionCommands } from "common/utils/enum-utils";
import { LIST_WAGERING_SOURCES } from "common/QueryKeys";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import { useWageringSourceApi } from "hooks/api/wagering-source/wagering-source-api";
import { CommandConfirmationDialog } from "../../common/Dialog";
import VirtualizedAutocomplete from "../../virtualized-autocomplete";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value: Record<string, string[]>) => void;
  title?: string;
  row?: prism.v1.itsp.IItspConnection;
  command: ConnectionCommands;
}
const ChipWrapper = styled(Box)({
  display: "flex",
  gap: 8,
  flexWrap: "wrap"
});
const GuestConnectionWageringSourcesModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  row,
  command
}: IProps) => {
  const { t } = useTranslation();
  const { useListWageringSources } = useWageringSourceApi();
  const isShowChips = [
    ConnectionCommands.ADD_GUEST_CONNECTION_WAGERING_SOURCES,
    ConnectionCommands.REMOVE_GUEST_CONNECTION_WAGERING_SOURCES
  ].includes(command);
  const [data, setData] = useState<string[]>(isShowChips ? [] : row.sources);
  const handleConfirm = () => {
    onConfirm({ wageringSources: data });
  };
  const handleClose = () => {
    setData([]);
    onClose();
  };
  const { isLoading: isWageringSourcesLoading, data: wageringSourcesData } =
    useListWageringSources(LIST_WAGERING_SOURCES, {
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT,
        includeSummary: true
      }
    });
  const handleAutocompleteChange =
    <T = { target: { value: string[] } },>() =>
    (e: T, value: string[]): void => {
      setData(value);
    };
  const handleReduceDataMap = (data: any) => {
    return (
      data?.reduce((acc, cur) => {
        return { ...acc, [cur.name]: cur };
      }, {}) || {}
    );
  };
  const wageringSourcesMap = handleReduceDataMap(
    wageringSourcesData?.wageringSources
  );
  const handleDelete = (source: string) => () => {
    setData([...data, source]);
  };
  return (
    <>
      <CommandConfirmationDialog
        onConfirm={handleConfirm}
        onClose={handleClose}
        isOpen={isOpen}
        title={title}
        body={
          <>
            <Box sx={{ marginBottom: "16px" }}>
              {t("connections_command_title", { connectionName: row.name })}
            </Box>
            {isShowChips && (
              <ChipWrapper>
                {row.sources
                  .filter(item => !data.includes(item))
                  .map(source => (
                    <Chip
                      label={
                        wageringSourcesMap[source]?.displayName?.value || source
                      }
                      key={source}
                      onDelete={
                        command ===
                          ConnectionCommands.REMOVE_GUEST_CONNECTION_WAGERING_SOURCES &&
                        handleDelete(source)
                      }
                    />
                  ))}
              </ChipWrapper>
            )}
            {command !==
              ConnectionCommands.REMOVE_GUEST_CONNECTION_WAGERING_SOURCES && (
              <VirtualizedAutocomplete
                fullWidth
                multiple
                openOnFocus
                disableCloseOnSelect
                options={Object.keys(wageringSourcesMap)}
                loading={isWageringSourcesLoading}
                getOptionLabel={(option: string) =>
                  wageringSourcesMap[option]?.displayName?.value || option
                }
                onChange={handleAutocompleteChange()}
                value={data}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={option + wageringSourcesMap[option]?.displayName.value}
                    title={option}
                  >
                    {wageringSourcesMap[option]?.displayName.value || option}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="standard"
                    label={t("wageringSources.label")}
                    placeholder={t("wageringSources.placeholder")}
                  />
                )}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default GuestConnectionWageringSourcesModal;
