import React, { useState } from "react";
import { GridComponent } from "components/common/Grid";
import { useTranslation } from "react-i18next";
import BetsFilter, {
  FilterValues
} from "components/transaction/Bets/BetsFilter/bets-filter";
import Columns from "./column";
import { usePageData } from "./use-page-data";

export default function BetsListPage() {
  const { t } = useTranslation();
  const [filterValues, setFilterValues] = useState<FilterValues>({});
  const { bets, isLoading, refetch, isRefetching } = usePageData(filterValues);
  const handleFilterChange = values => setFilterValues(values);

  return (
    <>
      <BetsFilter onFilterChange={handleFilterChange} />
      <GridComponent
        idProp={"name"}
        rowData={bets}
        columnDefs={Columns(t)}
        pageTitle={t("bets")}
        initialState={{
          sorting: {
            sortModel: [{ field: "shortName", sort: "asc" }]
          }
        }}
        loading={isLoading}
        editType="none"
        rowHeight={"auto"}
        disableAddButton={true}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
        showMultiFilter
      >
        <></>
      </GridComponent>
    </>
  );
}
