import React from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { convertSecondsToDateString } from "common/helper";
import { prism } from "@tsg/1st-grpc-web";
import { ITimestamp } from "@tsg/1st-grpc-js/lib/google/protobuf/timestamp_pb";
import CustomCell from "components/common/Grid/custom-cell";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

const getIsHiddenStatus = (params: GridValueGetterParams) => {
  const {
    sorting: { sortedRows },
    rows: { dataRowIdToModelLookup }
  } = params.api.state;
  const currentRowIndex = sortedRows.indexOf(params.id);
  const prevRowData = dataRowIdToModelLookup[sortedRows[currentRowIndex - 1]];
  return !(prevRowData?.name !== params.row.name);
};

const valueGetter =
  (valueModifier?: (value: string | ITimestamp) => string) =>
  (params: GridValueGetterParams) => {
    const currentValue = params.value;

    const isHidden = getIsHiddenStatus(params);

    const value = valueModifier ? valueModifier(currentValue) : currentValue;

    if (isHidden) {
      return "";
    }

    return value;
  };

export const Columns = (t): ExtendedGridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("market_odds_name"),
      width: 250,
      editable: false,
      sortable: false,
      hide: true,
      renderCell: (params: GridValueGetterParams) => (
        <CustomCell params={params} />
      ),
      valueGetter: valueGetter()
    },
    {
      field: "market",
      headerName: t("market_odds_market_source_display_name"),
      width: 250,
      editable: false,
      renderCell: (params: GridValueGetterParams) => (
        <CustomCell params={params} />
      ),
      valueGetter: valueGetter()
    },
    {
      field: "marketName",
      headerName: t("market_odds_market_display_name"),
      width: 250,
      editable: false,
      renderCell: (params: GridValueGetterParams) => (
        <CustomCell params={params} />
      ),
      valueGetter: valueGetter()
    },
    {
      field: "marketPriceConfigurationName",
      headerName: t("market_odds_price_configuration"),
      width: 250,
      editable: false,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <CustomCell params={params} />
      ),
      valueGetter: valueGetter()
    },
    {
      field: "state",
      headerName: t("market_odds_market_state"),
      width: 100,
      editable: false,
      renderCell: (params: GridValueGetterParams) => (
        <CustomCell params={params} />
      ),
      valueGetter: valueGetter((value: string) =>
        t(
          `market_odds_state_enum.${prism.v1.transaction.MarketOddsEnums.State[value]}`
        )
      )
    },
    {
      field: "updateTime",
      headerName: t("market_odds_last_update"),
      width: 100,
      editable: false,
      renderCell: (params: GridValueGetterParams) => (
        <CustomCell params={params} />
      ),
      valueGetter: valueGetter((updateTime: string) =>
        convertSecondsToDateString(updateTime)
      )
    },
    {
      field: "combination",
      headerName: t("market_odds_selections"),
      width: 100,
      editable: false
    },
    {
      field: "fractionalOdds",
      headerName: t("market_odds_current_odds"),
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <CustomCell params={params} />
      ),
      valueGetter: valueGetter()
    }
  ];
};
export default Columns;
