import React from "react";
import { IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";

interface IProps {
  onClick: () => void;
  isLoading: boolean;
}

const RefreshButton = ({ onClick, isLoading }: IProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      sx={{ padding: 0, marginLeft: "20px" }}
      disabled={isLoading}
      onClick={onClick}
      title={t("toolbar_refresh")}
      data-testid={"testid-refetch-button"}
    >
      <RefreshIcon />
    </IconButton>
  );
};

export default RefreshButton;
