import React from "react";
import { useTranslation } from "react-i18next";
import { ConnectionCommands } from "common/utils/enum-utils";
import { prism } from "@tsg/1st-grpc-web";
import { CommandConfirmationDialog } from "../../common/Dialog";
import { enumKeyToLabel } from "../../../utils/enum-parser";
import IpTextInput from "../../ip-text-input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { Box } from "@mui/material";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value: Record<string, string>) => void;
  title?: string;
  row?: prism.v1.itsp.IItspConnection;
  command?: ConnectionCommands;
}

const EditConnectionModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  row
}: IProps) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      displayName: row.displayName.value || "",
      itspRole:
        row.itspRole ||
        prism.v1.itsp.ItspConnectionEnums?.ItspRole.ITSP_ROLE_UNSPECIFIED,
      itspVersion:
        row.itspVersion ||
        prism.v1.itsp.ItspConnectionEnums?.ItspVersion.ITSP_VERSION_UNSPECIFIED,
      ipAddress: row.ipAddress || "",
      port: row.port || undefined,
      localConnectionId: row.localConnectionId || "",
      remoteConnectionId: row.remoteConnectionId || ""
    },
    onSubmit: values => {
      const { displayName, ...rest } = values;
      const data = {
        itspConnection: {
          ...row,
          ...rest,
          displayName: {
            value: displayName,
            i18n: [
              {
                text: `prism.itsp.${displayName
                  .trim()
                  .replaceAll(/\s+/gi, "-")}`,
                languageCode: "EN"
              }
            ]
          }
        },
        fieldMask: {
          paths: [
            "displayName",
            "itspVersion",
            "ipAddress",
            "port",
            "localConnectionId",
            "remoteConnectionId"
          ]
        }
      };
      onConfirm(data);
    }
  });
  const handleConfirm = () => {
    formik.handleSubmit();
  };
  const handleClose = () => {
    formik.resetForm();
    onClose();
  };
  const itspVersions = prism.v1.itsp.ItspConnectionEnums?.ItspVersion || {};

  return (
    <>
      <CommandConfirmationDialog
        onConfirm={handleConfirm}
        onClose={handleClose}
        isOpen={isOpen}
        title={title}
        buttonText={t("button_update")}
        body={
          <>
            <Box sx={{ marginBottom: "16px" }}>
              {t("connections_command_title", { connectionName: row.name })}
            </Box>
            <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
              <TextField
                margin="dense"
                id="displayName"
                name="displayName"
                label={t("connections_update_form_labels.displayName")}
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.displayName}
                onChange={formik.handleChange}
              />
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }} spacing={2}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="itsp-role-version">Version</InputLabel>
                <Select
                  labelId="itsp-role-version"
                  name={"itspVersion"}
                  value={
                    formik.values.itspVersion ||
                    prism.v1.itsp.ItspConnectionEnums?.ItspVersion
                      ?.ITSP_VERSION_VERSION_6_0_0
                  }
                  onChange={formik.handleChange}
                  label={t("connections_update_form_labels.itspVersion")}
                >
                  {Object.keys(itspVersions).map(key => (
                    <MenuItem value={itspVersions[key]} key={key}>
                      {enumKeyToLabel(key, "ITSPVERSION_")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%", alignItems: "baseline" }}
              spacing={2}
            >
              <IpTextInput
                name={"ipAddress"}
                onChange={formik.handleChange}
                value={formik.values.ipAddress}
              />
              <TextField
                margin="dense"
                id="port"
                label={t("connections_update_form_labels.port")}
                fullWidth
                type="number"
                variant="standard"
                name={"port"}
                onChange={formik.handleChange}
                value={formik.values.port}
              />
            </Stack>
            <Stack
              direction={"row"}
              sx={{ width: "100%", alignItems: "baseline" }}
              spacing={2}
            >
              <TextField
                margin="dense"
                id="localConnectionId"
                label={t("connections_update_form_labels.localConnectionId")}
                fullWidth
                variant="standard"
                name={"localConnectionId"}
                onChange={formik.handleChange}
                value={formik.values.localConnectionId}
              />
              <TextField
                margin="dense"
                id="remoteConnectionId"
                label={t("connections_update_form_labels.remoteConnectionId")}
                fullWidth
                variant="standard"
                name={"remoteConnectionId"}
                onChange={formik.handleChange}
                value={formik.values.remoteConnectionId}
              />
            </Stack>
          </>
        }
      />
    </>
  );
};

export default EditConnectionModal;
