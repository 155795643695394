import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { ConnectionCommands } from "common/utils/enum-utils";
import { prism } from "@tsg/1st-grpc-web";
import { CommandConfirmationDialog } from "../../common/Dialog";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value: Record<string, string>) => void;
  title?: string;
  row?: prism.v1.itsp.IItspConnection;
  command?: ConnectionCommands;
}
const Wrapper = styled(Box)({
  paddingTop: 8
});
const SettlementReportModal = ({
  isOpen,
  onClose,
  onConfirm,
  title
}: IProps) => {
  const { t } = useTranslation();
  const name = "parimutuelEventName";
  const [data, setData] = useState<string | null>(null);
  const handleConfirm = () => {
    onConfirm({ [name]: data });
  };
  const handleClose = () => {
    setData(null);
    onClose();
  };
  const handleChange = event => setData(event.target.value);
  return (
    <>
      <CommandConfirmationDialog
        onConfirm={handleConfirm}
        onClose={handleClose}
        isOpen={isOpen}
        title={title}
        body={
          <Wrapper>
            <TextField
              fullWidth
              name={name}
              onChange={handleChange}
              value={data}
              label={t("parimutuelEventName.label")}
            />
          </Wrapper>
        }
      />
    </>
  );
};

export default SettlementReportModal;
