import React from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { prism } from "@tsg/1st-grpc-web";
import IAlert = prism.v1.core.IAlert;

import AlertNotificationItem from "./alert-notification-item";

interface OwnProps {
  open: boolean;
  openAlertCenter: () => void;
  onClose: () => void;
  alertsPreview: IAlert[];
}

const AlertNotifications = ({
  open,
  onClose,
  alertsPreview,
  openAlertCenter
}: OwnProps) => {
  const handleAlertClick = () => {
    openAlertCenter();
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1
      }}
    >
      <Card sx={{ width: "600px", borderRadius: 0, boxShadow: "none" }}>
        <CardHeader
          title="Unacknowleged Alerts"
          sx={{
            backgroundColor: "#282535",
            padding: "20px 24px"
          }}
          titleTypographyProps={{
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#FFFFFF"
          }}
          action={
            <Button
              onClick={handleAlertClick}
              variant="contained"
              endIcon={<ChevronRightIcon />}
              startIcon={<NotificationsIcon />}
              sx={{
                backgroundColor: "#282535",
                borderRadius: "6px",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 400,
                border: "1px solid #FFF",
                textTransform: "none",
                height: "40px",
                padding: "6px 14px"
              }}
            >
              Alerts Center
            </Button>
          }
        />
        <CardContent
          sx={{
            padding: 0,
            marginTop: "20px"
          }}
        >
          {alertsPreview?.map((alert, index) => (
            <React.Fragment key={index}>
              <AlertNotificationItem
                key={index}
                subjectType={alert.subjectType}
                parent={alert.parent}
                date={Number(alert.auditInfo.createTime.seconds) * 1000}
                subject={alert.subject}
                description={alert.description.value}
                name={alert.name}
                actions={alert.actions}
                callback={handleAlertClick}
              />
              <Divider
                sx={{
                  margin: "14px 0px"
                }}
              />
            </React.Fragment>
          ))}
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "flex-end",
            padding: "20px"
          }}
        ></CardActions>
      </Card>
    </Drawer>
  );
};

export default AlertNotifications;
