import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { format } from "date-fns";
import { setActiveAlertId } from "store/actions/alerts";
import { useDispatch } from "react-redux";
import AlertSubjectType from "./alert-subject-type";

interface OwnProps {
  subjectType: string;
  parent: string;
  date: number;
  subject: string;
  description: string;
  name: string;
  callback?: () => void;
}

const AlertMessage = ({
  subjectType,
  parent,
  date,
  subject,
  description,
  name,
  callback
}: OwnProps) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setActiveAlertId(name));
    if (callback) {
      callback();
    }
  };
  return (
    <Box
      sx={{
        cursor: "pointer",
        width: "100%",
        borderRadius: "8px"
      }}
      onClick={handleClick}
      data-testid="alert-message"
    >
      <Grid container spacing={0}>
        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={"auto"}>
            <AlertSubjectType subjectType={subjectType} parent={parent} />
          </Grid>
          <Grid item xs={"auto"}>
            {format(date, "PPPp")}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              "& span": {
                color: "#484686"
              }
            }}
          >
            <b>Subject:</b> <span>{subject}</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              "& span": {
                color: "#484686"
              }
            }}
          >
            <b>Name of Alert:</b> <span>{description}</span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertMessage;
