import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { prism } from "@tsg/1st-grpc-web";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ConnectionCommands } from "common/utils/enum-utils";
import { LIST_PARIMUTUEL_PROGRAMS } from "common/QueryKeys";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import { useDataApi } from "hooks/api/data/useDataApi";
import { CommandConfirmationDialog } from "../../common/Dialog";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (value: Record<string, string[]>) => void;
  title?: string;
  command: ConnectionCommands;
  row?: prism.v1.itsp.IItspConnection;
}

const ChipWrapper = styled(Box)({
  display: "flex",
  gap: 8,
  flexWrap: "wrap"
});
const ParimutuelProgramsModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  command,
  row
}: IProps) => {
  const { t } = useTranslation();
  const { useListParimutuelPrograms } = useDataApi();
  const [data, setData] = useState<string[]>(
    command === ConnectionCommands.REMOVE_PARIMUTUEL_PROGRAMS
      ? row.parimutuelPrograms
      : []
  );
  const handleConfirm = () => {
    onConfirm({ parimutuelPrograms: data });
  };
  const handleClose = () => {
    setData([]);
    onClose();
  };
  const {
    isLoading: isParimutuelProgramsLoading,
    data: parimutuelProgramsData
  } = useListParimutuelPrograms(LIST_PARIMUTUEL_PROGRAMS, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT,
      includeSummary: true
    }
  });
  const handleReduceDataMap = (data: any) => {
    return (
      data?.reduce((acc, cur) => {
        return { ...acc, [cur.name]: cur };
      }, {}) || {}
    );
  };
  const parimutuelProgramsMap = handleReduceDataMap(
    parimutuelProgramsData?.parimutuelPrograms
  );
  const handleAutocompleteChange =
    <T = { target: { value: string[] } },>() =>
    (e: T, value: string[]): void => {
      setData(value);
    };
  const handleDelete = (source: string) => () => {
    setData([...data, source]);
  };
  return (
    <>
      <CommandConfirmationDialog
        onConfirm={handleConfirm}
        onClose={handleClose}
        isOpen={isOpen}
        title={title}
        body={
          <>
            <Box sx={{ marginBottom: "16px" }}>
              {t("connections_command_title", { connectionName: row.name })}
            </Box>
            <ChipWrapper>
              {row.parimutuelPrograms
                .filter(item => !data.includes(item))
                .map(parimutuelProgram => (
                  <Chip
                    label={
                      parimutuelProgramsMap[parimutuelProgram]?.displayName
                        .value || parimutuelProgram
                    }
                    key={parimutuelProgram}
                    onDelete={
                      command ===
                        ConnectionCommands.REMOVE_PARIMUTUEL_PROGRAMS &&
                      handleDelete(parimutuelProgram)
                    }
                  />
                ))}
            </ChipWrapper>
            {command !== ConnectionCommands.REMOVE_PARIMUTUEL_PROGRAMS && (
              <Autocomplete
                multiple
                options={Object.keys(parimutuelProgramsMap)}
                disableCloseOnSelect
                getOptionLabel={(option: string) =>
                  parimutuelProgramsMap[option]?.displayName.value || option
                }
                loading={isParimutuelProgramsLoading}
                onChange={handleAutocompleteChange()}
                value={data}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={
                      option + parimutuelProgramsMap[option]?.displayName.value
                    }
                    title={option}
                  >
                    {parimutuelProgramsMap[option]?.displayName.value || option}
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("parimutuelPrograms.label")}
                    placeholder={t("parimutuelPrograms.placeholder")}
                  />
                )}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default ParimutuelProgramsModal;
