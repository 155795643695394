import React from "react";
import { useSelector } from "react-redux";

import { Tabs, Tab, Typography, Divider, Badge, MenuItem } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CircularProgress from "@mui/material/CircularProgress";

import AlertMessage from "./alert-message";
import { prism } from "@tsg/1st-grpc-web";

type SortingDirection = "asc" | "desc";

interface OwnProps {
  alerts: prism.v1.core.IAlert[];
  isLoading: boolean;
  onChangeSortingDirection: (direction: SortingDirection) => void;
}

const ALERTS_LIST_TABS = [
  "Unacknowledged",
  "Acknowledged",
  "Resolved",
  "Muted"
];

const useStyles = makeStyles(() =>
  createStyles({
    selectedBg: {
      backgroundColor: "#6361B81A !important",
      border: "1px solid #484686 !important",
      borderLeft: "10px solid #484686 !important",
      paddingLeft: "10px !important"
    },
    listItemRoot: {
      marginBottom: "12px",
      borderRadius: "8px",
      border: "1px solid #6361B880",
      padding: "8px 20px"
    },
    divider: {
      borderColor: "#6361B880",
      borderWidth: "1px",
      borderStyle: "solid",
      marginLeft: "-24px",
      marginRight: "-24px",
      marginTop: "-24px"
    }
  })
);

const AlertList = ({
  alerts,
  onChangeSortingDirection,
  isLoading
}: OwnProps) => {
  const classes = useStyles();
  const { activeAlertId } = useSelector((state: any) => state.alerts);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeSortingDirection = ({ target: { value } }) => {
    onChangeSortingDirection(value);
  };
  const renderFilteredAlerts = (
    alerts: prism.v1.core.IAlert[],
    filter: number
  ) => {
    const filtered = alerts?.filter(alert => alert.state === filter);

    return (
      <List
        sx={{
          maxWidth: "800px",
          minWidth: "511px"
        }}
      >
        {filtered &&
          filtered?.map((alert, idx) => (
            <ListItemButton
              key={idx}
              selected={alert.name === activeAlertId}
              disableGutters
              classes={{
                selected: classes.selectedBg,
                root: classes.listItemRoot
              }}
            >
              <AlertMessage
                subjectType={alert.subjectType}
                parent={alert.parent}
                date={alert.auditInfo?.createTime?.seconds * 1000}
                subject={alert.subject}
                description={alert.description?.value}
                name={alert.name}
              />
            </ListItemButton>
          ))}
      </List>
    );
  };

  return (
    <Container
      sx={{
        margin: "24px 0px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "20px"
          }}
        >
          Alerts List
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              color: "#6361B8",
              marginRight: "8px"
            }}
          >
            Sort by:
          </Typography>
          <Select
            variant="standard"
            sx={{
              "& .MuiSelect-icon": {
                color: "#6361B8"
              }
            }}
            onChange={handleChangeSortingDirection}
            defaultValue={"desc"}
          >
            <MenuItem value="desc">Newest First</MenuItem>
            <MenuItem value="asc">Oldest First</MenuItem>
          </Select>
        </Box>
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          marginBottom: "24px"
        }}
      >
        {ALERTS_LIST_TABS.map((tab, index) => (
          <Tab
            sx={{
              overflow: "visible !important",
              marginRight: "24px",
              color: "#6361B8",
              "&.Mui-selected": {
                color: "#282535"
              },
              textTransform: "none",
              fontSize: "16px",
              lineHeight: "20px",
              minHeight: "44px"
            }}
            icon={
              index <= 1 && (
                <Badge
                  showZero
                  badgeContent={
                    alerts?.filter(alert => alert.state === index + 1).length
                  }
                  sx={
                    index === 0
                      ? {
                          "& span": {
                            color: "#FFF",
                            backgroundColor: "#CE5D5D",
                            top: 0,
                            right: -8
                          }
                        }
                      : {
                          "& span": {
                            color: "#6361B8",
                            backgroundColor: "#FFF",
                            border: "1px solid #6361B8",
                            radius: "9px",
                            top: 0,
                            right: -8
                          }
                        }
                  }
                />
              )
            }
            iconPosition="end"
            key={index}
            label={tab}
          />
        ))}
      </Tabs>
      <Divider variant="fullWidth" className={classes.divider} />
      <Container
        disableGutters
        sx={{
          maxHeight: "75vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          marginTop: "24px"
        }}
      >
        {value === 0 && renderFilteredAlerts(alerts, 1)}
        {value === 1 && renderFilteredAlerts(alerts, 2)}
        {value === 2 && renderFilteredAlerts(alerts, 3)}
        {value === 3 && renderFilteredAlerts(alerts, 4)}
        {isLoading && (
          <Box textAlign={"center"}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Container>
  );
};

export default AlertList;
