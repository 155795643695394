import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

import { useAlertApi } from "hooks/api/alerts/useAlertApi";
import { ALERTS_KEY } from "common/QueryKeys";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import AlertNotifications from "./alert-notifications";
import AlertsCenter from "./alerts-center";

const DEFAULT_ALERTS_DISPLAY_LIMIT = 5;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertIconButton = () => {
  const [openAlerts, setOpenAlerts] = useState<boolean>(false);
  const [openNotifications, setOpenNotifications] = useState<boolean>(false);

  const { useListAlerts } = useAlertApi();
  const { data: alertsData, isLoading: isAlertsLoading } = useListAlerts(
    ALERTS_KEY,
    {
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT
      }
    }
  );

  const handleNotifications = () => {
    setOpenNotifications(true);
  };

  const handleCloseAlerts = () => {
    setOpenAlerts(false);
    if (openNotifications) {
      setOpenNotifications(false);
    }
  };

  const badgeContent = alertsData?.alerts?.length || 0;
  const recentAlerts = alertsData?.alerts?.slice(
    0,
    DEFAULT_ALERTS_DISPLAY_LIMIT
  );

  return (
    <>
      <IconButton onClick={handleNotifications} aria-hidden={true}>
        <Badge
          badgeContent={badgeContent}
          sx={{
            "& span": {
              color: "#FFF",
              backgroundColor: "#CD1111",
              border: "1px solid #FFF",
              top: 1,
              right: 1
            }
          }}
        >
          <NotificationsIcon
            sx={{
              color: "#484686"
            }}
          />
        </Badge>
      </IconButton>
      <AlertNotifications
        open={openNotifications}
        openAlertCenter={() => setOpenAlerts(true)}
        onClose={() => setOpenNotifications(false)}
        alertsPreview={recentAlerts}
      />

      <Dialog
        open={openAlerts}
        onClose={handleCloseAlerts}
        fullScreen
        TransitionComponent={Transition}
      >
        <AlertsCenter handleClose={handleCloseAlerts} />
      </Dialog>
    </>
  );
};

export default AlertIconButton;
