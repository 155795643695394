import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import DataUsageSharpIcon from "@mui/icons-material/DataUsageSharp";
import EqualizerSharpIcon from "@mui/icons-material/EqualizerSharp";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GradientIcon from "@mui/icons-material/Gradient";
import GradingIcon from "@mui/icons-material/Grading";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import ListAltSharpIcon from "@mui/icons-material/ListAltSharp";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import PublicIcon from "@mui/icons-material/Public";
import PublishSharpIcon from "@mui/icons-material/PublishSharp";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import ViewCozyOutlinedIcon from "@mui/icons-material/ViewCozyOutlined";
import VideoIcon from "../common/Icons/video";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanIcon from "@mui/icons-material/Lan";
import ITSPIcon from "../common/Icons/itsp";
import DomainIcon from "@mui/icons-material/Domain";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import LanguageIcon from "@mui/icons-material/Language";
import BugReportIcon from "@mui/icons-material/BugReport";
import DataIcon from "../common/Icons/data";

// Temp mapping of the icons to subjectType until a list of all the subject types is provided
const iconMapping = {
  data: DataUsageSharpIcon,
  equalizer: EqualizerSharpIcon,
  exit: ExitToAppIcon,
  gradient: GradientIcon,
  grading: GradingIcon,
  hub: HubOutlinedIcon,
  list: ListAltSharpIcon,
  TestSubjectType: BugReportIcon,
  person: PersonSharpIcon,
  public: PublicIcon,
  publish: PublishSharpIcon,
  smartDisplay: SmartDisplayOutlinedIcon,
  videoCamera: VideoCameraFrontIcon,
  videoLibrary: VideoLibraryOutlinedIcon,
  viewCozy: ViewCozyOutlinedIcon,
  video: VideoIcon,
  email: EmailOutlinedIcon,
  itsp: ITSPIcon,
  global: LanguageIcon,
  Organization: DomainIcon
};

interface OwnProps {
  subjectType: string;
  parent: string;
  fontSize?: string;
}

const AlertSubjectType = ({ subjectType, parent, fontSize }: OwnProps) => {
  const IconComponent = iconMapping[subjectType || "global"];
  if (!IconComponent) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px"
      }}
    >
      <IconComponent fontSize={fontSize || "16px"} />
      <Typography
        sx={{
          fontSize: fontSize || "16px",
          color: "#282535",
          fontWeight: 600,
          lineHeight: "20px",
          textTransform: "uppercase"
        }}
      >
        {/* {parent ? parent + " / " : null} */}
        {subjectType}
      </Typography>
    </Box>
  );
};

export default AlertSubjectType;
