import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import { format } from "date-fns";
import { setActiveAlertId } from "store/actions/alerts";
import { useDispatch } from "react-redux";
import AlertSubjectType from "./alert-subject-type";
import { Container, IconButton, Stack } from "@mui/material";
import AlertActionsButton from "./alert-actions-button";
import { prism } from "@tsg/1st-grpc-web";
import IAlertAction = prism.v1.core.IAlertAction;

interface OwnProps {
  subjectType: string;
  parent: string;
  date: number;
  subject: string;
  description: string;
  name: string;
  callback?: () => void;
  actions: IAlertAction[];
}

const AlertNotificationItem = ({
  subjectType,
  parent,
  date,
  subject,
  description,
  name,
  callback,
  actions
}: OwnProps) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setActiveAlertId(name));
    if (callback) {
      callback();
    }
  };

  return (
    <Container
      sx={{
        cursor: "pointer"
      }}
      onClick={handleClick}
    >
      <Grid
        container
        spacing={0}
        sx={{
          "& > div": {
            marginBottom: "5px"
          }
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={"auto"}>
            <AlertSubjectType subjectType={subjectType} parent={parent} />
          </Grid>
          <Grid item xs={"auto"}>
            <Stack direction="row" spacing={2}>
              <IconButton
                sx={{
                  height: "38px",
                  width: "38px",
                  backgroundColor: "#6361B81A",
                  "&:hover": {
                    backgroundColor: "#6361B81A"
                  }
                }}
              >
                <ForwardToInboxIcon
                  sx={{
                    color: "#282535",
                    "&:hover": {
                      color: "#282535"
                    }
                  }}
                />
              </IconButton>
              <AlertActionsButton
                alertActions={actions}
                alertName={name}
                useIconButton
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={"auto"}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              "& span": {
                color: "#484686"
              }
            }}
          >
            <b>Time Raised:</b> <span>{date && format(date, "PPPp")}</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              "& span": {
                color: "#484686"
              }
            }}
          >
            <b>Subject:</b> <span>{subject}</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              "& span": {
                color: "#484686"
              }
            }}
          >
            <b>Name of Alert:</b> <span>{description}</span>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AlertNotificationItem;
