import IRoutes from "./IRoute";

export default {
  dashboard: {
    path: "/",
    moduleName: "Dashboard"
  },
  account: {
    path: "/account",
    moduleName: "Account"
  },
  videoSource: {
    path: "/video-source",
    moduleName: "Video Source"
  },
  videoChannel: {
    path: "/video-channel",
    moduleName: "Video Channel"
  },
  videoEncoder: {
    path: "/video-encoder",
    moduleName: "Video Encoder"
  },
  videoDecoder: {
    path: "/video-decoder",
    moduleName: "Video Decoder"
  },
  bookings: {
    path: "/bookings",
    moduleName: "Bookings"
  },
  videoConsumer: {
    path: "/video-consumer",
    moduleName: "Video Consumer"
  },
  facility: {
    path: "/facility",
    moduleName: "Facility"
  },
  location: {
    path: "/location",
    moduleName: "Location"
  },
  jockey: {
    path: "/jockey",
    moduleName: "Jockey"
  },
  market: {
    path: "/market",
    moduleName: "Market"
  },
  marketType: {
    path: "/market-type",
    moduleName: "Market Type"
  },
  parimutuelProgram: {
    path: "/parimutuel-program",
    moduleName: "Parimutuel Program"
  },
  markettype: {
    path: "/markettype",
    moduleName: "MarketType"
  },
  competitionplus: {
    path: "/competitionplus",
    moduleName: "CompetitionPlus"
  },
  competition: {
    path: "/competition",
    moduleName: "Competition"
  },
  parimutuelEvent: {
    path: "/parimutuel-event",
    moduleName: "Parimutuel Event"
  },
  allowedcountries: {
    path: "/allowed-countries",
    moduleName: "Allowed Countries"
  },
  organization: {
    path: "/organization",
    moduleName: "Organization"
  },
  organizationalUnit: {
    path: "/organizationalUnit",
    moduleName: "Organizational Units"
  },
  organizationDetail: {
    path: "/organization-detail/:name",
    moduleName: "View Organization"
  },
  multiviewPlayer: {
    path: "/multiview-player",
    moduleName: "Multiview Player"
  },
  videoReplay: {
    path: "/video-replay",
    moduleName: "Video Replay"
  },
  roles: {
    path: "/roles",
    moduleName: "Roles"
  },
  user: {
    path: "/platform-users",
    moduleName: "Users"
  },
  countries: {
    path: "/countries",
    moduleName: "Countries"
  },
  userGroup: {
    path: "/user-groups",
    moduleName: "User Group"
  },
  wageringSources: {
    path: "/wagering-sources",
    moduleName: "Wagering Sources"
  },
  priceConfiguration: {
    path: "/price-configuration",
    moduleName: "Price Configuration"
  },
  roleBinding: {
    path: "/role-bindings",
    moduleName: "Role Binding"
  },
  marketPools: {
    path: "/market-pools",
    moduleName: "Market Pools"
  },
  marketWinSummary: {
    path: "/market-win-summary",
    moduleName: "Market Win Summary"
  },
  marketNetworkPools: {
    path: "/market-network-pools",
    moduleName: "Market Network Pools"
  },
  marketPriceConfiguration: {
    path: "/market-price-configuration",
    moduleName: "Market Price Configuration"
  },
  marketPrice: {
    path: "/market-price",
    moduleName: "Market Price"
  },
  marketOdds: {
    path: "/market-odds",
    moduleName: "Market Odds"
  },
  marketCollation: {
    path: "/market-collation",
    moduleName: "Market Collation"
  },
  marketWillPays: {
    path: "/market-will-pays",
    moduleName: "Market Will Pays"
  },
  systemIntegrity: {
    path: "/system-integrity",
    moduleName: "System-integrity"
  },
  bets: {
    path: "/bets",
    moduleName: "Bets"
  },
  betValuator: {
    path: "/bet-valuator",
    moduleName: "Bet Valuation"
  },
  itspConnections: {
    path: "/itsp-connections",
    moduleName: "ITSP Connections"
  },
  videoStreamPriceConfiguration: {
    path: "/video-stream-pricing",
    moduleName: "Video Stream Price Configuration"
  },
  reports: {
    path: "/reports",
    moduleName: ""
  },
  videoStreamUsage: {
    path: "/reports/video-stream-usage",
    moduleName: "Video Stream Usage"
  },
  highUsage: {
    path: "/reports/high-usage",
    moduleName: "Video Stream High Usage"
  },
  userReport: {
    path: "/reports/user-report",
    moduleName: "Video Stream User Usage"
  },
  alerts: {
    path: "/alerts",
    moduleName: "Alerts"
  },
  videoStreamInvoice: {
    path: "/invoice",
    moduleName: "Video Stream Invoice"
  },
  videoStreamInvoiceHtml: {
    path: "/invoice-html",
    moduleName: "Video Stream Invoice HTML"
  },
  consumerRules: {
    path: "/consumer-rules",
    moduleName: "Consumer Rules"
  },
  authorizedClients: {
    path: "/authorized-clients",
    moduleName: "Authorized Clients"
  },
  userSource: {
    path: "/user-sources",
    moduleName: "User Source"
  }
} as IRoutes;
