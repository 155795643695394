import { DashboardOutlined } from "@mui/icons-material";
import { IPlatformMenu } from "common/model/platform-menu";
import routes from "common/routes";
import React from "react";
import DataIcon from "../common/Icons/data";

const Data: IPlatformMenu = {
  orgFilter: true,
  title: "Data",
  icon: <DataIcon />,
  sideMenus: [
    {
      text: "Jockey",
      icon: <DashboardOutlined color="primary" />,
      path: "/jockey"
    },
    {
      text: "Market",
      icon: <DashboardOutlined color="primary" />,
      path: "/market"
    },
    {
      text: "Market Type",
      icon: <DashboardOutlined color="primary" />,
      path: "/market-type"
    },
    {
      text: "Competition+",
      icon: <DashboardOutlined color="primary" />,
      path: "/competitionplus"
    },
    {
      text: "Competition",
      icon: <DashboardOutlined color="primary" />,
      path: "/competition"
    },
    {
      text: "Parimutuel Event",
      icon: <DashboardOutlined color="primary" />,
      path: "/parimutuel-event"
    },
    {
      text: "Parimutuel Program",
      icon: <DashboardOutlined color="primary" />,
      path: "/parimutuel-program"
    }
  ]
};

export default Data;
