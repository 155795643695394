import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import {
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { prism } from "@tsg/1st-grpc-web";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

export const Columns = (handleActionClick, t): ExtendedGridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("parimutuel_name"),
      width: 400,
      hide: true
    },
    {
      field: "displayName",
      headerName: t("display_name"),
      width: 300,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.displayName.value
    },
    {
      field: "source",
      headerName: t("parimutuel_source"),
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.source
    },
    {
      field: "state",
      headerName: t("column_active_inactive"),
      width: 100,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        t(
          `jockey_state_enum.${
            prism.v1.data.ParimutuelProgramEnums.State[params.row.state]
          }`
        )
    },
    {
      field: "actions",
      type: "actions",
      width: 40,
      editable: false,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleActionClick("edit", params)}
          showInMenu
        />,
        params.row.state === 2 ? (
          <GridActionsCellItem
            icon={<DoneIcon />}
            label="Enable"
            onClick={() => handleActionClick("reenable", params)}
            showInMenu
          />
        ) : params.row.state === 1 ? (
          <GridActionsCellItem
            icon={<ClearIcon />}
            label="Disable"
            onClick={() => handleActionClick("disable", params)}
            showInMenu
          />
        ) : (
          <></>
        )
      ]
    }
  ];
};
