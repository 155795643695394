import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import uniqueId from "lodash/uniqueId";
import { GridComponent } from "components/common/Grid";
import {
  APP_MARGIN_TOP,
  APP_PADDING_VERTICAL
} from "components/style/app-style";
import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import { useDataApi } from "hooks/api/data/DataAPI";
import { MARKETS_KEY } from "common/QueryKeys";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import Columns from "./column";

export default function MarketOddsPage() {
  const { t } = useTranslation();
  const { useListMarkets } = useDataApi();
  const { useListMarketOdds } = useTransactionApi();
  const {
    data: oddsData,
    isLoading: isLoadingMarketOdds,
    refetch: refetchMarketOdds,
    isRefetching: isMarketOddsRefetching
  } = useListMarketOdds({});
  const {
    data: marketsData,
    isLoading: isMarketsLoading,
    refetch: refetchMarkets,
    isRefetching: isMarketsRefetching
  } = useListMarkets(MARKETS_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const marketOddsData = useMemo(() => {
    return oddsData?.marketOdds.reduce((acc, odd) => {
      const rows = [...acc];
      odd.oddsCombos.forEach(combo => {
        const row = {
          rowId: uniqueId("rowId-"),
          source: "",
          market: "",
          marketName: "",
          name: "",
          marketPriceConfigurationName: "",
          state: "",
          updateTime: null,
          combination: "",
          fractionalOdds: ""
        };
        row.source = odd.source;
        row.market =
          marketsData?.markets.find(market => market.name === odd.market)
            ?.sourceName || odd.market;
        row.marketName =
          marketsData?.markets.find(market => market.name === odd.market)
            ?.displayName.value || odd.market;
        row.name = odd.name;
        row.marketPriceConfigurationName = odd.marketPriceConfigurationName;
        row.state = odd.state.toString();
        row.updateTime = odd.auditInfo.updateTime;
        row.combination = combo.combination.join(", ");
        row.fractionalOdds = `${combo.fractionalOdds.numerator}/${combo.fractionalOdds.denominator}`;
        rows.push(row);
      });
      return rows;
    }, []);
  }, [oddsData, marketsData]);

  const refetch = () => {
    refetchMarkets();
    refetchMarketOdds();
  };

  const isRefetching = [isMarketsRefetching, isMarketOddsRefetching].some(
    Boolean
  );

  const isLoading = [isMarketsLoading, isLoadingMarketOdds, isRefetching].some(
    Boolean
  );

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: {
          xs: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.xs * 2
          }px)`,
          md: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.md * 2
          }px)`,
          lg: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.lg * 2
          }px)`
        }
      }}
    >
      <GridComponent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column"
        }}
        idProp={"rowId"}
        rowData={marketOddsData}
        columnDefs={Columns(t)}
        pageTitle={t("market_odds")}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayName", sort: "asc" }]
          }
        }}
        loading={isLoading}
        editType="none"
        rowHeight={50}
        disableAddButton={true}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
      >
        <></>
      </GridComponent>
    </Box>
  );
}
