import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import LogRocket from "logrocket";

import connectionPagesReducer from "./reducers/connection-pages";
import snackbarsReducer from "./reducers/snackbars";
import alertReducer from "./reducers/alerts";

const reducers = combineReducers({
  connectionPages: connectionPagesReducer,
  snackbars: snackbarsReducer,
  alerts: alertReducer
});

export default createStore(
  reducers,
  compose(composeWithDevTools(applyMiddleware(LogRocket.reduxMiddleware())))
);
