import React, { useState, useReducer } from "react";
import omitBy from "lodash/omitBy";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { prism } from "@tsg/1st-grpc-web";
import {
  LIST_PARIMUTUEL_PROGRAMS,
  LIST_WAGERING_SOURCES
} from "common/QueryKeys";
import VirtualizedAutocomplete from "components/virtualized-autocomplete";
import { useWageringSourceApi } from "hooks/api/wagering-source/wagering-source-api";
import { useDataApi } from "hooks/api/data/useDataApi";
import useDebouncedNameValue from "hooks/useDebounce";
import { enumKeyToLabel } from "utils/enum-parser";
import useStyles from "./styles";
import Badge from "@mui/material/Badge";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export type ItspConnectionFilterState = Partial<
  Omit<prism.v1.itsp.ItspConnectionFilter, "displayName">
>;

interface IProps {
  onApply: (filter: ItspConnectionFilterState) => void;
  defaultState: ItspConnectionFilterState;
  showActiveFilterList: boolean;
  onShowActiveFilterList: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type TAction = {
  type: string;
  fieldName?: keyof ItspConnectionFilterState;
  value?:
    | string[]
    | number[]
    | prism.v1.itsp.ItspConnectionEnums.ConnectionState;
};

const initialState: ItspConnectionFilterState = {
  name: [""],
  org: [""],
  source: [],
  itspRole: [],
  parimutuelPrograms: [],
  connectionState: [],
  localConnectionId: [""],
  remoteConnectionId: [""]
};

export const reducer = (
  state: ItspConnectionFilterState,
  action: TAction
): ItspConnectionFilterState => {
  switch (action.type) {
    case "CHANGE_FIELD": {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }
    case "CHANGE_TEXT_FIELD": {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }
    case "RESET": {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

const ConnectionsFilterModal = (props: IProps) => {
  const {
    onApply,
    defaultState = {},
    showActiveFilterList,
    onShowActiveFilterList
  } = props;
  const { root } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...defaultState
  });
  const [filterFocus, setFilterFocus] = useState<boolean>(false);
  const [sourcesFilter, setSourcesFilter] = useState<string>("");
  const debouncedSourcesFilter: string = useDebouncedNameValue(sourcesFilter, {
    timeout: 500,
    startFrom: 2
  });
  const { useListWageringSources } = useWageringSourceApi();
  const { useListParimutuelPrograms } = useDataApi();

  const { isLoading: isWageringSourcesLoading, data: wageringSourcesData } =
    useListWageringSources(LIST_WAGERING_SOURCES, {
      filter: debouncedSourcesFilter
        ? [
            {
              org: [debouncedSourcesFilter]
            }
          ]
        : undefined,
      pagingOptions: {
        maxResults: 9999,
        includeSummary: true
      }
    });

  const {
    isLoading: isParimutuelProgramsLoading,
    data: parimutuelProgramsData
  } = useListParimutuelPrograms(LIST_PARIMUTUEL_PROGRAMS, {
    pagingOptions: {
      maxResults: 9999,
      includeSummary: true
    }
  });

  const isEmpty = property => {
    return !property[0];
  };

  const handleChange =
    <
      T extends unknown = {
        target: {
          value: string[] | prism.v1.itsp.ItspConnectionEnums.ConnectionState;
        };
      }
    >(
      fieldName
    ) =>
    (e: T): void => {
      dispatch({
        type: "CHANGE_FIELD",
        fieldName,
        value: [e.target.value]
      });
    };

  const handleAutocompleteChange =
    <T extends unknown = { target: { value: string[] } }>(fieldName) =>
    (e: T, value: string[]): void => {
      dispatch({
        type: "CHANGE_FIELD",
        fieldName,
        value
      });
    };

  const handleSourcesFilterChange = event => {
    setSourcesFilter(event.target.value);
  };

  const handleFilterFocus = () => {
    setFilterFocus(true);
  };

  const handleFilterBlur = () => {
    setFilterFocus(false);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    onApply(omitBy(state, isEmpty));
    handleClose();
  };

  const handleReset = () => {
    dispatch({
      type: "RESET"
    });
    onApply({});
    handleClose();
  };

  const wageringSourcesMap =
    wageringSourcesData?.wageringSources?.reduce((acc, cur) => {
      return { ...acc, [cur.name]: cur };
    }, {}) || {};

  const parimutuelProgramsMap =
    parimutuelProgramsData?.parimutuelPrograms?.reduce((acc, cur) => {
      return { ...acc, [cur.name]: cur };
    }, {}) || {};
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const itspConnectionState =
    prism.v1.itsp.ItspConnectionEnums?.ConnectionState || [];

  const badgeCount =
    Object.keys(defaultState).length > 0
      ? Object.values(defaultState)
          .map(a => a.length)
          .reduce((a, b) => a + b)
      : 0;

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge badgeContent={badgeCount} color="secondary">
          <FilterListIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Stack
          spacing={2}
          sx={{
            padding: 2,
            width: "400px"
          }}
          className={root}
        >
          <Typography variant={"h6"}>Filter Connections</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={showActiveFilterList}
                onChange={onShowActiveFilterList}
              />
            }
            label="Show Active Filters"
          />
          <TextField
            margin="dense"
            id="org"
            label="Organization"
            type="text"
            fullWidth
            variant="standard"
            value={state.org[0]}
            onChange={handleChange("org")}
          />
          <Stack direction={"row"} sx={{ alignItems: "end" }}>
            <TextField
              variant="standard"
              margin="dense"
              label="Filter by Organization"
              placeholder={"Org"}
              type="text"
              value={sourcesFilter}
              onChange={handleSourcesFilterChange}
              onFocus={handleFilterFocus}
              onBlur={handleFilterBlur}
              sx={{
                width: "80px"
              }}
            />
            <VirtualizedAutocomplete
              open={filterFocus}
              fullWidth
              multiple
              openOnFocus
              disableCloseOnSelect
              options={Object.keys(wageringSourcesMap)}
              loading={isWageringSourcesLoading}
              getOptionLabel={(option: string) =>
                wageringSourcesMap[option]?.displayName?.value || option
              }
              onChange={handleAutocompleteChange("source")}
              value={state.source}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option + wageringSourcesMap[option]?.displayName.value}
                  title={option}
                >
                  {wageringSourcesMap[option]?.displayName.value || option}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  variant="standard"
                  label="Wagering Sources"
                  placeholder={"Select Wagering Sources"}
                  onFocus={handleFilterFocus}
                  onBlur={handleFilterBlur}
                />
              )}
            />
          </Stack>
          <Autocomplete
            multiple
            options={Object.keys(parimutuelProgramsMap)}
            disableCloseOnSelect
            getOptionLabel={(option: string) =>
              parimutuelProgramsMap[option]?.displayName.value || option
            }
            loading={isParimutuelProgramsLoading}
            onChange={handleAutocompleteChange("parimutuelPrograms")}
            value={state.parimutuelPrograms}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option + parimutuelProgramsMap[option]?.displayName.value}
                title={option}
              >
                {parimutuelProgramsMap[option]?.displayName.value || option}
              </li>
            )}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Parimutuel Programs"
                placeholder={"Select Parimutuel Programs"}
              />
            )}
          />
          <FormControl variant="standard" fullWidth>
            <InputLabel id="connection-state-label">
              Connection State
            </InputLabel>
            <Select
              labelId="connection-state-label"
              value={state.connectionState[0]}
              onChange={handleChange("connectionState")}
              label="Connection State"
            >
              {Object.keys(itspConnectionState).map(key => (
                <MenuItem value={itspConnectionState[key]} key={key}>
                  {enumKeyToLabel(key, "ITSPCONNECTIONSTATE_")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack direction={"row"} justifyContent={"right"}>
            <Button onClick={handleApply}>Apply</Button>
            <Button onClick={handleReset}>Reset</Button>
            <Button onClick={handleClose}>Close</Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default ConnectionsFilterModal;
