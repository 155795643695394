import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Formik, Field } from "formik";
import { prism } from "@tsg/1st-grpc-web";
import { useDataApi } from "hooks/api/data/DataAPI";
import { usePrismApi } from "hooks/api/core/OrganizationAPI";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import { DropdownInput } from "../../../common/Input";
import { DATA_MARKET_TYPE_KEY, ORGANIZATION_KEY } from "common/QueryKeys";
import MenuItem from "@mui/material/MenuItem";
import ForeignIdentifier from "../../../common/ForeignId/foreign-id";
import { convertStringToMoney, convertMoneyToDouble } from "common/helper";
import { ValidationSchema } from "./validation-schema";

interface Props {
  row?: prism.v1.data.IMarketType;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
}

const MarketTypeUpsert = ({ row, handleSuccess, handleError }: Props) => {
  const { t } = useTranslation();
  const { useCreateMarketType, useUpdateMarketType } = useDataApi();
  const { useListOrganizations } = usePrismApi();
  const queryClient = useQueryClient();

  const { data: organisationsData } = useListOrganizations(ORGANIZATION_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const parents = organisationsData?.organizations || [];

  const upsertCallback = (successMessage: string) => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [DATA_MARKET_TYPE_KEY] });
        handleSuccess(t(successMessage));
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };
  const { mutate: mutateAdd, isPending: addIsLoading } = useCreateMarketType(
    upsertCallback("market_type_add_success_message")
  );
  const { mutate: mutateUpdate, isPending: updateIsLoading } =
    useUpdateMarketType(upsertCallback("market_type_update_success_message"));

  return (
    <Box sx={{ padding: "16px 25px" }}>
      <Typography variant="h6">
        {row?.name ? t("update") : t("add")} {t("market_type")}
      </Typography>
      <Box sx={{ marginTop: "26px", "& .MuiTextField-root": { mb: 2 } }}>
        <Formik
          key={row?.name}
          initialValues={{
            parent: row?.parent || "",
            displayName: row?.displayName?.value || "",
            shortName: row?.shortName || "",
            foreignIds: row?.foreignIds || [],
            defaultCurrency: row?.defaultCurrency || "",
            defaultMinBet: {
              currencyCode: row?.defaultMinBet.currencyCode || "",
              amount: row?.defaultMinBet
                ? convertMoneyToDouble(row?.defaultMinBet)
                : null
            },
            defaultMaxBet: {
              currencyCode: row?.defaultMaxBet.currencyCode || "",
              amount: row?.defaultMaxBet
                ? convertMoneyToDouble(row?.defaultMaxBet)
                : null
            },
            state:
              row?.state || prism.v1.data.MarketTypeEnums.State.STATE_ACTIVE,
            preliminaryResultProjection:
              row?.preliminaryResultProjection ||
              prism.v1.data.MarketTypeEnums.PreliminaryResultProjection
                .PRELIMINARY_RESULT_PROJECTION_NONE,
            reductionMatchRequirement:
              row?.reductionMatchRequirement ||
              prism.v1.data.MarketTypeEnums.ReductionMatchRequirement
                .REDUCTION_MATCH_REQUIREMENT_NONE,
            keepAsCombinationPool:
              row?.keepAsCombinationPool ||
              prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
                .KEEP_AS_COMBINATION_POOL_COMBO_POOL,
            createCollation:
              row?.createCollation ||
              prism.v1.data.MarketTypeEnums.CreateCollation
                .CREATE_COLLATION_NO_COLLATION,
            oddsType:
              row?.oddsType ||
              prism.v1.data.MarketTypeEnums.OddsType.ODDS_TYPE_NO_ODDS
          }}
          validationSchema={ValidationSchema(t)}
          onSubmit={data => {
            if (row?.name) {
              mutateUpdate({
                marketType: {
                  name: row.name,
                  displayName: { value: data.displayName },
                  parent: data.parent,
                  state: data.state,
                  defaultCurrency: data.defaultCurrency,
                  defaultMinBet: {
                    ...convertStringToMoney(String(data.defaultMinBet.amount)),
                    currencyCode: data.defaultMinBet.currencyCode
                  },
                  defaultMaxBet: {
                    ...convertStringToMoney(String(data.defaultMaxBet.amount)),
                    currencyCode: data.defaultMaxBet.currencyCode
                  },
                  preliminaryResultProjection: data.preliminaryResultProjection,
                  reductionMatchRequirement: data.reductionMatchRequirement,
                  keepAsCombinationPool: data.keepAsCombinationPool,
                  createCollation: data.createCollation,
                  oddsType: data.oddsType,
                  foreignIds: data.foreignIds
                },
                fieldMask: {
                  paths: [
                    "display_name",
                    "default_currency",
                    "default_min_bet",
                    "default_max_bet",
                    "preliminary_result_projection",
                    "reduction_match_requirement",
                    "keep_as_combination_pool",
                    "create_collation",
                    "odds_type",
                    "foreign_ids"
                  ]
                }
              });
            } else {
              mutateAdd({
                marketType: {
                  displayName: { value: data.displayName },
                  shortName: data.shortName,
                  parent: data.parent,
                  state: data.state,
                  defaultCurrency: data.defaultCurrency,
                  defaultMinBet: {
                    ...convertStringToMoney(String(data.defaultMinBet.amount)),
                    currencyCode: data.defaultMinBet.currencyCode
                  },
                  defaultMaxBet: {
                    ...convertStringToMoney(String(data.defaultMaxBet.amount)),
                    currencyCode: data.defaultMaxBet.currencyCode
                  },
                  preliminaryResultProjection: data.preliminaryResultProjection,
                  reductionMatchRequirement: data.reductionMatchRequirement,
                  keepAsCombinationPool: data.keepAsCombinationPool,
                  createCollation: data.createCollation,
                  oddsType: data.oddsType,
                  foreignIds: data.foreignIds
                  // Temporary for testing create market type
                  // legs: [
                  //   {
                  //     segmentCount: 1,
                  //     class: 1,
                  //     selectionRule: 1,
                  //     numberOfWinningPositions: 1
                  //   }
                  // ]
                }
              });
            }
          }}
        >
          {formik => (
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack direction="row" spacing={2}>
                <Autocomplete
                  multiple={false}
                  options={parents}
                  fullWidth
                  getOptionLabel={(
                    option:
                      | prism.v1.core.Organization
                      | prism.v1.core.OrganizationalUnit
                  ) =>
                    option.displayName?.value ||
                    parents.find(p => p.name === String(option))?.displayName
                      ?.value ||
                    String(option)
                  }
                  onChange={(
                    event,
                    newValue:
                      | prism.v1.core.Organization
                      | prism.v1.core.OrganizationalUnit
                  ) => {
                    formik.setFieldValue("parent", newValue.name);
                  }}
                  defaultValue={row?.parent}
                  renderInput={params => (
                    <DropdownInput
                      {...params}
                      formik={formik}
                      field="parent"
                      label={t("market_type_parent")}
                      placeholder={t("market_type_parent")}
                      onBlur={formik.handleBlur}
                    />
                  )}
                  disabled={Boolean(row?.name)}
                />
                <TextField
                  id="State"
                  label={t("market_type_state_label")}
                  variant="outlined"
                  size="medium"
                  name="state"
                  fullWidth
                  select
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.state || ""}
                >
                  {Object.values(prism.v1.data.MarketTypeEnums.State)
                    .slice(1)
                    .map(option => (
                      <MenuItem key={option} value={option}>
                        {t(
                          `market_type_state.${prism.v1.data.MarketTypeEnums.State[option]}`
                        )}
                      </MenuItem>
                    ))}
                </TextField>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  id="Display-Name"
                  label={t("market_display_name")}
                  variant="outlined"
                  size="medium"
                  name="displayName"
                  fullWidth
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.displayName?.value}
                  error={
                    formik.touched.displayName &&
                    Boolean(formik.errors.displayName)
                  }
                  helperText={
                    formik.touched.displayName && formik.errors.displayName
                  }
                />
                <TextField
                  id="Short-Name"
                  label={t("market_type_short_name")}
                  variant="outlined"
                  size="medium"
                  name="shortName"
                  fullWidth
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.shortName}
                  error={
                    formik.touched.shortName && Boolean(formik.errors.shortName)
                  }
                  helperText={
                    formik.touched.shortName && formik.errors.shortName
                  }
                  disabled={Boolean(row?.name)}
                />
              </Stack>
              <TextField
                id="Currency"
                label={t("market_type_currency")}
                variant="outlined"
                size="medium"
                name="defaultCurrency"
                fullWidth
                onChange={formik.handleChange}
                defaultValue={row?.defaultCurrency}
                error={
                  formik.touched.defaultCurrency &&
                  Boolean(formik.errors.defaultCurrency)
                }
                helperText={
                  formik.touched.defaultCurrency &&
                  formik.errors.defaultCurrency
                }
              />
              <Stack direction="row" spacing={2}>
                <TextField
                  id="Default-Min-Bet"
                  label={t("market_type_min_bet")}
                  variant="outlined"
                  size="medium"
                  name="defaultMinBet.amount"
                  fullWidth
                  type="number"
                  required
                  onChange={formik.handleChange}
                  defaultValue={convertMoneyToDouble(row?.defaultMinBet)}
                  error={
                    formik.touched.defaultMinBet?.amount &&
                    Boolean(formik.errors.defaultMinBet?.amount)
                  }
                  helperText={
                    formik.touched.defaultMinBet?.amount &&
                    formik.errors.defaultMinBet?.amount
                  }
                />
                <TextField
                  id="Default-Max-Bet"
                  label={t("market_type_max_bet")}
                  variant="outlined"
                  size="medium"
                  name="defaultMaxBet.amount"
                  fullWidth
                  type="number"
                  required
                  onChange={formik.handleChange}
                  defaultValue={convertMoneyToDouble(row?.defaultMaxBet)}
                  error={
                    formik.touched.defaultMaxBet?.amount &&
                    Boolean(formik.errors.defaultMaxBet?.amount)
                  }
                  helperText={
                    formik.touched.defaultMaxBet?.amount &&
                    formik.errors.defaultMaxBet?.amount
                  }
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  id="Reduction-Match-Requirement"
                  label={t("market_type_reduction_match_requirement_label")}
                  variant="outlined"
                  size="medium"
                  name="reductionMatchRequirement"
                  fullWidth
                  select
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.reductionMatchRequirement || ""}
                >
                  {Object.values(
                    prism.v1.data.MarketTypeEnums.ReductionMatchRequirement
                  )
                    .slice(1)
                    .map(option => (
                      <MenuItem key={option} value={option}>
                        {t(
                          `market_type_reduction_match_requirement.${prism.v1.data.MarketTypeEnums.ReductionMatchRequirement[option]}`
                        )}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  id="Keep-As-Combination-Pool"
                  label={t("market_type_keep_as_combination_pool_label")}
                  variant="outlined"
                  size="medium"
                  name="keepAsCombinationPool"
                  fullWidth
                  select
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.keepAsCombinationPool || ""}
                >
                  {Object.values(
                    prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
                  )
                    .slice(1)
                    .map(option => (
                      <MenuItem key={option} value={option}>
                        {t(
                          `market_type_keep_as_combination_pool.${prism.v1.data.MarketTypeEnums.KeepAsCombinationPool[option]}`
                        )}
                      </MenuItem>
                    ))}
                </TextField>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  id="Create-Collation"
                  label={t("market_type_create_collation_label")}
                  variant="outlined"
                  size="medium"
                  name="createCollation"
                  fullWidth
                  select
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.createCollation || ""}
                >
                  {Object.values(prism.v1.data.MarketTypeEnums.CreateCollation)
                    .slice(1)
                    .map(option => (
                      <MenuItem key={option} value={option}>
                        {t(
                          `market_type_create_collation.${prism.v1.data.MarketTypeEnums.CreateCollation[option]}`
                        )}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  id="Odds-Type"
                  label={t("market_type_odds_type_label")}
                  variant="outlined"
                  size="medium"
                  name="oddsType"
                  fullWidth
                  select
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.oddsType || ""}
                >
                  {Object.values(prism.v1.data.MarketTypeEnums.OddsType)
                    .slice(1)
                    .map(option => (
                      <MenuItem key={option} value={option}>
                        {t(
                          `market_type_odds_type.${prism.v1.data.MarketTypeEnums.OddsType[option]}`
                        )}
                      </MenuItem>
                    ))}
                </TextField>
              </Stack>
              <TextField
                id="Preliminary-Result-Projection"
                label={t("market_type_preliminary_result_projection_label")}
                variant="outlined"
                size="medium"
                name="preliminaryResultProjection"
                fullWidth
                select
                required
                onChange={formik.handleChange}
                defaultValue={row?.preliminaryResultProjection || ""}
              >
                {Object.values(
                  prism.v1.data.MarketTypeEnums.PreliminaryResultProjection
                )
                  .slice(1)
                  .map(option => (
                    <MenuItem key={option} value={option}>
                      {t(
                        `market_type_preliminary_result_projection.${prism.v1.data.MarketTypeEnums.PreliminaryResultProjection[option]}`
                      )}
                    </MenuItem>
                  ))}
              </TextField>
              <Field
                name="foreignIds"
                component={ForeignIdentifier}
                disabled={Boolean(row?.name)}
              />
              <LoadingButton
                variant="contained"
                fullWidth
                disableElevation
                type="submit"
                size="large"
                loading={addIsLoading || updateIsLoading}
                disabled={!(formik.isValid && formik.dirty)}
              >
                {row ? t("update_button_text") : t("add_button_text")}
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default MarketTypeUpsert;
