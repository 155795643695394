import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import {
  MARKET_NETWORK_POOLS_KEY,
  MARKETS_KEY,
  MARKET_SOURCES_KEY,
  DATA_MARKET_TYPE_KEY
} from "common/QueryKeys";
import { GridComponent } from "components/common/Grid";
import {
  APP_MARGIN_TOP,
  APP_PADDING_VERTICAL
} from "components/style/app-style";
import { FilterValues } from "components/transaction/MarketNetworkPool/MarketNameSearch";
import { useDataApi } from "hooks/api/data/DataAPI";
import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import Columns from "./column";
import { DataGridProProps, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { comparePoolCombo } from "utils/data";
import DetailPanelContent from "components/transaction/MarketPool/detail-panel";
import { prism } from "@tsg/1st-grpc-web";
import CustomCell from "components/common/Grid/custom-cell";
import MarketNameSearch from "components/transaction/MarketNetworkPool/MarketNameSearch";

type MarketNetworkPoolItem = prism.v1.transaction.IMarketNetworkPool & {
  marketNested: prism.v1.data.IMarket;
  marketSourceNested: prism.v1.data.IMarketSource;
};

const MarketNetworkPoolsPage = () => {
  const { t } = useTranslation();
  const { useGetMarketNetworkPool } = useTransactionApi();
  const { useListMarkets, useListMarketSources, useListMarketTypes } =
    useDataApi();
  const [filterValues, setFilterValues] = useState<FilterValues>({
    marketId: ""
  });

  const {
    data: marketNetworkPoolData,
    isLoading: isMarketNetworkPoolLoading,
    refetch: refetchMarketNetworkPool,
    isRefetching: isMarketNetworkPoolRefetching
  } = useGetMarketNetworkPool(
    MARKET_NETWORK_POOLS_KEY,
    {
      name: filterValues?.marketId
    },
    {
      enabled: !!filterValues?.marketId
    }
  );

  const {
    data: marketTypesData,
    isLoading: isMarketTypesLoading,
    refetch: refetchMarketTypes,
    isRefetching: isMarketTypesRefetching
  } = useListMarketTypes(DATA_MARKET_TYPE_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const marketTypes = marketTypesData?.marketTypes || [];

  const {
    data: marketSourcesData,
    isLoading: isMarketSourcesLoading,
    refetch: refetchMarketSources,
    isRefetching: isMarketSourcesRefetching
  } = useListMarketSources(MARKET_SOURCES_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const {
    data: marketsData,
    isLoading: isMarketsLoading,
    refetch: refetchMarkets,
    isRefetching: isMarketsRefetching
  } = useListMarkets(MARKETS_KEY, {
    filter: [
      {
        state: Object.keys(prism.v1.data.MarketEnums.State)
          .map(key => {
            if (
              [
                prism.v1.data.MarketEnums.State.STATE_INCOMPLETE,
                prism.v1.data.MarketEnums.State.STATE_CANCELLED
              ].every(type => prism.v1.data.MarketEnums.State[key] !== type)
            ) {
              return prism.v1.data.MarketEnums.State[key];
            }

            return null;
          })
          .filter(it => it !== null)
      }
    ],
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const keepAsCombinationPoolMap = useMemo(() => {
    return marketTypes.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.name]: cur.keepAsCombinationPool
      };
    }, {});
  }, [marketTypes]);

  const handleFilterChange = values => setFilterValues(values);

  const markets = (marketsData?.markets || []).reduce((acc, cur) => {
    return {
      ...acc,
      [cur.name]: cur
    };
  }, {});

  const marketSourcesMap = (marketSourcesData?.marketSources || []).reduce(
    (acc, cur) => {
      return {
        ...acc,
        [cur.name]: cur
      };
    },
    {}
  );

  const marketNetworkPools: MarketNetworkPoolItem[] =
    (marketNetworkPoolData && [
      {
        ...marketNetworkPoolData,
        marketNested: markets[marketNetworkPoolData?.market],
        marketSourceNested: marketSourcesMap[marketNetworkPoolData?.source]
      }
    ]) ||
    [];

  const isRefetching =
    isMarketNetworkPoolRefetching ||
    isMarketSourcesRefetching ||
    isMarketsRefetching ||
    isMarketTypesRefetching;

  const isLoading =
    isMarketNetworkPoolLoading ||
    isMarketSourcesLoading ||
    isMarketsLoading ||
    !marketNetworkPools ||
    isMarketTypesLoading;

  const refetch = () => {
    refetchMarketNetworkPool();
    refetchMarketSources();
    refetchMarkets();
  };

  const getDetailPanelContent = useCallback<
    DataGridProProps["getDetailPanelContent"]
  >(({ row }): ReactNode => {
    const { marketPoolCombos = [] } = row.netComboPool || {};

    return (
      <DetailPanelContent
        title="Net Live By Combination"
        matrixData={marketPoolCombos}
      />
    );
  }, []);

  const getDetailPanelHeight = () => "auto";

  // Generate market network pools data for tree data to be used with DataGrid.
  // In order to display the data in a tree structure, we need to generate a path for each row.
  // The pattern of the path array is as follows: [A] (parent), [A, B] (child), [A, C] (another child), [A, D] etc.
  // MUI tree data docs: https://mui.com/x/react-data-grid/tree-data/
  const handleGenerateTreeData = (
    marketNetworkPools: MarketNetworkPoolItem[]
  ) => {
    if (isMarketNetworkPoolLoading) return [];

    const sortedMarketNetworkPools = marketNetworkPools.map(mp => {
      return {
        ...mp,
        netComboPool: {
          ...mp.netComboPool,
          marketPoolCombos: mp?.netComboPool?.marketPoolCombos.sort((a, b) => {
            return comparePoolCombo(a.combination, b.combination);
          })
        },
        keepAsCombinationPool:
          keepAsCombinationPoolMap?.[markets?.[mp.market]?.marketTypeName]
      };
    });

    const treeData = [];
    const paths = sortedMarketNetworkPools.map((mnp, idx) => {
      const parentPath = `Local-Total-${idx}`;

      let pools = [];

      const keepAsCombinationPoolComboPool =
        mnp.keepAsCombinationPool ===
        prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
          .KEEP_AS_COMBINATION_POOL_COMBO_POOL;

      if (keepAsCombinationPoolComboPool) {
        pools =
          mnp.netComboPool?.marketPoolCombos?.map((_, idx) => `Combo-${idx}`) ||
          [];
      }

      return [parentPath, ...pools.filter(Boolean)];
    });

    if (!paths) return sortedMarketNetworkPools;

    sortedMarketNetworkPools.map((mnp, idx) => {
      const parentPath = paths[idx][0];
      paths[idx].forEach((path, idx) => {
        if (idx === 0) {
          treeData.push({
            ...mnp,
            path: [parentPath]
          });
        } else {
          treeData.push({
            ...mnp,
            path: [parentPath, path]
          });
        }
      });
    });

    return treeData.map((td, idx) => ({
      ...td,
      id: idx
    }));
  };

  const getTreeDataPath = row => {
    const keepAsCombinationPoolComboPool =
      row.keepAsCombinationPool ===
      prism.v1.data.MarketTypeEnums.KeepAsCombinationPool
        .KEEP_AS_COMBINATION_POOL_COMBO_POOL;

    if (!keepAsCombinationPoolComboPool) {
      return [`${row?.marketNested?.marketTypeName}-${row.id}`];
    }

    return row.path;
  };

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    field: "marketSourceDisplayName",
    headerName: t("market_pool_market_source_display_name"),
    hide: false,
    editable: false,
    sortable: true,
    minWidth: 250,
    width: 250,
    renderCell: (params: GridValueGetterParams) => {
      const path = params.row.path;

      if (path.length > 1) {
        return "";
      }

      return <CustomCell params={params} sx={{ height: "100%" }} />;
    },
    valueGetter: (params: GridValueGetterParams) => {
      const path = params.row.path;
      if (path.length > 1) return "";
      return (
        params.row.marketSourceNested?.displayName?.value || params.row.source
      );
    }
  };

  const treeData = handleGenerateTreeData(marketNetworkPools);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: {
          xs: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.xs * 2
          }px)`,
          md: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.md * 2
          }px)`,
          lg: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.lg * 2
          }px)`
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell": {
          alignItems: "flex-start",
          "& .MuiListItemText-root:first-child": {
            marginTop: "0"
          }
        }
      }}
    >
      <MarketNameSearch
        onFilterChange={handleFilterChange}
        isLoading={isLoading || isRefetching}
      />
      <GridComponent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column"
        }}
        idProp={"id"}
        rowData={treeData}
        columnDefs={Columns(t)}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        pageTitle={t("market_network_pools")}
        initialState={{
          sorting: {
            sortModel: [{ field: "marketSourceDisplayName", sort: "asc" }]
          },
          rowGrouping: {
            model: ["marketSourceDisplayName"]
          }
        }}
        rowHeight={"auto"}
        disableAddButton={true}
        loading={isLoading}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
        treeData={true}
        getTreeDataPath={getTreeDataPath}
        disableCellMouseEvents={true}
        groupingColDef={groupingColDef}
        showQuickFilter={false}
      >
        <></>
      </GridComponent>
    </Box>
  );
};

export default MarketNetworkPoolsPage;
