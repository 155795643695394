import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { LIST_CONNECTIONS } from "common/QueryKeys";
import { ConnectionCommands } from "common/utils/enum-utils";
import { GridComponent } from "components/common/Grid";
import ConnectionCommandModal from "components/itsp/connection-command-modal";
import ConnectionDetailsModal from "components/itsp/connection-details-modal";
import ConnectionForm from "components/itsp/connection-form";
import { ItspConnectionFilterState } from "components/itsp/connections-filter-modal/connections-filter-modal";
import { useSnackbar } from "components/snackbar-context/snackbar-context";
import { useItspCommands } from "hooks/api/itsp/useItspCommands";
import { setFilter } from "store/actions/connection-pages";
import useITSPConnectionPageData from "../../hooks/api/itsp/useITSPConnectionPageData";
import { Columns, ITSPConnection } from "./column";
import ITSPConnectionFilters from "./filters";

export default function ITSPConnectionPage() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const actionMethod = useRef(null);
  const [selectedConnectionName, setSelectedConnectionName] =
    useState<string>();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [connectionCommand, setConnectionCommand] =
    useState<ConnectionCommands | null>(null);
  const [rowData, setRowData] = useState<ITSPConnection | null>(null);
  const [showActiveFilterList, setShowActiveFilterList] =
    useState<boolean>(false);
  const {
    handleRefetch,
    isLoading,
    filter,
    isRefetching,
    parimutuelProgramsMap,
    connectionData,
    isWageringSourcesLoading,
    wageringSourcesMap,
    isParimutuelProgramsLoading,
    itspConnectionsData
  } = useITSPConnectionPageData();
  const { showSuccessSnack } = useSnackbar();
  const requestCallback = (message: { key: string }) => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [LIST_CONNECTIONS] });

        showSuccessSnack(t(message.key));
        handleRefetch();
      },
      onError: errorResponse => {
        handleRefetch();
      }
    };
  };
  const { isLoading: isLoadingCommand, handlers: commandHandlers } =
    useItspCommands(requestCallback);
  const handleActionClick = (data: {
    command: ConnectionCommands;
    row: ITSPConnection;
  }) => {
    setConnectionCommand(data.command);
    setRowData(data.row);
  };

  const handleRowClick = ({ row: connection }) => {
    setSelectedConnectionName(connection.name);
    setIsDetailsModalOpen(true);
  };
  const handleDetailsClose = () => {
    setIsDetailsModalOpen(false);
  };
  const handleCloseCommandConfirmationDialog = () => {
    setConnectionCommand(null);
  };
  const handleConfirmCommand = (body?: Record<string, string[]>) => {
    commandHandlers[connectionCommand] &&
      commandHandlers[connectionCommand]({ name: rowData.name, ...body });
    setConnectionCommand(null);
    setRowData(null);
  };
  const handleApplyFilter = (filter: ItspConnectionFilterState) => {
    dispatch(setFilter("all", filter));
  };

  const handleShowActiveFilterList = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowActiveFilterList(event.target.checked);
  };
  return (
    <>
      <GridComponent
        rowData={connectionData}
        columnDefs={Columns(handleActionClick, t)}
        idProp={"name"}
        pageTitle={t("connection_table.title")}
        tooltipTitle={t("connection_table.create_connection")}
        editType="modal"
        actionMethod={actionMethod}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayName", sort: "asc" }]
          }
        }}
        loading={isLoading || isRefetching || isLoadingCommand}
        modalSize={"lg"}
        refreshButton={{
          onRefresh: handleRefetch,
          isRefreshingData: isRefetching
        }}
        onCellClick={handleRowClick}
        customTableAction={
          <ITSPConnectionFilters
            onApply={handleApplyFilter}
            filter={filter}
            showActiveFilterList={showActiveFilterList}
            onShowActiveFilterList={handleShowActiveFilterList}
            isLoading={isWageringSourcesLoading || isParimutuelProgramsLoading}
            wageringSourcesMap={wageringSourcesMap}
            parimutuelProgramsMap={parimutuelProgramsMap}
          />
        }
      >
        <ConnectionForm />
      </GridComponent>
      <ConnectionDetailsModal
        connection={itspConnectionsData?.itspConnections?.find(
          c => c.name === selectedConnectionName
        )}
        onClose={handleDetailsClose}
        open={isDetailsModalOpen}
      />
      <ConnectionCommandModal
        row={
          (rowData &&
            itspConnectionsData?.itspConnections.find(
              connection => connection.name === rowData.name
            )) ||
          null
        }
        command={connectionCommand}
        onClose={handleCloseCommandConfirmationDialog}
        onConfirm={handleConfirmCommand}
      />
    </>
  );
}
