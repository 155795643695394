import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { prism } from "@tsg/1st-grpc-web";
import { IAM_USER_SOURCE_KEY } from "common/QueryKeys";
import { ALL_ORG_UNITS } from "common/constants";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import DomainActionForm from "components/common/DomainActionForm/domain-action-form";
import { TagInput } from "components/common/TagsInput/tags-input";
import { usePrismWebContext } from "components/common/context";
import { Formik } from "formik";
import { useIAMApi } from "hooks/api/iam/IamAPI";
import React from "react";
import { useTranslation } from "react-i18next";
import { ValidationSchema } from "./validation-schema";

interface Props {
  row?: prism.v1.iam.IUserSource;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
  domainAction?: string;
  setDomainAction?: (x: string) => void;
  allOrgUserSources?: prism.v1.iam.IUserSource[];
}

const UserSourceUpsert = ({
  row,
  handleSuccess,
  handleError,
  domainAction,
  setDomainAction,
  allOrgUserSources
}: Props) => {
  const { t } = useTranslation();
  const {
    useUpdateUserSource,
    useAddUserSourceDomain,
    useRemoveUserSourceDomain,
    useCreateUserSource,
    useListUserSources
  } = useIAMApi();
  const [userSourceName, setUserSourceName] = React.useState("");
  const [userSourceData, setUserSourceData] =
    React.useState<prism.v1.iam.IUserSource>(row);
  const queryClient = useQueryClient();
  const { organization, organizationUnit } = usePrismWebContext();
  const [organizationUnitName, setOrganizationUnitName] = organizationUnit;
  const [organizationName, setOrganizationName] = organization;
  const [domainName, setDomainName] = React.useState("");
  const [listOfDomainsByName, setListOfDomainsByName] = React.useState([]);
  const [shortNameData, setShortNameData] = React.useState([]);
  const [allDomainsData, setAllDomainsData] = React.useState([]);

  const { data } = useListUserSources(IAM_USER_SOURCE_KEY, {
    filter: [
      {
        org: [organizationName],

        state: [
          prism.v1.iam.UserSourceEnums.State.STATE_INACTIVE,
          prism.v1.iam.UserSourceEnums.State.STATE_ACTIVE
        ]
      }
    ],
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  React.useEffect(() => {
    setUserSourceData(row);
    setUserSourceName(row?.name);
    setListOfDomainsByName(row?.domains || []);
    if (data?.userSources) {
      const uniqueData = data?.userSources
        .filter(x => x.shortName !== userSourceData?.shortName)
        .map(x => x.shortName);
      setShortNameData(uniqueData);
    }
  }, [row, data?.userSources]);

  React.useEffect(() => {
    if (allOrgUserSources) {
      const allData = allOrgUserSources?.filter(
        x => !userSourceData?.domains.some(domain => x.domains.includes(domain))
      );
      setAllDomainsData(allData?.map(x => x.domains).flat() || []);
    }
  }, [allOrgUserSources]);

  const upsertCallback = successMessage => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: [IAM_USER_SOURCE_KEY]
        });
        setDomainAction(null);
        handleSuccess(t(successMessage));
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };

  const { mutate: mutateAdd, isPending: addIsLoading } = useCreateUserSource(
    upsertCallback("user_source_add_success_message")
  );

  const { mutate: mutateUpdate, isPending: updateIsLoading } =
    useUpdateUserSource(upsertCallback("user_source_update_success_message"));

  const { mutate: mutateAddDomain, isPending: addDomainIsLoading } =
    useAddUserSourceDomain(
      upsertCallback("user_source_domain_add_success_message")
    );

  const { mutate: mutateRemoveDomain, isPending: removeDomainIsLoading } =
    useRemoveUserSourceDomain(
      upsertCallback("user_source_domain_remove_success_message")
    );

  const addDomain = domainAction === "addDomain";
  const removeDomain = domainAction === "removeDomain";

  const handleChange = name => {
    setDomainName(name);
  };

  const handleSubmit = () => {
    if (removeDomain && domainName) {
      mutateRemoveDomain({
        name: userSourceName,
        domain: domainName
      });
    } else if (addDomain && domainName) {
      mutateAddDomain({
        name: userSourceName,
        domain: domainName
      });
    }
  };

  return (
    <>
      <Box sx={{ padding: "16px 25px" }}>
        <Typography variant="h6">
          {addDomain
            ? t("user_source_add_domain")
            : removeDomain
            ? t("user_source_remove_domain")
            : userSourceName
            ? t("update_user_source")
            : t("create_user_source")}
        </Typography>
        {!(addDomain || removeDomain) && (
          <Box sx={{ marginTop: "26px", "& .MuiTextField-root": { mb: 2 } }}>
            <Formik
              initialValues={{
                displayName: userSourceData?.displayName?.value || "",
                shortName: userSourceData?.shortName || "",
                domains: userSourceData?.domains || []
              }}
              validationSchema={ValidationSchema(
                t,
                shortNameData,
                allDomainsData
              )}
              onSubmit={data => {
                if (userSourceName) {
                  const updateRequest = {
                    userSource: {
                      name: userSourceName,
                      displayName: { value: data.displayName.trim() }
                    },
                    fieldMask: {
                      paths: ["display_name"]
                    }
                  };
                  mutateUpdate(updateRequest);
                } else {
                  const createRequest = {
                    userSource: {
                      displayName: { value: data.displayName.trim() },
                      shortName: data?.shortName.trim(),
                      domains: data?.domains,
                      parent:
                        organizationUnitName === ALL_ORG_UNITS
                          ? organizationName
                          : organizationUnitName
                    }
                  };
                  mutateAdd(createRequest);
                }
              }}
            >
              {formik => (
                <form noValidate onSubmit={formik.handleSubmit}>
                  <TextField
                    id="Display-Name"
                    label={t("user_source_display_name")}
                    variant="outlined"
                    size="medium"
                    name="displayName"
                    fullWidth
                    required
                    onChange={formik.handleChange}
                    defaultValue={userSourceData?.displayName?.value}
                    error={
                      formik.touched.displayName &&
                      Boolean(formik.errors.displayName)
                    }
                    helperText={
                      formik.touched.displayName && formik.errors.displayName
                    }
                  />
                  <TextField
                    id="Short-Name"
                    label={t("user_source_short_name")}
                    variant="outlined"
                    size="medium"
                    name="shortName"
                    fullWidth
                    required
                    onChange={formik.handleChange}
                    defaultValue={userSourceData?.shortName}
                    error={
                      formik.touched.shortName &&
                      Boolean(formik.errors.shortName)
                    }
                    helperText={
                      formik.touched.shortName && formik.errors.shortName
                    }
                    disabled={Boolean(userSourceName)}
                  />
                  <TagInput
                    name={"domains"}
                    field={"domains"}
                    onChange={value => formik.setFieldValue("domains", value)}
                    defaultValues={formik.values.domains}
                    label={t("user_source_domains")}
                    disabled={Boolean(userSourceName)}
                  />

                  <LoadingButton
                    variant="contained"
                    fullWidth
                    disableElevation
                    sx={{
                      marginTop: "15px"
                    }}
                    type="submit"
                    size="large"
                    loading={updateIsLoading || addIsLoading}
                    loadingPosition="start"
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {userSourceName
                      ? t("user_group_update_button_text")
                      : t("user_group_add_button_text")}
                  </LoadingButton>
                </form>
              )}
            </Formik>
          </Box>
        )}
        {(addDomain || removeDomain) && (
          <DomainActionForm
            addDomain={addDomain}
            removeDomain={removeDomain}
            domainName={domainName}
            listOfDomainsByName={listOfDomainsByName.sort()}
            removeDomainIsLoading={removeDomainIsLoading}
            addDomainIsLoading={addDomainIsLoading}
            handleSubmit={handleSubmit}
            onChange={handleChange}
            allDomains={allOrgUserSources
              ?.map(x => x.domains)
              ?.flatMap(allDomains => allDomains)}
          />
        )}
      </Box>
    </>
  );
};

export default UserSourceUpsert;
