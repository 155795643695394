export const ORGANIZATION_KEY = "organizations";
export const ORGANIZATION_DETAIL_KEY = "organizations_";
export const ORGANIZATION_UNITS_KEY = "organizations_units";
export const ORGANIZATION_SOURCES_KEY = "organizations_source";
export const ORGANIZATION_UNIT_TYPE_KEY = "organization_unit_types";
export const SOURCE_TYPES_KEY = "source_types";
export const VIDEO_ENCODER_KEY = "video_encoder";
export const VIDEO_DECODER_KEY = "video_decoder";
export const COUNTRY_KEY = "country_master";
export const ALLOWED_COUNTRY_KEY = "allowed_country";
export const VIDEO_SOURCES_KEY = "video_sources_key";
export const VIDEO_CHANNEL_KEY = "video_channel_key";
export const CAMERA_ANGLE_KEY = "camera_angle";
export const FOREIGN_ID_KEY = "foreign_id";
export const VIDEO_BOOKING_KEY = "video_booking_key";
export const VIDEO_REPLAY_KEY = "video_replay_key";
export const VIDEO_REPLAY_KEY_FOR_PARENT = "video_replay_key_for_parent";
export const VIDEO_CONSUMER_KEY = "video_consumer_key";
export const IAM_ROLE_KEY = "iam_roles_key";
export const IAM_PERMISSION_KEY = "iam_permissions_key";
export const DATA_MARKET_KEY = "data_market_key";
export const DATA_MARKET_TYPE_KEY = "data_market_type_key";
export const DATA_PARIMUTUEL_EVENT_KEY = "data_parimutuel_event_key";
export const DATA_PARIMUTUEL_PROGRAM_KEY = "data_paramutuel_program_key";
export const DATA_JOCKEY_KEY = "data_jockey_key";
export const DATA_COMPETITION_KEY = "data_competition_key";
export const DATA_MARKET_SOURCE_KEY = "data_market_source_key";
export const IAM_USER_KEY = "iam_user_key";
export const IAM_USER_SOURCE_KEY = "iam_user_source_key";
export const IAM_USER_GROUP_KEY = "iam_user_group_key";
export const WAGERING_SOURCES_KEY = "transaction_wagering_sources";
export const PRICE_CONFIGURATIONS_KEY = "transaction_price_configurations";
export const MARKET_POOLS_KEY = "transaction_market_pools";
export const MARKET_POOLS_GROSS_TOTALS_KEY =
  "transaction_market_pools_gross_totals";
export const MARKET_POOL_INTEGRITY_CHECK_KEY =
  "transaction_market_pool_integrity_check";
export const MARKET_PRICE_INTEGRITY_CHECK_KEY =
  "transaction_market_price_integrity_check";
export const MARKET_NETWORK_POOLS_KEY = "transaction_market_network_pools";
export const MARKET_PRICES_KEY = "transaction_market_prices";
export const MARKET_PRICE_CONFIGURATIONS_KEY =
  "transaction_market_price_configurations";
export const MARKET_SOURCES_KEY = "transaction_market_sources";
export const MARKET_WILL_PAYS_KEY = "transaction_market_will_pays";
export const MARKET_WIN_SUMMARY_KEY = "market_win_summary";
export const COMPETITIONS_KEY = "data_competitions";
export const MARKETS_KEY = "data_markets";
export const MARKET_TYPES_KEY = "data_market_types";
export const MARKET_TOTALS_KEY = "transaction_market_totals_key";
export const BETS_KEY = "transaction_bets";
export const MARKET_COLLATIONS_KEY = "transaction_market_collations_key";
export const IAM_ROLE_BINDING_KEY = "iam_role_binding_key";
export const IAM_AUTHORIZED_CLIENT_KEY = "iam_authorized_client_key";
export const LIST_CONNECTIONS = "list-connections";
export const LIST_WAGERING_SOURCES = "list-wagering-sources";
export const LIST_PARIMUTUEL_PROGRAMS = "list-parimutuel-programs";
export const VIDEO_STREAM_PRICING_KEY = "video-stream-pricing-key";
export const VIDEO_STREAM_USAGE_KEY = "video_stream_usage_report";
export const VIDEO_STREAM_USER_USAGE_KEY = "video_stream_user_usage_report";
export const ALERTS_KEY = "core_alerts";
export const VIDEO_STREAM_INVOICE_KEY = "video_stream_invoice";
export const VIDEO_CHANNEL_CLUSTER_KEY = "video_channel_cluster";
export const COMPETITION_KEY = "competition_key";
